import { Icon } from '@mui/material';
import { createTheme } from '@mui/material/styles';

const commonTypography = {
  fontFamily: '"Poppins","Roboto", "Helvetica", "Arial", "sans-serif"',
  fontSize: 14, //14
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  h1: {
    fontWeight: 600,
    fontSize: '2.25rem', //36
  },
  h2: {
    fontWeight: 600,
    fontSize: '2rem', //32
  },
  h3: {
    fontWeight: 600,
    fontSize: '1.75rem', //28
  },
  h4: {
    fontWeight: 600,
    fontSize: '1.5rem', //24
  },
  h5: {
    fontWeight: 600,
    fontSize: '1.25rem', //20
  },
  h6: {
    fontWeight: 600,
    fontSize: '1rem', //16
  },
  sh1: {
    fontWeight: 500,
    fontSize: '1.5rem', //24
  },
  sh2: {
    fontWeight: 500,
    fontSize: '1.125rem', //18
  },
  sh3: {
    fontWeight: 500,
    fontSize: '1rem', //16
  },
  sh4: {
    fontWeight: 500,
    fontSize: '0.75rem', //12
  },
  body1: {
    fontWeight: 400,
    fontSize: '1.25rem', //20
  },
  body2: {
    fontWeight: 400,
    fontSize: '1.125rem', //18
  },
  body3: {
    fontWeight: 400,
    fontSize: '1rem', //16
    // color: "#5E5E5E",
  },
  body4: {
    fontWeight: 400,
    fontSize: '0.75rem', //12
  },
  body5: {
    fontWeight: 400,
    fontSize: '0.875rem', //14
  },
  button: {
    fontWeight: 500,
    fontSize: '0.75rem', //12
    // textTransform: 'capitalize',
  },
  subtitle1: {
    color: '#5E5E5E',
    letterSpacing: '0.08em',
    textTransform: 'uppercase',
    fontSize: '0.75rem',
    lineHeight: '18px',
    fontWeight: '400',
    fontStyle: 'normal',
  },
};

const lightTheme = createTheme({
  root: {
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',
  },
  palette: {
    // mode: "light",
    base: {
      main: 'transparent',
    },
    onSurface: {
      main: 'transparent',
    },
    onSurface2: {
      main: 'transparent',
    },
    onColorSurfaceText: {
      main: 'transparent',
    },
    white: {
      main: '#FFFFFF',
      // light: '#F5F6FE',
      // dark: '#EBEBEB'
    },
    lightBgBlue: {
      main: '#F5F6FE',
      light: '#EBECF5',
    },
    black: {
      main: '#000000',
      light: '#5E5E5E',
      dark: '#333333',
    },
    grey: {
      main: '#5E5E5E',
      light: '#C6C6C6',
    },
    blue: {
      main: '#5B6AF1',
      light: '#ACB3F1',
      dark: '#161E6A',
    },
    green: {
      main: '#5BF1D2',
      light: '#ACF1E3',
      dark: '#166A59',
    },
    yellow: {
      main: '#F1C65B',
      light: '#F1DAAC',
      dark: '#6A5216',
    },
    red: {
      main: '#F15B65',
      light: '#F1ACB0',
      dark: '#6A161B',
    },
    linearGradient: {
      main: 'linear-gradient(180deg, #0D1773 1.04%, #0D735E 100%)',
    },
    graph01: {
      main: '#D86445',
      // mainRgb: 'rgba(216, 73, 69, 0.5)',
    },
    graph02: {
      main: '#D89E45',
      // mainRgb: 'rgba(216, 100, 69, 0.5)',
    },
    graph03: {
      main: '#D8CF45',
      // mainRgb: 'rgba(216, 158, 69, 0.5)',
    },
    graph04: {
      main: '#45D853',
      // mainRgb: 'rgba(216, 207, 69, 0.5)',
    },
    graph05: {
      main: '#45D8D7',
      // mainRgb: 'rgba(69, 216, 83, 0.5)',
    },
    graph06: {
      main: '#458FD8',
      // mainRgb: 'rgba(69, 216, 215, 0.5)',
    },
    graph07: {
      main: '#8B45D8',
      // mainRgb: 'rgb(69, 143, 216, 0.5)',
    },
    graph08: {
      main: '#D8459C',
      // mainRgb: 'rgba(139, 69, 216, 0.5)',
    },
    graph09: {
      main: '#D84945;',
      // mainRgb:'rgba(139, 69, 216, 0.5)'
    },
    borderColor: {
      main: '#c6c6c6',
    },
    chartFont: {
      main: '#000000',
    },
    gridColor: {
      main: 'rgba(0, 0, 0, 0.5)',
    },
  },
  breakpoints: {
    values: {
      // xs: 0,
      // sm: 600,
      // md: 960,
      // lg: 1280,
      // xl: 1920,
      xxs: 0, // smol phone
      xs: 300, // phone
      sm: 600, // tablets
      md: 900, // small laptop
      lg: 1200, // desktop
      xl: 1536, // large screens
    },
  },
  typography: commonTypography,
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '8px 16px',
          textTransform: 'capitalize',
          boxShadow: 'none',
        },
        sizeMedium: {
          fontSize: '1rem',
        },
        sizeSmall: {
          fontSize: '0.875rem',
        },
        sizeLarge: {
          fontSize: '1.125rem',
        },
        contained: {
          backgroundColor: '#161E6A',
          width: { xl: '450px', md: '450px' },
          fontSize: { xl: '1px', md: '14px' },
          color: '#FFF',
          '&:hover': {
            backgroundColor: '#000',
            boxShadow: 'none',
          },
          '&:active': {
            // border: '1px solid #ACF1E3'
            // outline: '1px solid #ACF1E3',
            boxShadow: 'none',
          },
          '&:focus': {
            // border: '1px solid #ACF1E3'
            // outline: '1px solid #ACF1E3',
            boxShadow: 'none',
          },
        },
        text: {
          color: '#161E6A',
          '&:hover': {
            color: '#000',
            boxShadow: 'none',
          },
          '&:active': {
            outline: '1px solid #ACF1E3',
            boxShadow: 'none',
          },
        },
        outlined: {
          color: '#161E6A',
          borderColor: '#161E6A',
          '&:hover': {
            color: '#000',
            border: '1px solid #000',
            boxShadow: 'none',
          },
          '&:active': {
            // border: '1px solid #ACF1E3',
            outline: '1px solid #ACF1E3',
            boxShadow: 'none',
          },
        },
      },
    }, //MuiButton ends

    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#333',
          fontSize: '24px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& input': { fontSize: '1rem', fontWeight: '400' },
            '& input::placeholder': { fontSize: '0.875rem', fontWeight: '400' },
            '& fieldset': {
              borderColor: '#5E5E5E',
            },
            '&:hover fieldset': {
              // borderColor: '#00f',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#161e6a',
            },
            '&.Mui-disabled fieldset': {
              // backgroundColor: "#EBECF5",
            },
          },
          '&.Mui-disabled .MuiOutlinedInput-root fieldset': {
            // backgroundColor: "red",
          },
        },
      },
    }, //end MuiTextField
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: '#ADB3EB',
          color: '#333333',
          borderRadius: '4px',
          padding: '4px',
          margin: '8px 8px 8px 0',
        },
        deleteIcon: {
          color: '#333333',
          fontSize: '24px',
          fontWeight: '500',
        },
      },
    }, //end MuiChip
    MuiDialog: {
      styleOverrides: {
        paper: {
          // background:'red',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        // root: {
        //   fontFamily: "Poppins",
        //   fontStyle: "normal",
        //   fontWeight: 500,
        //   fontSize: "24px",
        //   lineHeight: "36px",
        //   /* identical to box height */
        //   textAlign: "center",
        //   color: "#333333",
        // },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '0px',
          // border:'1px solid red',
          // width: "808px",
          // minHeight: "auto",
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '20px',
          lineHeight: '36px',
          /* identical to box height */
          textAlign: 'center',
          color: '#333',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'center',
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          // error: <ErrorIcon fontSize="inherit" />, // Customize icons
          // warning: <WarningIcon fontSize="inherit" />,
          // success: <CheckCircleIcon fontSize="inherit" />,
          info: <Icon className="fh-icon fh-icon-info" />,
        },
      },
      styleOverrides: {
        root: {
          background: '#F5EBEF',
          color: '#333333',
          padding: '16px 34px 16px 32px',
          '& .MuiAlert-message': {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          },
          '& .MuiAlert-icon': {
            color: '#6A161B',
          },
        },
        filledSuccess: {
          backgroundColor: '#ACF1E3',
          color: 'black',
        },
        filledError: {
          backgroundColor: '#F1ACB0',
          color: 'black',
        },
        filledWarning: {
          backgroundColor: '#F7DEB6',
          color: 'black',
        },
        filledInfo: {
          backgroundColor: '#ACB3F1',
          color: 'black',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',

          '&.Mui-selected': {
            background: '161E6A',
            color: '#fff',
            textDecoration: 'none',
            borderBottom: 'none ',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: '1px solid #5E5E5E',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0px',
        },
      },
    },
    // MuiAccordionSummary:{
    //     styleOverrides:{
    //         root:{
    //             fontSize:'320px'
    //         }
    //     }
    // },

    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: 'none',
          // border: '1px solid #c6c6c6',
          // overflow:'hidden'
          // borderColor: '#5E5E5E'
          // border:'1px solid rgba(198,198,198,0.8)'
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          // border: '1px solid rgba(198,198,198,0.8)',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid rgba(198,198,198,0.8)',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: '#333333',
          '& .MuiTableCell-root': {
            color: '#fff',
            fontSize: '0.875rem',
            padding: '8px 16px',
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            color: '#333',
            fontSize: '0.875rem',
            padding: '8px 16px',
          },
        },
      },
    },
    // radiogroup
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#161E6A',
          // padding: '9px 0px',
          '&.Mui-checked': {
            color: '#161E6A',
          },
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#161E6A',
          '&.Mui-checked': {
            color: '#161E6A', // Change color to #161E6A when checked
          },
        },
      },
    },

    // MuiSelect

    MuiSelect: {
      defaultProps: { displayEmpty: true, variant: 'outlined' },
      styleOverrides: {
        root: {
          borderRadius: 4,
          fontSize: '1rem',
          // padding: "8px",
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#333333',
          },
          '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#444 !important',
          },
        },
        menu: {
          '& .MuiPaper-root': {
            border: '1px solid #ccc',
          },
        },
      },
    },

    // MuiAutocomplete: {
    //   defaultProps: {
    //     variant: "outlined",
    //   },
    //   styleOverrides: {
    //     root: {
    //       borderRadius: 4,
    //       fontSize: "1rem",
    //       // padding: "8px",
    //       "& .MuiOutlinedInput-notchedOutline": {
    //         borderColor: "#333333",
    //       },
    //       "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    //         borderColor: "#444 !important",
    //       },
    //     },
    //     menu: {
    //       "& .MuiPaper-root": {
    //         border: "1px solid #ccc",
    //       },
    //     },
    //   },
    // },

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          // Custom styles for the root element
          // borderColor: "#f00 !important",
          // borderRadius: "8px",
        },
        inputRoot: {
          // borderColor: "#f00 !important",
          // Custom styles for the input root element
          // backgroundColor: "lightgray",
        },
        paper: {
          // Custom styles for the paper (dropdown menu)
          backgroundColor: 'white',
          border: '1px solid #c6c6c6',
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.125rem',

          color: '#000',
          '&.MuiFormLabel-root.Mui-focused': {
            color: '#000',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '& .MuiFormControlLabel-label': {
            fontSize: '1rem',
            fontWeight: 400,
            color: '#333333',
            fontStyle: 'normal',
          },
        },
      },
    }, //End MuiFormControlLabel
    MuiListItemButton: {
      //MuiListButton start
      styleOverrides: {
        root: {
          color: localStorage.getItem('theme') === 'light' ? '#fff' : '#000',
          justifyContent: 'center',
          '&:hover': {
            background:
              localStorage.getItem('theme') === 'light'
                ? `rgba(198, 198, 198, 0.4)`
                : `rgba(0, 0, 0, 0.3)`,
            borderRadius: '4px',
          },
          '&.Mui-selected': {
            backgroundColor:
              localStorage.getItem('theme') === 'light' ? `#fff` : `#333`,
            borderRadius: `4px !important`,
            color:
              localStorage.getItem('theme') === 'light' ? `#7378A6` : `#fff`,
          },
        },
      },
    }, //MuiListButton start
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          // background:'red',
          color: '#fff',
          fontSize: '1rem',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#161e6a',
        },
      },
    },
  },
});
const darkTheme = createTheme({
  root: {
    display: 'flex',
  },
  palette: {
    mode: 'dark',
    base: {
      main: '#1A1B24',
    },
    onSurface: {
      main: '#2C2D36',
    },
    onSurface2: {
      main: '#31323A',
    },
    onColorSurfaceText: {
      main: '#121212',
    },
    lightBgBlue: {
      light: '#CED2F3',
    },
    white: {
      main: '#FFFFFF',
      dark: '#E8E8E9',
    },
    grey: {
      main: '#D1D1D3',
      light: '#A3A4A7',
    },
    blue: {
      main: '#9DA6F7',
      light: '#CED2F3',
      dark: '#7378A6',
    },
    green: {
      main: '#9CF7E4',
      light: '#CEF3EC',
      dark: '#73A69B',
    },
    yellow: {
      main: '#F7DC9D',
      light: '#F3E8CE',
      dark: '#A69773',
    },
    black: {
      main: '#000000',
      light: '#5E5E5E',
      dark: '#333333',
    },
    red: {
      main: '#F79DA3',
      light: '#F3CED0',
      dark: '#A67376',
    },
    linearGradient: {
      main: 'linear-gradient(180deg, #CED1F3 1.04%, #CEF3EC 100%)',
    },
    graph01: {
      main: '#E8A28F',
      // mainRgb: 'rgba(216, 73, 69, 0.5)',
    },
    graph02: {
      main: '#E8C58F',
      // mainRgb: 'rgba(216, 100, 69, 0.5)',
    },
    graph03: {
      main: '#E8E28F',
      // mainRgb: 'rgba(216, 158, 69, 0.5)',
    },
    graph04: {
      main: '#8FE898',
      mainRgb: 'rgba(216, 207, 69, 0.5)',
    },
    graph05: {
      main: '#8FE8E7',
      mainRgb: 'rgba(69, 216, 83, 0.5)',
    },
    graph06: {
      main: '#8FBCE8',
      mainRgb: 'rgba(69, 216, 215, 0.5)',
    },
    graph07: {
      main: '#B98FE8',
      mainRgb: 'rgb(69, 143, 216, 0.5)',
    },
    graph08: {
      main: '#E88FC4',
      mainRgb: 'rgba(139, 69, 216, 0.5)',
    },
    graph09: {
      main: '#E8928F;',
      mainRgb: 'rgba(139, 69, 216, 0.5)',
    },
    borderColor: {
      main: '#c6c6c6',
    },
    chartFont: {
      main: '#FFFFFF',
    },
    gridColor: {
      main: '#D1D1D3',
    },
  },
  typography: commonTypography,
  shadows: 'none',
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '8px 16px',
          textTransform: 'capitalize',
          boxShadow: 'none',
        },
        // "&.Mui-selected":{
        //     background:"161E6A",
        //     color:"#fff",
        //     textDecoration:"none",
        //     borderBottom:'none ',
        // },
        sizeMedium: {
          fontSize: '1rem',
        },
        sizeSmall: {
          fontSize: '0.875rem',
        },
        sizeLarge: {
          fontSize: '1.125rem',
        },
        contained: {
          backgroundColor: '#7378A6',
          color: '#FFF',
          '&:hover': {
            backgroundColor: '#161E6A',
            color: '#FFF',
            boxShadow: 'none',
          },
          '&:active': {
            outline: '1px solid #ACF1E3',
          },
        },
        text: {
          color: '#7378A6',
          '&:hover': {
            color: '#FFF',
          },
          '&:active': {
            outline: '1px solid #ACF1E3',
          },
          '&:select': {},
        },
        outlined: {
          color: '#7378A6',
          borderColor: '#7378A6',
          '&:hover': {
            color: '#FFF',
          },
          '&:active': {
            outline: '1px solid #ACF1E3',
          },
          '::selection': {
            color: '#2e8b57',
          },
        },
      },
    }, //MuiButton ends
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& input': {
              fontSize: '0.825rem',
              fontWeight: '500',
            },
            '& input::placeholder': { fontSize: '0.825rem', fontWeight: '500' },
            '& fieldset': {
              borderColor: '#FFFFFF',
            },
            '&:hover fieldset': {
              // borderColor: '#00f',
            },
            '&.Mui-focused fieldset': {
              // borderColor: '#bbb',
            },
            '&.Mui-disabled fieldset': {
              backgroundColor: '#EBECF5',
            },
          },
          '&.Mui-disabled .MuiOutlinedInput-root fieldset': {
            backgroundColor: 'red',
          },
        },
      },
    }, //end MuiTextField
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: '#7378A6',
          color: '#121212',
          borderRadius: '4px',
          padding: '4px',
          marginRight: '8px',
        },
        deleteIcon: {
          color: '#121212',
          fontSize: '10px',
        },
      },
    }, //end MuiChip
    MuiAlert: {
      styleOverrides: {
        filledSuccess: {
          backgroundColor: '#73A69B',
          color: 'black',
        },
        filledError: {
          backgroundColor: '#A67376',
          color: 'black',
        },
        filledWarning: {
          backgroundColor: '#A69773',
          color: 'black',
        },
        filledInfo: {
          backgroundColor: '#7378A6',
          color: 'black',
        },
      },
    },
  },
});

export { lightTheme, darkTheme };
