import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  container: (props) => ({
    boxSizing: 'border-box',
    width: props.width,
    height: props.height,
    backgroundColor: props.isDragging
      ? 'rgb(172, 179, 241,0.2'
      : theme.palette.white.main,
    border: `1px ${props.isDragging ? 'dashed #ACB3F1' : 'solid #5E5E5E'}`,
    borderRadius: '6px',
    color: props.isDragging ? '#ACB3F1' : '#333333',

    display: props.document_type === 'video' || props.isDragging ? 'flex' : '',
    flexDirection: props.document_type === 'video' ? 'column' : 'row',
    justifyContent: props.isDragging
      ? 'center'
      : props.document_type === 'video'
      ? 'center'
      : '',
    alignItems: props.isDragging
      ? 'center'
      : props.document_type === 'video'
      ? 'center'
      : '',
  }),

  icon: (props) => ({
    fontSize: '32px',
    color: '#5B6AF1',
    marginBottom: '2px',
  }),
  content: (props) => ({
    // padding: "9px",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    // textAlign: "center",
    cursor: 'pointer',
    color: props.isDragging ? '#5B6AF1' : '#333333',
    height: '154px',
    position: 'relative',
  }),
  renderText: (props) => ({
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    height:
      props.document_type === 'video'
        ? ''
        : props.document_type === 'image'
        ? '154'
        : '97px',
    padding: props.document_type === 'file' ? '7px' : '',
    flexDirection: 'column',
  }),
  renderIcon: (props) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    height: props.document_type === 'video' ? ' 70px' : '57px',
  }),
  text: (props) => ({
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    padding: '2px 8px',

    height: 'auto',
    justifyContent: 'center',
  }),
  filename: {
    wordWrap: ' break-word',
    whiteSpace: 'pre-wrap',
    //  overflow:"hidden",
    //  textOverflow:"ellipsis"
    maxWidth: '100%',
  },
  action: (props) => ({
    position: 'absolute',
    left: props.document_type === 'video' ? '245px' : '123px',
    zIndex: 999,
    // top: theme.spacing(0),
    // right: theme.spacing(0),
    height: '24px',
    width: ' 24px',
    borderRadius: '50%',
  }),
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '153px',
    minWidth: '153px',
  },
  video: {
    // display: "block",
    borderRadius: '6px',
    height: 'auto',
    width: '100%',
    zIndex: '0',
    right: '-130px',
    '&::-webkit-media-controls': {
      display: 'none',
    },
    '&::-webkit-media-controls-start-playback-button': {
      display: 'none',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-moz-media-controls': {
      display: 'none',
    },
    '&::-moz-media-controls-start-playback-button': {
      display: 'none',
    },
    '&::-moz-range-thumb': {
      display: 'none',
    },
  },

  playButton: {
    position: 'absolute',
    top: ' 50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '1',
    fontSize: '30px',
    fontWeight: '600',
    color: '#fff',
    border: 'none',
    cursor: ' pointer',
  },

  source: {
    position: 'relative',
    zIndex: '0',
  },

  overlay: {
    // backgroundColor: "#000",
    // position:"relative",
    // width: "1200px",
    // height: "565px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  dialog: {
    // "& .MuiDialog-paper": {
    //    maxWidth: "1200px",
    //    maxHeight: "565px" ,
    //    backgroundColor: "#000",
    // },
  },
  displayFile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  innerOverlay: {
    position: 'absolute',
    width: '100%',
    height: '10%',
    // background: "linear-gradient(180deg, rgba(0, 0, 0, 0.7) 15.16%, rgba(0, 0, 0, 0) 83.52%)",
    top: '0px',
    left: '0px',
  },
  title: {
    position: 'absolute',
    color: '#fff',
    top: '20px',
    left: '20px',
    // transform:translate(-20% ,-80%)
  },
  closeButton: {
    position: 'absolute',
    color: '#fff',
    top: '20px',
    right: '30px',
    fontSize: '26px',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    // border:'1px solid #f00',
    objectFit: 'fill',
    backgroundColor: '#fff',
    borderRadius: 1,
  },
  videoOverlay: {
    minWidth: '100%',
    minHeight: '100%',
    objectFit: 'fill',
    bottom: '0px',
    right: '0px',
  },
}));
