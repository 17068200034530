import InputField from './InputField/InputField';
import Button from './Button/Button';
import ActionButton from './ActionButton/ActionButton';
import Select from './Select/Select';
import RadioGroup from './RadioGroup/RadioGroup';
import Checkbox from './Checkbox/Checkbox';
import Toggle from './Toggle/Toggle';
import Popup from './Popup/Popup';
import AutoComplete from './AutoComplete/AutoComplete';
import Tags from './Tags/Tags';
import Hexagon from './Polygon/Hexagon';
import DownArrow from './Polygon/DownArrow';
import ActionMenu from './ActionMenu/ActionMenu';
import ReusableTooltip from './Tooltip/Tooltip';
import ReusableCard from './Card/Card';
import CardType2 from './Card/CardType2';
import Stepper from './Stepper/Stepper';
import Accordion from './Accordion/Accordion';
import ReusableTags from './ReusableTags/ReusableTags';
import GroupButton from './GroupButton/GroupButton';
import Status from './Status/Status';
import MultiSelect from './MultiSelect/MultiSelect';

export const Common = {
    InputField,
    Button,
    ActionButton,
    Select,
    MultiSelect,
    RadioGroup,
    Checkbox,
    Toggle,
    Popup,
    AutoComplete,
    GroupButton,
    Tags,
    Hexagon,
    DownArrow,
    ActionMenu,
    ReusableTooltip,
    ReusableCard,
    CardType2,
    Stepper,
    Accordion,
    ReusableTags,
    Status
}
