import {
  Box,
  FormControl,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import React from 'react';
import useStyles from './styles';

const InputBox = (props) => {
  const {
    size = 'medium',
    variant,
    placeholder,
    startAdornment,
    endAdornment,
    disable,
    type,
    error,
    value,
    onChange,
    maxLength,
    name,
    id,
    questionsFontStyle,
  } = props;
  const classes = useStyles();

  const hasError = Boolean(error);

  return (
    <FormControl fullWidth>
      <TextField
        sx={{
          fontFamily: `${questionsFontStyle}, sans-serif`,
          '& .MuiInputBase-input': {
            fontFamily: `${questionsFontStyle}, sans-serif`,
          },
        }}
        id={id}
        type={type || 'text'}
        variant={variant || 'outlined'}
        className={`${classes.root} ${hasError && classes.errorBorder}`}
        size={size}
        disabled={disable || false}
        inputProps={{ maxLength: maxLength }}
        InputProps={{
          startAdornment: startAdornment && (
            <InputAdornment position="start">
              <Box className={classes.icon}>
                <Icon className={startAdornment} />
              </Box>
            </InputAdornment>
          ),
          endAdornment: endAdornment && (
            <InputAdornment position="start">
              <IconButton disableRipple className={classes.iconButton}>
                <Icon className={endAdornment} />
              </IconButton>
            </InputAdornment>
          ),
          autoComplete: 'off',
        }}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        fullWidth
        {...(error && { error: true, helperText: error })}
        name={name}
      />
    </FormControl>
  );
};

export default InputBox;
