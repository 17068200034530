import * as React from 'react';
import {Button, Box, ButtonGroup, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList, Typography} from '@mui/material';
import useStyles from './style';

const options = ['Button1', 'Button2 ', 'Button3', 'Button4'];

export default function SplitButton(props) {
  const {size, variant} = props;
  const classes = useStyles(props);
  const [open, setOpen] = React.useState(false);
  const [iconAttribute, setIconAttribute] = React.useState("iconSizeSmall");
  const [textAttribute, setTextAttribute] = React.useState("textSizeSmall");
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  React.useEffect(() => {
    if(size === "small"){
      setIconAttribute("iconSizeSmall");
      setTextAttribute("textSizeSmall");
    }else if(size === "medium"){
      setIconAttribute("iconSizeMedium");
      setTextAttribute("textSizeMedium");
    }else{
      setIconAttribute("iconSizeLarge");
      setTextAttribute("textSizeLarge");
    }
  }, [size])
  

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup variant={variant} ref={anchorRef} aria-label="split button" disableRipple>
        <Button onClick={handleClick} className={`${classes.root} ${classes[variant]} ${classes.buttonWidth}` }
        startIcon={<Typography className={`fh-icon fh-icon-plus ${classes[iconAttribute]} ` }></Typography>}>
        <Typography className={classes[textAttribute]}>  {options[selectedIndex]} </Typography>
        </Button>
        <Button
        
        className={`${classes.root} ${classes[variant]}`}
          onClick={handleToggle}
        >
          {open ? 
          <Typography className={`fh-icon fh-icon-up-filled ${classes[iconAttribute]}`}></Typography> : 
          <Typography className={`fh-icon fh-icon-down-filled ${classes[iconAttribute]}`}></Typography>}
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem className={classes.MenuWidth}>
                  {options.map((option, index) => (
                    <MenuItem
                      className={`${classes[textAttribute]}`}
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
