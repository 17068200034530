import React, { useEffect, useState } from "react";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { menu } from "./menu";
import { hasChildren } from "./utils";
import useStyles from "./styles";

const NavList = (props) => {
  const handleOver = () => {
    props.setSidebarOpen(true);
  };
  return menu.map((item, key) => (
    <MenuItem
      key={key}
      item={item}
      id={1}
      props={props}
      handleOver={handleOver}
    />
  ));
};

const MenuItem = ({ item, id, props, handleOver }) => {
  const classes = useStyles();

  // Multilevel
  if (hasChildren(item)) {
    return <MultiLevel item={item} props={props} handleOver={handleOver} />;
  } else {
    // single level children
    if (id === 1) {
      return (
        <SingleLevel
          item={item}
          className={classes.title}
          props={props}
          handleOver={handleOver}
        />
      );
    }
    // children inside multilevel children
    return (
      <SingleLevel
        item={item}
        className={classes.subNav}
        props={props}
        handleOver={handleOver}
      />
    );
  }
};
const SingleLevel = ({ item, className, props, handleOver }) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const pathName = location.pathname;

  return (
    <List>
      <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton
          disableRipple={true}
          onClick={() => navigate(item.path)}
          className={`${
            pathName === item.path ? classes.active : classes.root
          }`}
        >
          {/*icons*/}
          {item.icon ? (
            <ListItemIcon
              className={classes.listIconItem}
              onMouseOver={handleOver}
            >
              <i
                className={
                  pathName === item.path ? classes.icon : classes.iconWhite
                }
              >
                {item.icon}
              </i>
            </ListItemIcon>
          ) : null}
          {/*titles*/}
          {props.sidebarOpen ? (
            <ListItemText
              primary={<span>{item.title}</span>}
              classes={{ primary: className }}
            />
          ) : null}
        </ListItemButton>
      </ListItem>
    </List>
  );
};

const MultiLevel = ({ item, props, handleOver }) => {
  const classes = useStyles();
  const { items: children } = item;

  const [open, setOpen] = useState(false);

  const location = useLocation();
  const pathName = location.pathname;

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    props.sidebarOpen ? setOpen(open) : setOpen(false);
  }, [props.sidebarOpen]);

  return (
    <List>
      <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton
          disableRipple={true}
          onClick={handleClick}
          className={`${
            pathName === item.path ? classes.active : classes.root
          }`}
        >
          {/*icons*/}
          <ListItemIcon
            className={classes.listIconItem}
            onMouseOver={handleOver}
          >
            <i
              className={
                pathName === item.path ? classes.icon : classes.iconWhite
              }
            >
              {item.icon}
            </i>
          </ListItemIcon>
          {/*titles*/}
          <ListItemText
            primary={
              props.sidebarOpen ? (
                <span>{item.title}</span>
              ) : (
                <span style={{ display: "none" }}>{item.title}</span>
              )
            }
            classes={{ primary: classes.title }}
          />
          {open ? (
            props.sidebarOpen ? (
              <i className="fh-icon fh-icon-up-filled" />
            ) : (
              <i
                className="fh-icon fh-icon-up-filled"
                style={{ display: "none" }}
              />
            )
          ) : props.sidebarOpen ? (
            <i className="fh-icon fh-icon-down-filled" />
          ) : (
            <i
              className="fh-icon fh-icon-down-filled"
              style={{ display: "none" }}
            />
          )}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {/*Navigation List*/}
          <List>
            <ListItem disablePadding sx={{ display: "block" }}>
              {children.map((child, key) => (
                <MenuItem key={key} item={child} id={2} props={props} />
              ))}
            </ListItem>
          </List>
        </Collapse>
      </ListItem>
    </List>
  );
};
export default NavList;
