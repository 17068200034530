import { createContext, useState, useContext } from "react";

const CreateContext = createContext();

export const SideBarContextProvider = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const handleClick = () => {
    setSidebarOpen(!sidebarOpen);
  };
  return (
    <CreateContext.Provider
      value={{ sidebarOpen, setSidebarOpen, handleClick }}
    >
      {children}
    </CreateContext.Provider>
  );
};

export const useSidebarContext = () => useContext(CreateContext);
