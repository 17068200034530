import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { Menu } from "@mui/material";

export default makeStyles((theme) => ({
  action: {
    fontSize: "14px",
    fontWeight: "500",
  },
  menuIcon: (props) => ({
    color: props.menuColor ? `${props.menuColor}!important` : "#5d5d5d",
    background: props.menuColor?'#5E5E5E':"",
    // fontSize: "16px !important",
    // mixBlendMode: props.menuColor ? "darken" : "",
    // opacity: props.menuColor ? 0.6 : "",
    opacity:props.menuColor ? 0.6 : 1,
    width: props.menuColor ? "24px" : "",
    height: props.menuColor ? "24px" : "",
    borderRadius: props.menuColor ? "50%" : "",
    display: props.menuColor ? "flex" : "",
    justifyContent: props.menuColor ? "center" : "",
    alignItems: props.menuColor ? "center" : "",
  }),
  actionButton: {
    "&:hover > .menuIcon": {
      // background: "#5E5E5E",
      // mixBlendMode: "difference",
      // opacity: 0.5,
      // color: "#fff",
    },
  },
  icon: {
    marginRight: "6px",
  },
}));

export const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: props.vertical,
      horizontal: props.horizontal,
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 4,
    border: "1px solid rgba(0,0,0,.15) !important",
    marginTop: theme.spacing(2.5),
    maxWidth: 250,
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
  },
}));
