// import React from 'react';
// import ToggleButton from '@mui/material/ToggleButton';
// import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
// import { makeStyles } from '@mui/styles';
// import { Stack } from '@mui/material';
// import { useTheme } from '@mui/material/styles';

// const useStyles = makeStyles(theme => ({
//   rootToggle: {
//     margin: `${theme.spacing(2, 0)} !important`,
//   },
//   borderStyle:{
//     borderLeft: localStorage.getItem("theme") === "light" ? `1px solid ${theme.palette.grey.main} !important` : ``,
//   },
//   buttonColor: {
//     backgroundColor: localStorage.getItem("theme") === "light" ?`${theme.palette.blue.light} !important`:``,
//     color: localStorage.getItem("theme") === "light" ?`${theme.palette.black.dark} !important`:``,
//     fontSize: '16px !important',
//     fontWeight: '500 !important',
//     padding: `${theme.spacing(1, 2)} !important`,
//     "&.Mui-selected": {
//       backgroundColor: localStorage.getItem("theme") === "light" ?`${theme.palette.blue.dark} !important`:``,
//       color: localStorage.getItem("theme") === "light" ?`${theme.palette.white.main} !important`:``,
//     }
//   }
// })
// );

// export default function Toggle(props) {
//   // const { text1, text2, text3 } = props;
//   const classes = useStyles();
//   const theme = useTheme();
//   const { tValue, size } = props;
//   const [alignment, setAlignment] = React.useState('left');
//   const handleAlignment = (event, newAlignment) => {
//     if (newAlignment !== null) {
//       setAlignment(newAlignment);
//     }
//   };
//   return (
//     <Stack direction="row" spacing={4} className={classes.rootToggle}>
//       <ToggleButtonGroup
//         value={alignment}
//         exclusive
//         onChange={handleAlignment}
//         aria-label="text alignment"
//         size={size || 'large'}

//       >
//         {tValue.map((item, index) => (
//           <ToggleButton value={item.value} key={index} className={index === 0 ? `${classes.buttonColor}` : `${classes.buttonColor} ${classes.borderStyle}`} aria-label="left aligned"

//           >
//             {item.text}
//           </ToggleButton>
//         ))}
//       </ToggleButtonGroup>
//     </Stack>

//   );
// }
import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { makeStyles } from "@mui/styles";
import { Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles((theme) => ({
  rootToggle: {
    margin: `${theme.spacing(2, 0)} !important`,
  },

  borderStyle: {
    borderLeft: `1px solid ${theme.palette.grey.main} !important`,
  },

  buttonColor: {
    backgroundColor: `${theme.palette.blue.light} !important`,
    color: `${theme.palette.black.dark} !important`,
    fontSize: "16px !important",
    fontWeight: "500 !important",
    padding: `${theme.spacing(1, 2)} !important`,
    "&.Mui-selected": {
      backgroundColor: `${theme.palette.blue.dark} !important`,
      color: `${theme.palette.white.main} !important`,
    },
    "&.MuiToggleButton-root":{  //added for toggle button styles
      textTransform:"none",
      width: "122px",
      height: "40px",
  
    }
  },
}));

export default function Toggle(props) {
  
  const classes = useStyles();
  const { tValue, size, handleChange, value, selectedId } = props;

  return (
    <Stack direction="row" spacing={4} className={classes.rootToggle}>
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={handleChange}
        size={size || "large"}
      >
        {tValue.map((item, index) => (
          <ToggleButton
            value={item}
            key={index}
             selected={item.value === selectedId ? true : false}
            className={
              index === 0
                ? `${classes.buttonColor}`
                : `${classes.buttonColor} ${classes.borderStyle}`
            }
            aria-label="left aligned"
          >
            {item.text}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Stack>
  );
}
