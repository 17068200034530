import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme)=>({
    root: {
      backgroundColor: `${theme.palette.onSurface.main} !important`,
      overflow:"hidden",
      border : '1px solid #C6C6C6',
      borderRadius:'8px',
      boxShadow:'none !important',
    },
    
  }));




export default function ReusableCard(props) {
    const classes = useStyles(props);
  return (
    // <Box className={classes.cardBox} >
      <Card className={classes.root} sx={{
        display:"flex", 
        aligntems:"center", 
        justifyContent:"center"
      }}
        >
      <CardContent sx={{textAlign: "center"}}>
      {props.number}
      {props.title}
      
    </CardContent>
      </Card>
    // </Box>
  );
}
