import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { getMethod } from '../../../../api/getMethod';
import {
  Box,
  CircularProgress,
  Grid,
  Icon,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Common } from '../../../../components/common/Common';
import ThankYou from '../ThankYou/ThankYou';
import useStyles from '../styles';
import ThanksImage from '../../../../components/Images/thankyou.svg';
import EndUserFooter from '../../../../components/Footer/EndUserFooter';
import SubmittedSurvey from '../SubmittedSurvey/SubmittedSurvey';
import { toast, ToastContainer } from 'react-toastify';
import DisplayQuestions from '../DisplayQuestions';

const KnownUserResponsePage = (props) => {
  // console.log('document.referrer>>>>>', document.referrer);
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down('md'));
  const bodyBoxPadding = isMatch
    ? {
        padding: '5px',
      }
    : { padding: '40px' };

  const { surveyCode, userUniqueId, cmp_fk_id, smId, geoData } = props;
  const [loading, setLoading] = useState(false);
  const [gridData, setGridData] = useState({});
  const [hashmap, setHashmap] = useState({});
  const [smileySelections, setSmileySelections] = useState({});
  const [starSelections, setStarSelections] = useState({}); //starRating
  const [emojiSelections, setEmojiSelections] = useState({});
  const [email, setEmailValue] = useState({});
  const [number, setNumberValue] = useState({});
  const [message, setMessage] = useState('');
  const [buttonClicked, setButtonClicked] = useState(false);
  const [selectedId, setSelectedId] = useState();

  const [mulChResponses, setMulChResponses] = useState({
    // Initialize with empty array for current question
  });
  const [radioValue, setRadioValue] = useState(null);

  const [isSubmitted, setisSubmitted] = useState(false);
  const [fontSetting, setFontSetting] = useState([]);
  const [titleFontStyle, setTitleFontStyle] = useState([]);
  const [titleFontSize, setTitleFontSize] = useState('');
  const [descriptionFontStyle, setDescriptionFontStyle] = useState([]);
  const [descriptionFontSize, setDescriptionFontSize] = useState('');
  const [sectionTitleFontStyle, setSectionTitleFontStyle] = useState([]);
  const [sectionTitleFontSize, setSectionTitleFontSize] = useState('');
  const [questionsFontStyle, setQuestionsFontStyle] = useState([]);
  const [questionFontSize, setQuestionsFontSize] = useState('');
  const [optionsFontStyle, setOptionsFontStyle] = useState([]);
  const [optionsFontSize, setOptionsFontSize] = useState('');
  const [headerFontStyle, setHeaderFontStyle] = useState([]);
  const [headerFontSize, setHeaderFontSize] = useState('');
  const [messageFontStyle, setMessageFontStyle] = useState([]);
  const [messageFontSize, setMessageFontSize] = useState('');
  const [errorMessages, setErrorMessages] = useState([]); //error message state
  const totalSections = gridData.sections ? gridData.sections.length : 0;
  const [currentSection, setCurrentSection] = useState(0);

  const classes = useStyles();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);

  //
  const [formData, setFormData] = useState({
    shortAnswer: '',
    paraAns: '',
    singleChoiceAns: '',
    npsRadioAns: '',
    radioRatingAns: '',
  });

  const [selectedOptions, setSelectedOptions] = useState({});
  const handleCheckboxChange = (optionId, questionId) => {
    setSelectedOptions((prevSelectedOptions) => {
      const updatedOptions = { ...prevSelectedOptions };
      if (!updatedOptions[questionId]) {
        updatedOptions[questionId] = [];
      }
      if (updatedOptions[questionId].includes(optionId)) {
        updatedOptions[questionId] = updatedOptions[questionId].filter(
          (id) => id !== optionId
        );
      } else {
        updatedOptions[questionId].push(optionId);
      }
      return updatedOptions;
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const questionsData =
    gridData &&
    gridData.sections &&
    gridData.sections[currentSection]?.questions
      ? gridData.sections[currentSection].questions
      : [];

  /////////////////////////////////////////////////////////////
  const totalQuestions = questionsData ? questionsData.length : 0;

  const handleClickedRatingStar = (id) => {
    setSelectedId((prevState) => {
      if (prevState === id) {
        setButtonClicked((prevState) => !prevState);
      } else {
        setButtonClicked(true);
      }
      return id;
    });
  };

  const handlesubmit = (e) => {
    e.preventDefault();

    // Check for missing mandatory questions
    const missingMandatoryQuestions = questionsData.filter((question) => {
      const questionId = question.que_pk_id;
      const isMandatory = question.que_is_mandatory;

      if (!isMandatory) return false;

      const shortAnswer = formData[`shortAnswer_${questionId}`];
      const paraAnswer = formData[`paraAns_${questionId}`];

      const isShortAnswerEmpty = !shortAnswer || shortAnswer.trim() === '';
      const isParaAnswerEmpty = !paraAnswer || paraAnswer.trim() === '';
      const isNpsRadioAnswerEmpty = !formData[`npsRadioAns_${questionId}`];
      const isSmileySelectionEmpty = !smileySelections[questionId];
      const isSingleChoiceAnswerEmpty =
        !formData[`singleChoiceAns_${questionId}`];
      const isRadioRatingAnswerEmpty =
        !formData[`radioRatingAns_${questionId}`];
      const isSelectedOptionsEmpty =
        !selectedOptions[questionId] ||
        selectedOptions[questionId].length === 0;
      const isStarSelectionEmpty = !starSelections[questionId];
      const isEmojiSelectionEmpty = !emojiSelections[questionId];
      const isMulChResponsesEmpty =
        !mulChResponses[questionId] || mulChResponses[questionId].length === 0;

      return (
        isShortAnswerEmpty &&
        isParaAnswerEmpty &&
        isNpsRadioAnswerEmpty &&
        isSmileySelectionEmpty &&
        isSingleChoiceAnswerEmpty &&
        isRadioRatingAnswerEmpty &&
        isSelectedOptionsEmpty &&
        isStarSelectionEmpty &&
        isEmojiSelectionEmpty &&
        isMulChResponsesEmpty
      );
    });

    if (missingMandatoryQuestions.length > 0) {
      const newErrorMessages = {};
      missingMandatoryQuestions.forEach((question) => {
        newErrorMessages[question.que_pk_id] =
          'This question field is mandatory';
      });
      setErrorMessages(newErrorMessages);
      return;
    }

    // Clear error messages if all mandatory questions are filled
    setErrorMessages({});

    // Construct responses array
    const responses = questionsData.map((question) => {
      const response = {
        que_fk_id: question.que_pk_id,
        qtype_fk_id: question.qtype_fk_id,
        que_is_mandatory: question.que_is_mandatory,
        sec_fk_id: 1, // You may need to update this section ID accordingly
      };

      // Handle different question types
      switch (question.qtype_fk_id) {
        case 1:
          response.text_response =
            formData[`shortAnswer_${question.que_pk_id}`]?.trim() || '';
          break;
        case 2:
          response.text_response =
            formData[`paraAns_${question.que_pk_id}`]?.trim() || '';
          break;
        case 3:
          response.text_response =
            question.otype_fk_id === 1
              ? formData[`npsRadioAns_${question.que_pk_id}`]
                ? String(formData[`npsRadioAns_${question.que_pk_id}`])
                : ''
              : question.otype_fk_id === 2
              ? smileySelections[question.que_pk_id]
                ? String(smileySelections[question.que_pk_id])
                : ''
              : '';
          response.options =
            question.otype_fk_id === 1
              ? formData[`npsRadioAns_${question.que_pk_id}`]
                ? [formData[`npsRadioAns_${question.que_pk_id}`]]
                : [Number('')]
              : question.otype_fk_id === 2
              ? smileySelections[question.que_pk_id]
                ? [smileySelections[question.que_pk_id]]
                : [Number('')]
              : [Number('')];
          break;
        case 4:
          response.text_response =
            formData[`singleChoiceAns_${question.que_pk_id}`] || '';
          response.options = formData[`singleChoiceAns_${question.que_pk_id}`]
            ? [formData[`singleChoiceAns_${question.que_pk_id}`]]
            : [];
          break;
        case 5:
          response.text_response = mulChResponses[question.que_pk_id]
            ? String(mulChResponses[question.que_pk_id])
            : '';
          response.options = mulChResponses[question.que_pk_id]
            ? mulChResponses[question.que_pk_id]
            : [];
          break;
        case 6:
          response.text_response = email[question.que_pk_id] || '';
          break;
        case 7:
          response.text_response = number[question.que_pk_id]
            ? String([number[question.que_pk_id]])
            : '';
          response.options = number[question.que_pk_id]
            ? [number[question.que_pk_id]]
            : [];
          break;
        case 8:
          response.text_response =
            question.otype_fk_id === 3 || question.otype_fk_id === 6
              ? starSelections[question.que_pk_id] === undefined
                ? ''
                : String(starSelections[question.que_pk_id])
              : question.otype_fk_id === 2 || question.otype_fk_id === 5
              ? emojiSelections[question.que_pk_id] === undefined
                ? ''
                : String(emojiSelections[question.que_pk_id])
              : question.otype_fk_id === 1 || question.otype_fk_id === 4
              ? formData[`radioRatingAns_${question.que_pk_id}`]
                ? String(formData[`radioRatingAns_${question.que_pk_id}`])
                : ''
              : '';
          response.options =
            question.otype_fk_id === 3 || question.otype_fk_id === 6
              ? starSelections[question.que_pk_id] === undefined
                ? [Number('')]
                : [String(starSelections[question.que_pk_id])]
              : question.otype_fk_id === 2 || question.otype_fk_id === 5
              ? emojiSelections[question.que_pk_id] === undefined
                ? [Number('')]
                : [String(emojiSelections[question.que_pk_id])]
              : question.otype_fk_id === 1 || question.otype_fk_id === 4
              ? formData[`radioRatingAns_${question.que_pk_id}`] === undefined
                ? [Number('')]
                : [String(formData[`radioRatingAns_${question.que_pk_id}`])]
              : [''];
          break;
        default:
          break;
      }

      return response;
    });

    // If all mandatory questions are filled, proceed to submit the form
    const data = {
      user_unique_id: userUniqueId,
      sm_fk_id: smId,
      cmp_fk_id: cmp_fk_id,
      is_submit: true,
      responses,
    };

    axios
      .post(process.env.REACT_APP_BASE_URL + 'v1/survey/user/response/', data)
      .then((response) => {
        // console.log('response>>>>>', response);
        if (response.data.success === true) {
          setisSubmitted(true);
        } else {
          setisSubmitted(false);
          toast.error(
            'There was an error submitting the survey. Please try again.',
            {
              style: {
                fontSize: '1rem',
              },
            }
          );
        }
      })
      .catch((error) => {
        // Handle any errors here
        console.error('API Error:', error);
        setisSubmitted(false);
        // toast.error('There was an error submitting the survey. Please try again.');
      });
  };

  const handleSinglesubmit = (e) => {
    e.preventDefault();
    const currentQuestionData = questionsData[currentQuestion];

    // Check if the current question is mandatory and if it has been answered
    const isMandatory = currentQuestionData.que_is_mandatory;
    const shortAnswer =
      formData[`shortAnswer_${currentQuestionData.que_pk_id}`];
    const paraAnswer = formData[`paraAns_${currentQuestionData.que_pk_id}`];

    const isAnswered =
      (shortAnswer && shortAnswer.trim() !== '') ||
      (paraAnswer && paraAnswer.trim() !== '') ||
      formData[`singleChoiceAns_${currentQuestionData.que_pk_id}`] ||
      formData[`npsRadioAns_${currentQuestionData.que_pk_id}`] ||
      formData[`radioRatingAns_${currentQuestionData.que_pk_id}`] ||
      smileySelections[currentQuestionData.que_pk_id] ||
      starSelections[currentQuestionData.que_pk_id] ||
      emojiSelections[currentQuestionData.que_pk_id] ||
      (mulChResponses[currentQuestionData.que_pk_id] &&
        mulChResponses[currentQuestionData.que_pk_id].length > 0);

    // If the question is mandatory and not answered, set an error message and return
    if (isMandatory && !isAnswered) {
      setErrorMessages((prevErrorMessages) => ({
        ...prevErrorMessages,
        [currentQuestionData.que_pk_id]: 'This question field is mandatory',
      }));
      return;
    }

    // Clear error messages if the current question is answered
    setErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      [currentQuestionData.que_pk_id]: '',
    }));

    // Construct responses array
    const responses = [];
    questionsData.forEach((question) => {
      const response = {
        que_fk_id: question.que_pk_id,
        qtype_fk_id: question.qtype_fk_id,
        que_is_mandatory: question.que_is_mandatory,
        sec_fk_id: 1, // You may need to update this section ID accordingly
      };

      // Handle different question types
      switch (question.qtype_fk_id) {
        case 1: // Short answer question
          response.text_response =
            formData[`shortAnswer_${question.que_pk_id}`]?.trim() || '';
          break;
        case 2: // Textarea choice question
          response.text_response =
            formData[`paraAns_${question.que_pk_id}`]?.trim() || '';
          break;
        case 3: // NPS choice question
          response.text_response =
            question.otype_fk_id === 1
              ? formData[`npsRadioAns_${question.que_pk_id}`]
                ? String(formData[`npsRadioAns_${question.que_pk_id}`])
                : ''
              : question.otype_fk_id === 2
              ? smileySelections[question.que_pk_id]
                ? String(smileySelections[question.que_pk_id])
                : ''
              : '';
          response.options =
            question.otype_fk_id === 1
              ? formData[`npsRadioAns_${question.que_pk_id}`]
                ? [formData[`npsRadioAns_${question.que_pk_id}`]]
                : [Number('')]
              : question.otype_fk_id === 2
              ? smileySelections[question.que_pk_id]
                ? [smileySelections[question.que_pk_id]]
                : [Number('')]
              : [Number('')];
          break;
        case 4: // Single choice question
          response.text_response =
            formData[`singleChoiceAns_${question.que_pk_id}`] || '';
          response.options = formData[`singleChoiceAns_${question.que_pk_id}`]
            ? [formData[`singleChoiceAns_${question.que_pk_id}`]]
            : [];
          break;
        case 5: // Multiple choice question
          response.text_response = mulChResponses[question.que_pk_id]
            ? String(mulChResponses[question.que_pk_id])
            : '';
          response.options = mulChResponses[question.que_pk_id]
            ? mulChResponses[question.que_pk_id]
            : [];
          break;
        case 6: // Email question
          response.text_response = email[question.que_pk_id] || '';
          break;
        case 7: // Number question
          response.text_response = number[question.que_pk_id]
            ? String(number[question.que_pk_id])
            : '';
          response.options = number[question.que_pk_id]
            ? [number[question.que_pk_id]]
            : [];
          break;
        case 8:
          response.text_response =
            question.otype_fk_id === 3 || question.otype_fk_id === 6
              ? starSelections[question.que_pk_id] === undefined
                ? ''
                : String(starSelections[question.que_pk_id])
              : question.otype_fk_id === 2 || question.otype_fk_id === 5
              ? emojiSelections[question.que_pk_id] === undefined
                ? ''
                : String(emojiSelections[question.que_pk_id])
              : question.otype_fk_id === 1 || question.otype_fk_id === 4
              ? formData[`radioRatingAns_${question.que_pk_id}`]
                ? String(formData[`radioRatingAns_${question.que_pk_id}`])
                : ''
              : '';

          response.options =
            question.otype_fk_id === 3 || question.otype_fk_id === 6
              ? starSelections[question.que_pk_id] === undefined
                ? [Number('')]
                : [String(starSelections[question.que_pk_id])]
              : question.otype_fk_id === 2 || question.otype_fk_id === 5
              ? emojiSelections[question.que_pk_id] === undefined
                ? [Number('')]
                : [String(emojiSelections[question.que_pk_id])]
              : question.otype_fk_id === 1 || question.otype_fk_id === 4
              ? formData[`radioRatingAns_${question.que_pk_id}`] === undefined
                ? [Number('')]
                : [String(formData[`radioRatingAns_${question.que_pk_id}`])]
              : [''];
          break;
        default:
          // Handle other question types
          break;
      }

      // Push the constructed response object into the responses array
      responses.push(response);
    });

    // If all mandatory questions are filled, proceed to submit the form
    const data = {
      user_unique_id: userUniqueId,
      sm_fk_id: smId,
      cmp_fk_id: cmp_fk_id,
      is_submit: true,
      responses,
    };

    axios
      .post(process.env.REACT_APP_BASE_URL + 'v1/survey/user/response/', data)
      .then((response) => {
        if (response.data.success === true) {
          setisSubmitted(true);
        } else {
          setisSubmitted(false);
          toast.error(
            'There was an error submitting the survey. Please try again.'
          );
        }
      })
      .catch((error) => {
        // Handle any errors here
        console.error('API Error:', error);
        setisSubmitted(false);
      });
  };

  // Call survey API after geoData is set
  // useEffect(() => {
  //   if (smId && geoData.ip) {
  //     const getSurveyApiData = async () => {
  //       setLoading(true);
  //       const data = {
  //         state: geoData.region || '',
  //         city: geoData.city || '',
  //         country: geoData.country || '',
  //         lat: geoData.lat ? geoData.lat : '',
  //         lon: geoData.lon ? geoData.lon : '',
  //         ipaddress: geoData.ip || '',
  //         name: '',
  //         email: '',
  //         mobile: '',
  //       };

  //       let urls;
  //       if (userUniqueId && cmp_fk_id) {
  //         urls = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SURVEY_USER_CAPTURE}/?sm_unique_code=${surveyCode}&cmp_fk_id=${cmp_fk_id}&user_unique_id=${userUniqueId}`;
  //       } else if (userUniqueId) {
  //         urls = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SURVEY_USER_CAPTURE}/?user_unique_id=${userUniqueId}&sm_unique_code=${surveyCode}`;
  //       }
  //       try {
  //         const res = await axios.post(urls, data);
  //         if (res.data) {
  //           // setLoading(true);
  //           setGridData(res.data.data);
  //           setMessage(res.data.message);
  //           setLoading(false);
  //         } else {
  //           setGridData([]);
  //           setLoading(false);
  //         }
  //       } catch (err) {
  //         console.error('Error fetching survey data:', err);
  //         setLoading(false);
  //       }
  //     };
  //     getSurveyApiData();
  //   }
  // }, [geoData, userUniqueId]);

  useEffect(() => {
    if (smId && geoData.ip) {
      // First show loading spinner for 2 seconds
      setLoading(true);

      const getSurveyApiData = async () => {
        const data = {
          state: geoData.region || '',
          city: geoData.city || '',
          country: geoData.country || '',
          lat: geoData.lat ? geoData.lat : '',
          lon: geoData.lon ? geoData.lon : '',
          ipaddress: geoData.ip || '',
          name: '',
          email: '',
          mobile: '',
        };

        let urls;
        if (userUniqueId && cmp_fk_id) {
          urls = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SURVEY_USER_CAPTURE}/?sm_unique_code=${surveyCode}&cmp_fk_id=${cmp_fk_id}&user_unique_id=${userUniqueId}`;
        } else if (userUniqueId) {
          urls = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SURVEY_USER_CAPTURE}/?user_unique_id=${userUniqueId}&sm_unique_code=${surveyCode}`;
        }

        try {
          const res = await axios.post(urls, data);
          if (res.data) {
            setGridData(res.data.data);
            setMessage(res.data.message);
          } else {
            setGridData([]);
          }
        } catch (err) {
          console.error('Error fetching survey data:', err);
        } finally {
          setLoading(false); // Hide loading after data is fetched
        }
      };

      // Delay the API call by 2 seconds (show loading first)
      const timeoutId = setTimeout(() => {
        getSurveyApiData();
      }, 2000); // 2-second delay

      // Clear timeout if component unmounts or dependencies change
      return () => clearTimeout(timeoutId);
    }
  }, [geoData, userUniqueId]);

  //////////////////////////fontstyelApi////////////
  useEffect(() => {
    const getStyleData = async () => {
      // process.env.REACT_APP_BASE_URL + process.env.REACT_APP_STYLES

      getMethod(
        process.env.REACT_APP_BASE_URL +
          process.env.REACT_APP_STYLES +
          `${smId}`
      )
        .then((res) => {
          const datas = Object.keys(res.data.data).map((key) => ({
            key,
            value: res.data.data[key],
          }));
          const fntstyle = {
            values: datas.map((item) => ({
              id: item.value.fs_pk_id,
              title: item.value.fs_name,
              fontFile: item.value.fs_font_file,
            })),
          };
          setFontSetting(fntstyle.values);

          const style = document.createElement('style');
          document.head.appendChild(style);

          fntstyle.values.forEach((font) => {
            if (font.fontFile) {
              const fontFaceRule = `
          @font-face {
            font-family: '${font.title}';
            src: url('${process.env.REACT_APP_BASE_URL}${font.fontFile}') format('truetype');
            font-weight: normal;
            font-style: normal;
          }
        `;
              style.appendChild(document.createTextNode(fontFaceRule));
            }
          });

          const newHashMap = {};
          datas.forEach((item) => {
            newHashMap[item.value.fs_pk_id] = item.value.fs_name;
          });
          setHashmap(newHashMap);
        })
        .catch((err) => {
          console.log(err, 'API ERROR');
        });
    };
    if (smId) getStyleData();
  }, [smId]);

  useEffect(() => {
    // Ensure that the hashmap is populated before calling getFontSizeData
    if (Object.keys(hashmap).length > 0) {
      const getFontSizeData = async () => {
        await getMethod(
          process.env.REACT_APP_BASE_URL +
            process.env.REACT_APP_PREFIX_URL +
            `${smId}/style/` //dynamic servey_code
        )
          .then((res) => {
            if (res.data) {
              setTitleFontStyle([
                res.data.data.sm_title_font,
                hashmap[res.data.data.sm_title_font],
              ]);
              setTitleFontSize(res.data.data.sm_title_font_size);
              setDescriptionFontStyle([
                res.data.data.sm_desc_font,
                hashmap[res.data.data.sm_desc_font],
              ]);
              setDescriptionFontSize(res.data.data.sm_desc_font_size);
              setSectionTitleFontStyle([
                res.data.data.sm_sec_font,
                hashmap[res.data.data.sm_sec_font],
              ]);
              setSectionTitleFontSize(res.data.data.sm_sec_font_size);
              setQuestionsFontStyle([
                res.data.data.sm_que_font,
                hashmap[res.data.data.sm_que_font],
              ]);
              setQuestionsFontSize(res.data.data.sm_que_font_size);
              setOptionsFontStyle([
                res.data.data.sm_opt_font,
                hashmap[res.data.data.sm_opt_font],
              ]);
              setOptionsFontSize(res.data.data.sm_opt_font_size);
              setHeaderFontStyle([
                res.data.data.sm_ty_header_font,
                hashmap[res.data.data.sm_ty_header_font],
              ]);
              setHeaderFontSize(res.data.data.sm_ty_header_font_size);
              setMessageFontStyle([
                res.data.data.sm_ty_msg_font,
                hashmap[res.data.data.sm_ty_msg_font],
              ]);
              setMessageFontSize(res.data.data.sm_ty_msg_font_size);
            }
          })
          .catch((err) => {
            console.log('Error', err);
          });
      };
      if (smId) getFontSizeData();
    }
  }, [hashmap, smId]);

  const handlePrevQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    } else if (currentSection > 0) {
      setCurrentSection(currentSection - 1);
      setCurrentQuestion(totalQuestions - 1);
    }
  };

  const handlePrev = () => {
    //section prev
    setCurrentSection((prevSection) => prevSection - 1);
    if (currentStep > 0) {
      setCurrentStep(currentSection - 1);
    }
  };

  const handleNext = () => {
    //section next
    setCurrentSection((prevSection) => prevSection + 1);
  };

  const handleNextQuestions = (e) => {
    e.preventDefault();

    // Get the current question data
    const currentQuestionData = questionsData[currentQuestion];

    // Check if the current question is mandatory
    const isMandatory = currentQuestionData.que_is_mandatory;

    // Check if the current question is answered
    const isAnswered =
      (formData[`shortAnswer_${currentQuestionData.que_pk_id}`] &&
        formData[`shortAnswer_${currentQuestionData.que_pk_id}`].trim() !==
          '') ||
      (formData[`paraAns_${currentQuestionData.que_pk_id}`] &&
        formData[`paraAns_${currentQuestionData.que_pk_id}`].trim() !== '') ||
      formData[`singleChoiceAns_${currentQuestionData.que_pk_id}`] ||
      formData[`npsRadioAns_${currentQuestionData.que_pk_id}`] ||
      formData[`radioRatingAns_${currentQuestionData.que_pk_id}`] ||
      smileySelections[currentQuestionData.que_pk_id] ||
      starSelections[currentQuestionData.que_pk_id] ||
      emojiSelections[currentQuestionData.que_pk_id] ||
      (mulChResponses[currentQuestionData.que_pk_id] &&
        mulChResponses[currentQuestionData.que_pk_id].length > 0);

    // If the question is mandatory and not answered, set an error message and return
    if (isMandatory && !isAnswered) {
      setErrorMessages((prevErrorMessages) => ({
        ...prevErrorMessages,
        [currentQuestionData.que_pk_id]: 'This question field is mandatory',
      }));
      return;
    }

    // Clear error messages if the current question is answered
    setErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      [currentQuestionData.que_pk_id]: '',
    }));

    // Proceed to the next question or section
    if (
      currentSection < totalSections - 1 ||
      currentQuestion < totalQuestions - 1
    ) {
      if (currentQuestion < totalQuestions - 1) {
        setCurrentQuestion(currentQuestion + 1);
      } else if (currentSection < totalSections - 1) {
        setCurrentSection(currentSection + 1);
        setCurrentQuestion(0); // Move to the first question of the new section
      }
    } else {
      // setisSubmitted(true);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '94vh',
        }}
      >
        <CircularProgress size={72} thickness={3} />
      </Box>
    );
  }

  if (gridData.length === 0) {
    return <SubmittedSurvey message={message} />;
  }

  return (
    <>
      {(gridData.sm_format === 'all' ||
        gridData.sm_format === 'question_wise') && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {isSubmitted ? (
              <ThankYou
                ThanksImage={ThanksImage}
                gridData={gridData}
                headerFontStyle={headerFontStyle[1]}
                messageFontStyle={messageFontStyle[1]}
              />
            ) : (
              <Box
                sx={{
                  border: gridData && '1px solid #c6c6c6',
                  borderRadius: 2,
                  // padding: '40px',
                  padding: bodyBoxPadding,
                  minHeight: '310px',
                }}
              >
                {gridData.sm_logo_link && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: `${gridData.sm_logo_position}`,
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={
                        gridData.sm_logo_link &&
                        `${process.env.REACT_APP_BASE_URL}/` +
                          gridData.sm_logo_link
                      }
                      alt="logo"
                      style={{
                        // minWidth: '15vw',
                        maxWidth: '18vw',
                        height: 'auto',
                      }}
                    />
                  </Box>
                )}
                {gridData.sm_banner_link && (
                  <Box sx={{ width: '100%', height: 'auto', mt: 2.75 }}>
                    <img
                      src={
                        gridData.sm_banner_link &&
                        `${process.env.REACT_APP_BASE_URL}/` +
                          gridData.sm_banner_link
                      }
                      className={classes.banner}
                      alt="banner"
                    />
                  </Box>
                )}
                {!isSubmitted ? (
                  <Box>
                    {gridData.sm_title && (
                      <Box
                        sx={{
                          mt: 2.75,
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography
                          // variant="sh3"
                          style={{
                            color: '#000',
                            fontSize: `${titleFontSize}px`,
                            fontFamily: titleFontStyle[1],
                            wordWrap: 'break-word', // Add this line to enable word wrapping
                          }}
                        >
                          {gridData.sm_title}
                        </Typography>
                      </Box>
                    )}
                    {gridData.sm_description && (
                      <Box mt={2} mb={2}>
                        <Typography
                          variant="body1"
                          sx={{
                            color: '#5e5e5e',
                            fontSize: descriptionFontSize
                              ? `${descriptionFontSize}px`
                              : '1rem',
                            fontFamily: `${descriptionFontStyle[1]}`,
                            wordWrap: 'break-word',
                          }}
                        >
                          {gridData.sm_description}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                ) : null}
                <Box>
                  {gridData &&
                    gridData.sections &&
                    gridData.sections.map((section, sectionIndex) => (
                      <Box key={section.sec_pk_id}>
                        {section.questions.length === 0 &&
                        sectionIndex === currentSection ? (
                          <Typography align="center">
                            No questions available for this section.
                          </Typography>
                        ) : (
                          <Box>
                            {section.questions.map((ques, indexQues) => (
                              <Box key={indexQues}>
                                <Box>
                                  {gridData.sm_format === 'question_wise' && (
                                    <Box>
                                      {indexQues === currentQuestion &&
                                      sectionIndex === currentSection ? (
                                        <Box>
                                          <DisplayQuestions
                                            queId={ques.que_pk_id}
                                            question={ques}
                                            index={Number(indexQues) + 1}
                                            text={ques.que_text}
                                            mandatoryChecked={
                                              ques.que_is_mandatory
                                            }
                                            questionCount={ques.que_serial_no}
                                            value={ques.qtype_fk_id}
                                            paraPlaceholder={
                                              ques.que_place_holder
                                            }
                                            shortPlaceholder={
                                              ques.que_place_holder
                                            }
                                            options={ques.options}
                                            optionsFontStyle={optionsFontStyle}
                                            onNumberChange={(
                                              newNumbervalue
                                            ) => {
                                              setNumberValue((prevAnswers) => ({
                                                ...prevAnswers,
                                                [ques.que_pk_id]:
                                                  newNumbervalue,
                                              }));
                                            }}
                                            onEmailChange={(newEmailvalue) => {
                                              setEmailValue((prevAnswers) => ({
                                                ...prevAnswers,
                                                [ques.que_pk_id]: newEmailvalue,
                                              }));
                                            }}
                                            handleClickedRatingStar={
                                              handleClickedRatingStar
                                            }
                                            selectedId={selectedId}
                                            buttonClicked={buttonClicked}
                                            smileySelections={smileySelections}
                                            setSmileySelections={
                                              setSmileySelections
                                            }
                                            starSelections={starSelections} //star rating
                                            setStarSelections={
                                              setStarSelections
                                            }
                                            emojiSelectedId={emojiSelections} //emoji
                                            setEmojiSelectedId={
                                              setEmojiSelections
                                            }
                                            radioValue={radioValue}
                                            setRadioValue={setRadioValue}
                                            questionsFontStyle={
                                              questionsFontStyle[1]
                                            }
                                            errorMessage={
                                              errorMessages[ques.que_pk_id]
                                            } // Pass the error message
                                            setErrorMessages={setErrorMessages}
                                            formData={formData}
                                            handleChange={handleChange}
                                            handleCheckboxChange={
                                              handleCheckboxChange
                                            }
                                            mulChResponses={mulChResponses}
                                            setMulChResponses={
                                              setMulChResponses
                                            }
                                            questionFontSize={questionFontSize}
                                          />
                                        </Box>
                                      ) : null}
                                    </Box>
                                  )}
                                  {gridData.sm_format === 'all' && (
                                    <Box>
                                      <DisplayQuestions
                                        queId={ques.que_pk_id}
                                        question={ques}
                                        index={Number(indexQues) + 1}
                                        text={ques.que_text}
                                        mandatoryChecked={ques.que_is_mandatory}
                                        questionCount={ques.que_serial_no}
                                        value={ques.qtype_fk_id}
                                        paraPlaceholder={ques.que_place_holder}
                                        shortPlaceholder={ques.que_place_holder}
                                        options={ques.options}
                                        optionsFontStyle={optionsFontStyle}
                                        onNumberChange={(newNumbervalue) => {
                                          setNumberValue((prevAnswers) => ({
                                            ...prevAnswers,
                                            [ques.que_pk_id]: newNumbervalue,
                                          }));
                                        }}
                                        onEmailChange={(newEmailvalue) => {
                                          setEmailValue((prevAnswers) => ({
                                            ...prevAnswers,
                                            [ques.que_pk_id]: newEmailvalue,
                                          }));
                                        }}
                                        handleClickedRatingStar={
                                          handleClickedRatingStar
                                        }
                                        selectedId={selectedId}
                                        buttonClicked={buttonClicked}
                                        smileySelections={smileySelections}
                                        setSmileySelections={
                                          setSmileySelections
                                        }
                                        starSelections={starSelections} //star rating
                                        setStarSelections={setStarSelections}
                                        emojiSelectedId={emojiSelections} //emoji
                                        setEmojiSelectedId={setEmojiSelections}
                                        radioValue={radioValue}
                                        setRadioValue={setRadioValue}
                                        questionsFontStyle={
                                          questionsFontStyle[1]
                                        }
                                        errorMessage={
                                          errorMessages[ques.que_pk_id]
                                        } // Pass the error message
                                        setErrorMessages={setErrorMessages}
                                        formData={formData}
                                        handleChange={handleChange}
                                        handleCheckboxChange={
                                          handleCheckboxChange
                                        }
                                        mulChResponses={mulChResponses}
                                        setMulChResponses={setMulChResponses}
                                        questionFontSize={questionFontSize}
                                      />
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        )}
                      </Box>
                    ))}
                  {/* questions format All wise submit button  */}
                  {gridData.sm_format === 'all' && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Common.Button
                        variant="contained"
                        size="small"
                        type="submit"
                        onClick={handlesubmit}
                      >
                        Submit
                      </Common.Button>
                    </Box>
                  )}

                  {gridData.sm_format === 'section_wise' && (
                    // Content for 'sectionwise' condition
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      {currentSection === 0 ? (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Common.Button
                            variant="text"
                            size="small"
                            disabled
                            onClick={handlePrev}
                          >
                            <Icon
                              className="fh-icon fh-icon-left"
                              sx={{ fontSize: '0.875rem' }}
                            />
                            Prev
                          </Common.Button>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <Common.Button
                            variant="text"
                            size="small"
                            onClick={handlePrev}
                          >
                            <Icon
                              className="fh-icon fh-icon-left"
                              sx={{ fontSize: '0.875rem' }}
                            />
                            Prev
                          </Common.Button>
                        </Box>
                      )}
                      {currentSection < totalSections - 1 ? (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Common.Button
                            size="small"
                            variant="text"
                            onClick={handleNext}
                          >
                            Next
                            <Icon
                              sx={{ ml: 1, fontSize: '0.875rem' }}
                              className="fh-icon fh-icon-right"
                            />
                          </Common.Button>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Common.Button
                            size="small"
                            type="submit"
                            onClick={handlesubmit}
                          >
                            Submit
                          </Common.Button>
                        </Box>
                      )}
                    </Box>
                  )}
                  {gridData.sm_format === 'question_wise' && (
                    // Content for 'question_wise' condition
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {currentQuestion !== 0 || currentSection !== 0 ? (
                          <Common.Button
                            variant="text"
                            onClick={handlePrevQuestion}
                            sx={{ mr: 1 }}
                            size="small"
                          >
                            <Icon
                              className="fh-icon fh-icon-left"
                              sx={{ fontSize: '0.875rem' }}
                            />
                            Prev
                          </Common.Button>
                        ) : null}
                        {currentSection !== totalSections - 1 ||
                        currentQuestion !== totalQuestions - 1 ? (
                          <Common.Button
                            variant="text"
                            onClick={handleNextQuestions}
                            size="small"
                          >
                            Next
                            <Icon
                              sx={{ ml: 1, fontSize: '0.875rem' }}
                              className="fh-icon fh-icon-right"
                            />
                          </Common.Button>
                        ) : (
                          <Common.Button
                            type="submit"
                            size="small"
                            onClick={handleSinglesubmit} //Questionwise submit
                          >
                            Submit
                          </Common.Button>
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
                {(gridData.sm_format === 'all' ||
                  gridData.sm_format === 'question_wise') && (
                  <Grid item xs={12} mb={2} mt={2}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        // p: 4,
                      }}
                    >
                      <EndUserFooter />
                    </Box>
                  </Grid>
                )}
              </Box>
            )}
          </Grid>
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar
          />
        </Grid>
      )}
    </>
  );
};
export default KnownUserResponsePage;
