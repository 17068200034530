import React from "react";
import { Toolbar, AppBar as MuiAppBar } from "@mui/material";
import useStyles from "./styles";

const AppBar = ({ headertext, drawerwidth, children, ...others }) => {
  const classes = useStyles();
  return (
    <MuiAppBar position="fixed" {...others}>
      <Toolbar className={classes.toolbar}>{children}</Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
