import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BarChart, PieChart } from '../../../../../../components/common/charts';
// import { useTheme } from "@mui/styles";
import CommonChartContainer from '../CommonChartContainer/CommonChartContainer';
const SingleTypeChart = ({ item }) => {
  const [dynamicColors, setDynamicColors] = useState([]);

  function getColors1(length) {
    const pallet = [
      '#D86445',
      '#D89E45',
      '#D8CF45',
      '#45D853',
      '#45D8D7',
      '#458FD8',
      '#8B45D8',
      '#D8459C',
      '#D84945',
    ];
    const colors = [];

    for (let i = 0; i < length; i++) {
      colors.push(pallet[i % pallet.length]);
    }

    return colors;
  }

  function getColors2(length) {
    const colors = [];

    for (let i = 0; i < length; i++) {
      // Generate a color using HSL
      const hue = (i * 360) / length; // Spread hues evenly across the circle
      const saturation = 60 + Math.random() * 10; // Randomize saturation
      const lightness = 50 + Math.random() * 10; // Randomize lightness
      colors.push(`hsl(${hue}, ${saturation}%, ${lightness}%)`);
    }

    return colors;
  }

  useEffect(() => {
    if (item.labels.length > 9) {
      setDynamicColors(getColors2(item.labels.length));
    } else if (item.labels.length <= 9) {
      setDynamicColors(getColors1(item.labels.length));
    }
  }, [item.labels.length]);

  // console.log(item.labels.length, getColors(item.label_counts.length));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <CommonChartContainer>
          <Typography variant="sh3">Submissions Breakdown</Typography>
          <BarChart
            legendDisplay={false}
            barWidth={'100%'}
            barHeight={'100%'}
            barLabels={[
              ['Not Submitted', ' by known'],
              ['Submitted', ' by known'],
              ['Submitted', ' by Un-known'],
              ['Not Submitted', ' by Un-known'],
            ]}
            datasets={[
              {
                data: [
                  item.Not_Submitted_by_known,
                  item.Submitted_by_known,
                  item.Submitted_by_unknown,
                  item.Not_Submitted_by_unknown,
                ],
                backgroundColor: ['#8B45D8', '#D86445', '#458FD8', '#D8459C'],
              },
            ]}
          />
        </CommonChartContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <CommonChartContainer>
          <Typography variant="sh3">Option Selection</Typography>
          <PieChart
            labels={item.labels}
            // width={'500px'}
            height={'260px'}
            datasets={[
              {
                label: '',
                data: item.label_counts,
                backgroundColor: dynamicColors,
                // backgroundColor: ["#8B45D8", "#D86445", "#458FD8"],
                // borderColor: ["#8B45D8", "#D86445", "#458FD8"],
                borderWidth: 1,
              },
            ]}
          />
        </CommonChartContainer>
      </Grid>
    </Grid>
  );
};

export default SingleTypeChart;
