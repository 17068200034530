import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Box } from '@mui/material';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function PieChart(props) {
  const { width, height, datasets, labels, fontColor } = props;
  const data = {
    labels,
    datasets,
  };

  const options = {
    maintainAspectRatio: false,
    aspectRatio: 1,
    plugins: {
      legend: {
        display: true,
        position: 'right',
        align: 'center',
        // rtl:'true',
        // textDirection: 'ltr',
        labels: {
          textAlign: 'left',
          color: fontColor,
          usePointStyle: true,
          pointStyle: 'rect',
          align: 'end',
          font: {
            family: 'poppins',
            size: 12,
          },
        },
      },

      title: {
        display: false,
      },
    },
  };
  return (
    <Box
    sx={{
      width: width,
      height: height,
    }}
    >
      <Pie
        data={data}
        options={options}
        // plugins={[legendExtraSpace]}
      />
    </Box>
  );
}
