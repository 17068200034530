import { makeStyles } from '@mui/styles';
export default makeStyles((theme) => ({
  root: {
    overflowX: 'auto',
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  children: {
    // width: '80%',
    // width: [{ xs: '100%', md: '30%' }],
    margin: '0px auto',
    // margin: '0px 10px 10px 10px',
    // width: 'calc(100% - 4px) !important',
    // overflow: "hidden",
  },
}));
