import { Box, Grid, Icon, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { BarChart, DoughnutChart } from '../../../../components/common/charts';
// import StackedBarChart from "../../../../components/common/charts/StackedBarChart";

const AnalyticsCharts = ({
  participantData,
  stateWiseparticipantData,
  // ageWiseparticipantData,
  submissionReportData,
  channelReportData,
}) => {
  // channel
  const [channelType, setChannelType] = useState([]);
  const [chNotAttempted, setChNotAttempted] = useState([]);
  const [chNotSubmitted, setChNotSubmitted] = useState([]);
  const [chSubmitted, setChSubmitted] = useState([]);
  // state
  const [stateName, setStateName] = useState([]);
  // const [stateNotAttempted, setStateNotAttempted] = useState([]);
  const [stateNotSubmitted, setStateNotSubmitted] = useState([]);
  const [stateSubmitted, setStateSubmitted] = useState([]);
  // age
  // const [ageGroup, setAgeGroup] = useState([]);
  // const [agecmplCnt, setAgeCmplCnt] = useState([]);
  // const [agenotCmplCnt, setAgeNotCmplCnt] = useState([]);

  //================================== Participant=========================================
  const submitted = participantData?.submitted || 0;
  const notSubmitted = participantData?.not_submitted || 0;
  const notAttempted = participantData?.not_attempted || 0;
  const totalUser = participantData?.total_user || 0;

  //
  const knownUser = submissionReportData?.known_user || 0;
  const unKnownUser = submissionReportData?.unknown_user || 0;
  const totalSubmissionUser = submissionReportData?.total_user || 0;

  const knownUserPercentage = ((knownUser / totalSubmissionUser) * 100).toFixed(
    0
  );
  const unKnownUserPercentage = (
    (unKnownUser / totalSubmissionUser) *
    100
  ).toFixed(0);
  // Calculating percentages for the chart labels
  const notAttemptedPercentage = ((notAttempted / totalUser) * 100).toFixed(0);
  const notSubmittedPercentage = ((notSubmitted / totalUser) * 100).toFixed(0);
  const submitPercentage = ((submitted / totalUser) * 100).toFixed(0);

  // =================================StateWise==================================
  useEffect(() => {
    if (stateWiseparticipantData.length !== 0) {
      const labels = stateWiseparticipantData.map((item) => item.state_name);
      setStateName(labels);
      const submitted = stateWiseparticipantData.map(
        (item) => item.completed_count
      );
      setStateSubmitted(submitted);
      // const notAttempted = stateWiseparticipantData.map(
      //   (item) => item.not_completed_count
      // );
      // setStateNotAttempted(notAttempted);
      const notSubmitted = stateWiseparticipantData.map(
        (item) => item.not_completed_count
      );
      setStateNotSubmitted(notSubmitted);
    }
  }, [stateWiseparticipantData]);

  //============================ Age Wise=======================================
  // useEffect(() => {
  //   // console.log("ageWiseSep>>>>>>>>>>", ageWiseparticipantData);
  //   if (ageWiseparticipantData.length !== 0) {
  //     const ageGroup = ageWiseparticipantData.map((item) => item.age_group);
  //     setAgeGroup(ageGroup);
  //     const ageCompletedCounts = ageWiseparticipantData.map(
  //       (item) => item.completed_count
  //     );
  //     setAgeCmplCnt(ageCompletedCounts);
  //     const ageNotCompletedCounts = ageWiseparticipantData.map(
  //       (item) => item.not_completed_count
  //     );
  //     setAgeNotCmplCnt(ageNotCompletedCounts);
  //   } else {
  //   }
  // }, [ageWiseparticipantData]);

  // ===========================Channel wise====================================
  useEffect(() => {
    if (channelReportData.length !== 0) {
      const labels = channelReportData.map((item) => item.channel_type);
      setChannelType(labels);
      const notAttempted = channelReportData.map((item) => item.not_attempted);
      setChNotAttempted(notAttempted);
      const notSubmitted = channelReportData.map((item) => item.not_submitted);
      setChNotSubmitted(notSubmitted);
      const submitted = channelReportData.map((item) => item.submitted);
      setChSubmitted(submitted);
    }
  }, [channelReportData]);

  // console.log("channelReportData>>>>", channelReportData);

  // Define the datasets for the state
  const stateDatasets = [
    {
      label: 'Not Submitted',
      data: stateNotSubmitted,
      backgroundColor: '#D89E45',
    },
    {
      label: 'Submitted',
      data: stateSubmitted,
      backgroundColor: '#458FD8',
    },
  ];

  // Define the datasets for the channel
  const channelDatasets = [
    {
      label: 'Not Attempted',
      data: chNotAttempted,
      backgroundColor: '#D86445',
    },
    {
      label: 'Submitted',
      data: chSubmitted,
      backgroundColor: '#458FD8',
    },
    {
      label: 'Not Submitted',
      data: chNotSubmitted,
      backgroundColor: '#D89E45',
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              height: 'auto',
              border: '1px solid #C6C6C6',
              borderRadius: 1,
              p: 2,
            }}
          >
            <Typography variant="sh3">Survey Submission Report</Typography>
            <DoughnutChart
              labels={[
                `Known users- ${knownUser} (${knownUserPercentage}%)`,
                `Un-Known users - ${unKnownUser} (${unKnownUserPercentage}%)`,
              ]}
              datasets={[
                {
                  label: '',
                  data: [knownUser, unKnownUser],
                  backgroundColor: ['#D86445', '#458FD8'],
                  borderColor: ['#D86445', '#458FD8'],
                  borderWidth: 1,
                  hoverOffset: 4,
                },
              ]}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 0.5 }}>
            <Icon className="fh-icon fh-icon-info" sx={{ fontSize: '1rem' }} />
            <Typography variant="body4">
              &nbsp;&nbsp;&nbsp;This report shows the break up of respondents
              who have submitted the survey.
            </Typography>
          </Box>
          <Typography variant="body4" component={'p'} sx={{ display: 'block' }}>
            Note: The channel filter does not apply to the survey submission
            report
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              height: 'auto',
              border: '1px solid #C6C6C6',
              borderRadius: 1,
              p: 2,
            }}
          >
            <Typography variant="sh3">Known Participants Reports</Typography>
            <DoughnutChart
              labels={[
                `Not Attempted - ${notAttempted} (${notAttemptedPercentage}%)`,
                `Not Submitted - ${notSubmitted} (${notSubmittedPercentage}%)`,
                `Submitted - ${submitted} (${submitPercentage}%)`,
              ]}
              // height={"290px"}
              datasets={[
                {
                  label: '',
                  data: [notAttempted, notSubmitted, submitted],
                  // data:[2,1],
                  backgroundColor: ['#D86445', '#D89E45', '#458FD8'],
                  borderColor: ['#D86445', '#D89E45', '#458FD8'],
                  borderWidth: 1,
                  hoverOffset: 4,
                },
              ]}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 0.5 }}>
            <Icon className="fh-icon fh-icon-info" sx={{ fontSize: '1rem' }} />
            <Typography variant="body4">
              &nbsp;&nbsp;&nbsp;This report shows the status of known
              individuals survey was sent.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              height: 'auto',
              border: '1px solid #C6C6C6',
              borderRadius: 1,
              p: 2,
            }}
          >
            <Typography variant="sh3">Channel wise Report</Typography>
            <BarChart
              barWidth={'100%'}
              barHeight={'100%'}
              barLabels={channelType}
              datasets={channelDatasets}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 0.5 }}>
            <Icon className="fh-icon fh-icon-info" sx={{ fontSize: '1rem' }} />
            <Typography variant="body4">
              &nbsp;&nbsp;&nbsp;This report shows the known respondents by
              channel they received the survey link.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              height: 'auto',
              border: '1px solid #C6C6C6',
              borderRadius: 1,
              p: 2,
            }}
          >
            <Typography variant="sh3">Statewise Report</Typography>
            <BarChart
              barWidth={'100%'}
              barHeight={'100%'}
              barLabels={stateName}
              datasets={stateDatasets}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 0.5 }}>
            <Icon className="fh-icon fh-icon-info" sx={{ fontSize: '1rem' }} />
            <Typography variant="body4">
              &nbsp;&nbsp;&nbsp;This report shows the state of the all
              respondents.
            </Typography>
          </Box>
        </Grid>
        {/* <Grid item xs={6}>
            <Box
              sx={{
                // width: 558,
                height: 352,
                border: "1px solid #C6C6C6",
                borderRadius: 1,
                p: 2,
              }}
            >
              <Typography variant="sh3">Age wise Reports</Typography>
              <StackedBarChart
                barWidth={"100%"}
                barHeight={"100%"}
                // barLabels={[
                //   "0 to 20",
                //   "20 to 40",
                //   "40 to 60",
                //   "Above 60",
                // ].reverse()}
                barLabels={ageGroup.reverse()}
                datasets={[
                  {
                    label: "Non Respondents",
                    // data: [18, 11, 10, 13],
                    data: agenotCmplCnt,
                    backgroundColor: "#D86445",

                    //   backgroundColor: theme.palette.graphBlue.main,
                  },
                  {
                    label: "Respondent",
                    // data: [12, 19, 3, 5],
                    data: agecmplCnt,
                    backgroundColor: "#458FD8",
                    //   backgroundColor: theme.palette.graphPurple.main,
                  },
                  // {
                  //   label: "Draft",
                  //   data: [15, 14, 8, 10],
                  //   // backgroundColor: theme.palette.graphGreen.main,
                  // },
                ]}
              />
            </Box>
          </Grid> */}
      </Grid>
      {/* ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="sh2" sx={{ color: "#333", mt: 6 }}>
            No Data Available for this survey
          </Typography>
        </Box>
      )} */}
    </>
  );
};

export default AnalyticsCharts;
