import React, { useEffect, useState } from 'react';
import InputBox from '../../../components/common/InputBox/InputBox';
import TextArea from '../../../components/common/TextArea/TextArea';
import { Box, Grid, Typography } from '@mui/material';
import { Common } from '../../../components/common/Common';
import { postMethodFile } from '../../../api/postMethodFile';
import Fileupload from '../../../components/common/DocumentUploader/FileUpload';
import { getMethod } from '../../../api/getMethod';
import EditBasicInfo from './EditBasicInfo/EditBasicInfo';
import { toast, ToastContainer } from 'react-toastify';
import RadioGroup from '../../../components/common/RadioGroup/RadioGroup';
import { Form, useForm } from '../../../components/common/Form/useForm';
import TextStyles from './TextStyles/TextStyles';

const items = [
  { id: 'all', title: 'All Questions in one page' },
  // { id: "section_wise", title: "One Section per page" },
  { id: 'question_wise', title: 'One Question at a time' },
];

const piItems = [
  { id: 0, title: 'No' },
  { id: 1, title: 'Yes' },
];
const logoPositions = [
  { id: 'left', title: 'Left' },
  { id: 'center', title: 'Center' },
  { id: 'right', title: 'Right' },
];
const maxChars = 250;
export default function CreateBasicInfo({
  onNextClick,
  activeStep,
  setUpdatedFlag,
  token,
  isAddQuestionVisited,
}) {
  const initialFieldValues = {
    sm_title: '',
    description: '',
    surveyFormat: '',
    personalInfo: '',
    logoPosition: '',
    surveyHeader: '',
    surveyMessage: '',
    titleFontStyle: '',
    descriptionFontStyle: '',
    sectionTitleFontStyle: '',
    questionFontStyle: '',
    optionResponseFontStyle: '',
    headerFontStyle: '',
    messageFontStyle: '',
  };
  const [showContent, setShowContent] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const [charCounts, setCharCounts] = useState(0);
  const [buttonValue, setbuttonValue] = useState(true);
  const [selectedFile, setSelectedFile] = useState('');
  const [selectedImage, setselectedImage] = useState('');
  const [style, setStyle] = useState([]);
  const [surveyInfoId, setSurveyInfoId] = useState(null);
  const [surveyCode, setSurveyCode] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const currentURL = window.location.href;
  const url = new URL(currentURL);
  const surveyId = url.searchParams.get('id');
  const duplicateId = url.searchParams.get('duplicate');
  const survey_code = url.searchParams.get('survey_code');

  const addQuestion = (surveyInfoId, activeStep, surveyCode, expiryDate) => {
    onNextClick(surveyInfoId, surveyCode, expiryDate);
    activeStep = activeStep + 1;
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ('sm_title' in fieldValues) {
      if (!fieldValues.sm_title || !fieldValues.sm_title.trim()) {
        temp.sm_title = 'This field is required.';
      } else if (fieldValues.sm_title.length > 100) {
        setIsSaveDisabled(true);
        temp.sm_title = 'Title cannot exceed 100 characters.';
      } else {
        temp.sm_title = '';
        setIsSaveDisabled(false);
      }
    }
    if ('description' in fieldValues) {
      if (!fieldValues.description || !fieldValues.description.trim()) {
        temp.description = 'This field is required.';
      } else if (fieldValues.description.length > 200) {
        setIsSaveDisabled(true);
        temp.description = 'Description cannot exceed 200 characters.';
      } else {
        temp.description = '';
        setIsSaveDisabled(false);
      }
    }
    if ('surveyFormat' in fieldValues) {
      temp.surveyFormat = fieldValues.surveyFormat
        ? ''
        : 'This field is required.';
    }
    if ('personalInfo' in fieldValues) {
      temp.personalInfo = fieldValues.personalInfo
        ? ''
        : 'This field is required.';
    }
    if ('logoPosition' in fieldValues) {
      temp.logoPosition = fieldValues.logoPosition
        ? ''
        : 'Logo position is required when a logo is selected.';
    }
    if ('surveyMessage' in fieldValues) {
      if (fieldValues.surveyMessage.length > 200) {
        temp.surveyMessage = 'Message cannot exceed 200 characters.';
      } else {
        temp.surveyMessage = '';
      }
    }
    setErrors({ ...temp });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === '');
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFieldValues, true, validate);

  const handleApply = (e) => {
    e.preventDefault();
    if (validate()) {
      setValues({ ...values });
    }
    // Check if a logo is selected
    if (selectedFile) {
      // If logo is selected, check if logo position is also selected
      if (!values.logoPosition) {
        // If logo position is not selected, set error and return
        setErrors((prevErrors) => ({
          ...prevErrors,
          logoPosition: 'Logo position is required when a logo is selected.',
        }));
        return;
      }
    }

    const smTitlestyle =
      isNaN(parseInt(values.titleFontStyle)) || values.titleFontStyle === ''
        ? 1
        : parseInt(values.titleFontStyle);
    const smdescStyle =
      isNaN(parseInt(values.descriptionFontStyle)) ||
      values.descriptionFontStyle === ''
        ? 1
        : parseInt(values.descriptionFontStyle);
    const smsectionstyle =
      isNaN(parseInt(values.sectionTitleFontStyle)) ||
      values.sectionTitleFontStyle === ''
        ? 1
        : parseInt(values.sectionTitleFontStyle);
    const smquestionStyle =
      isNaN(parseInt(values.questionFontStyle)) ||
      values.questionFontStyle === ''
        ? 1
        : parseInt(values.questionFontStyle);
    const smoptionStyle =
      isNaN(parseInt(values.optionResponseFontStyle)) ||
      values.optionResponseFontStyle === ''
        ? 1
        : parseInt(values.optionResponseFontStyle);
    const smtyMsgfnt =
      isNaN(parseInt(values.messageFontStyle)) || values.messageFontStyle === ''
        ? 1
        : parseInt(values.messageFontStyle);
    const smheaderStyle =
      isNaN(parseInt(values.headerFontStyle)) || values.headerFontStyle === ''
        ? 1
        : parseInt(values.headerFontStyle);
    const formData = new FormData();
    formData.append('sm_title_font', parseInt(smTitlestyle));
    formData.append('sm_title_font_size', 20);
    formData.append('sm_desc_font', parseInt(smdescStyle));
    formData.append('sm_desc_font_size', 16);
    formData.append('sm_sec_font', parseInt(smsectionstyle));
    formData.append('sm_sec_font_size', 20);
    formData.append('sm_que_font', parseInt(smquestionStyle));
    formData.append('sm_que_font_size', 16);
    formData.append('sm_opt_font', parseInt(smoptionStyle));
    formData.append('sm_opt_font_size', 16);
    formData.append('sm_ty_msg_font', parseInt(smtyMsgfnt));
    formData.append('sm_ty_msg_font_size', 16);
    formData.append('sm_ty_header_font', parseInt(smheaderStyle));
    formData.append('sm_ty_header_font_size', 20);
    formData.append('sm_title', values.sm_title);
    formData.append('sm_description', values.description);
    formData.append('sm_format', values.surveyFormat);
    formData.append('sm_logo_link', selectedFile);
    formData.append('sm_logo_position', values.logoPosition);
    formData.append('sm_banner_link', selectedImage);
    formData.append('sm_ty_header', values.surveyHeader);
    formData.append('sm_ty_message', values.surveyMessage);
    formData.append('sm_is_pii', values.personalInfo);

    if (
      values.sm_title &&
      values.description &&
      values.surveyFormat &&
      values.personalInfo
    ) {
      postMethodFile(
        process.env.REACT_APP_BASE_URL + process.env.REACT_APP_SURVEY_CREATE,
        token,
        formData
      )
        .then((res) => {
          setSurveyInfoId(res.data.data.sm_pk_id);
          setSurveyCode(res.data.data.sm_unique_code);
          setExpiryDate(res.data.data.sm_expiry_date);
          setbuttonValue(false);
          setIsSaveDisabled(true);
          toast.success('Survey Created Successfully', {
            style: {
              fontSize: '0.875rem',
              textTransform: 'capitalize',
            },
          });
        })
        .catch((err) => {
          // console.log('Error');
          toast.error('Survey with this title already exists.', {
            style: {
              fontSize: '0.875rem',
              textTransform: 'capitalize',
            },
          });
          // if (err.response && err.response.data && err.response.data.errors) {
          //   const errorMessages = Object.values(
          //     err.response.data.errors
          //   ).flatMap((errors) => errors);
          //   const errorMessage = errorMessages.join(' ');
          //   toast.error(errorMessage, {
          //     style: {
          //       fontSize: '0.875rem',
          //       textTransform: 'capitalize',
          //     },
          //   });
          // }
        });
    }
  };

  const getStyleData = async () => {
    getMethod(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_STYLES,
      token
    )
      .then((res) => {
        const datas = Object.keys(res.data.data).map((key) => ({
          key,
          value: res.data.data[key],
        }));

        const fntstyle = {
          values: datas.map((item) => ({
            id: item.value.fs_pk_id,
            title: item.value.fs_name,
          })),
        };

        setStyle(fntstyle.values);
      })
      .catch((err) => {
        console.log('API ERROR');
      });
  };

  useEffect(() => {
    getStyleData();
  }, []);

  const handleCheckboxChange = (event) => {
    setShowContent(event.target.checked);
  };

  if (surveyId) {
    return (
      <div>
        <EditBasicInfo
          surveyId={surveyId}
          activeStep={activeStep}
          survey_code={survey_code}
          onNextClick={onNextClick}
          setUpdatedFlag={setUpdatedFlag}
          isAddQuestionVisited={isAddQuestionVisited}
        />
      </div>
    );
  } else if (duplicateId) {
    return (
      <div>
        <EditBasicInfo
          surveyId={duplicateId}
          activeStep={activeStep}
          survey_code={survey_code}
          onNextClick={onNextClick}
          duplicateId={duplicateId}
          setUpdatedFlag={setUpdatedFlag}
        />
      </div>
    );
  } else {
    return (
      <Form onSubmit={handleApply}>
        <Grid container>
          <Grid item xs={12} mt={2}>
            <TextStyles
              options={style}
              values={values}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12} mt={2}>
            <Typography variant="sh3">Survey Title*</Typography>
            <InputBox
              name="sm_title"
              placeholder="Enter Survey Title"
              value={values.sm_title}
              onChange={handleInputChange}
              helperText={errors.sm_title}
              error={errors.sm_title}
            />
          </Grid>

          <Grid item xs={12} mt={2}>
            <Typography variant="sh3">Description*</Typography>
            <TextArea
              name="description"
              placeholder="Write Description Here..."
              value={values.description}
              onChange={handleInputChange}
              rows={3}
              maxChars={250}
              cols={35}
              charCount={charCount}
              setCharCount={setCharCount}
              helperMsg={errors.description}
              error={errors.description}
            />
          </Grid>
          <Grid item xs={12} mt={2}>
            <Typography variant="sh3">Select Survey format*</Typography>
            <RadioGroup
              name="surveyFormat"
              onChange={handleInputChange}
              error={errors.surveyFormat}
              helperText={errors.surveyFormat}
              value={values.surveyFormat}
              items={items}
            />
          </Grid>
          <Grid item xs={12} mt={2}>
            <Typography variant="sh3">
              Do you want personal information (PI) to be captured and reported
              for the survey respondent?*
            </Typography>
            <RadioGroup
              name="personalInfo"
              onChange={handleInputChange}
              error={errors.personalInfo}
              helperText={errors.personalInfo}
              value={values.personalInfo}
              items={piItems}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="sh3">Upload Logo</Typography>
            <Fileupload
              minFileSize={0}
              maxFileSize={200 * 1024} //200KB
              fileTitle="logo"
              imageSubtitle="Upload logo here"
              imageSubcontent="Only PNG or JPG formats in 240*160px"
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              document_type="image"
              width={'10rem'}
              file_type={{
                'image/png': ['.png'],
                'image/jpg': ['.jpg'],
                'image/jpeg': ['.jpeg'],
              }}
            />
          </Grid>
          {selectedFile && (
            <Grid item xs={12} mt={2}>
              <Typography variant="sh3">Select Logo Position *</Typography>
              <RadioGroup
                name="logoPosition"
                onChange={handleInputChange}
                value={values.logoPosition}
                error={errors.logoPosition}
                helperText={errors.logoPosition}
                items={logoPositions}
                row
              />
            </Grid>
          )}
          <Grid item xs={12} mt={selectedFile ? 0 : 2}>
            <Typography variant="sh3">Upload Banner</Typography>
            <Fileupload
              minFileSize={0}
              maxFileSize={5 * 1024 * 1024} // 5MB
              fileTitle="banner"
              document_type="image"
              imageSubtitle={'Upload'}
              imageSubcontent={
                'Only PNG or JPG, recommended size is 728*90 PX, Banner will be positioned on the top of the page'
              }
              selectedFile={selectedImage}
              setSelectedFile={setselectedImage}
              file_type={{
                'image/png': ['.png'],
                'image/jpg': ['.jpg'],
                'image/jpeg': ['.jpeg'],
              }}
            />
            {/* <FileUploader
              selectedFiles={selectedImage}
              setSelectedFiles={setselectedImage}
            /> */}
          </Grid>

          <Grid item xs={12} mt={2}>
            <Common.Checkbox
              label="Show Thank you page"
              checked={showContent}
              onChange={handleCheckboxChange}
            />
          </Grid>
          {showContent && (
            <React.Fragment>
              <Grid item xs={12} mt={2}>
                <Grid item xs={12}>
                  <Typography variant="sh3">Header</Typography>
                  <InputBox
                    name="surveyHeader"
                    value={values.surveyHeader}
                    onChange={handleInputChange}
                    placeholder="Thank You"
                    size="small"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} mt={2}>
                <Typography variant="sh3">Message</Typography>
                <TextArea
                  name="surveyMessage"
                  onChange={handleInputChange}
                  value={values.surveyMessage}
                  helperMsg={errors.surveyMessage}
                  error={errors.surveyMessage}
                  placeholder="Write your message Here..."
                  rows={3}
                  maxChars={250}
                  cols={35}
                  charCount={charCounts}
                  setCharCount={setCharCounts}
                />
              </Grid>
            </React.Fragment>
          )}

          <Grid container>
            <Grid item xs={12} mt={4} mb={8}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <Common.Button
                  type="submit"
                  size="small"
                  disabled={isSaveDisabled}
                >
                  Save
                </Common.Button>
                {surveyInfoId && (
                  <Common.Button
                    style={{ marginLeft: 15 }}
                    size="small"
                    type="button"
                    disabled={buttonValue}
                    onClick={() =>
                      addQuestion(
                        surveyInfoId,
                        activeStep,
                        surveyCode,
                        expiryDate
                      )
                    }
                  >
                    Next
                  </Common.Button>
                )}
              </Box>
            </Grid>
          </Grid>
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar
          />
        </Grid>
      </Form>
    );
  }
}
