import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import useStyles from './styles';

export default function ReusableTooltip(props) {
  const { title, placement,children } = props;
  const classes= useStyles();
  return (
    <Tooltip
      title={title}
      arrow={true}
      placement={placement}
      classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }}
    >
      {children}
    </Tooltip>
  );
}