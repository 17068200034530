import React, { useState, useEffect } from 'react';
import RadioGroup from '../../../components/common/RadioGroup/RadioGroup';

const DisplayOptionFormat = ({
  options,
  onChange,
  id,
  value,
  optionsFontStyle,
  error,
  helperText,
  name,
}) => {
  const [optionList, setOptionList] = useState([]);
  useEffect(() => {
    const newList = options.map((item, index) => ({
      id: item.opt_pk_id,
      title: item.opt_text,
    }));
    setOptionList(newList);
  }, [options]);

  return (
    <RadioGroup
      name={name}
      onChange={onChange}
      id={id}
      value={value}
      items={optionList}
      optionsFontStyle={optionsFontStyle}
      error={error}
      helperText={helperText}
    />
  );
};

export default DisplayOptionFormat;
