import { makeStyles } from "@mui/styles";
export default makeStyles(theme=>({
    root: {
        width: '100%',
        // boxShadow:'none !important'
    },
    heading: {
        // fontSize: ` !important`,
        fontWeight: '500 !important',
        color:theme.palette.mode==='light'?`${theme.palette.blue.dark} !important`:``,
    },
}));