import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import {Box} from '@mui/system';
import { Line } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);






export default function AreaChart(props) {
    const {areaLabels, areaWidth, areaHeight, datasets, fontColor, gridColor} = props;
    const labels = areaLabels;

    const data = {
        labels,
        datasets,
      }
    
      const options = {
        responsive:true,
        maintainAspectRatio: true,
        aspectRatio: 2,
      
        elements:{
          line:{
           borderWidth: 2
          }
        },
      
        plugins: {
          legend: {
            position: 'right',
            labels:{
            color: fontColor,
            usePointStyle: true,
            pointStyle: 'rect',
            align: 'end',
            font:{
              family:'poppins',
              size:12,
            }
            },
          },
          title: {
            display: false,
          },
        },
      
        scales: {
          
          x:{
            grid: {
              display: false,
          },
          ticks:{
            beginAtZero: true,
            color: fontColor,
            font:{
              family:'poppins',
              size:12,
              weight: 400,
            }
          }
        },
          y:{
            border:{
             display: false,
            },
            grid:{
              color: gridColor,
              lineWidth: 0.5,
            },
           ticks:{
            beginAtZero: true,
            color: fontColor,
            font:{
              family:'poppins',
              size:12,
              weight: 400,
            }
          }
          }
        }
      };
      
  return(
  <Box sx={{width: areaWidth, height:areaHeight}}>
  <Line options={options} data={data} />
  </Box>
  )
}
