import { makeStyles } from "@mui/styles";
export default makeStyles(theme=>({
    dialog: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(0),
    },
    dialogTitle: {
        paddingRight: theme.spacing(2),
    },
    dialogContent: {

    },
    dialogAction: {

    },
    primaryButton: {
        color: `${theme.palette.white.main} !important`,
    }
}));