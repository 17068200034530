import { Grid, Typography } from '@mui/material';
import React from 'react';
import QuestionAnalytics from './QuestionAnalytics/QuestionAnalytics';
const QuestionSpecificReports = ({ questionAnalyticsData }) => {
  return (
    <Grid container>
      <Grid item xs={12} mt={2}>
        <Typography variant="sh2">Questions Responses Report</Typography>
      </Grid>
      <Grid item xs={12}>
        <QuestionAnalytics questionAnalyticsData={questionAnalyticsData} />
      </Grid>
    </Grid>
  );
};

export default QuestionSpecificReports;
