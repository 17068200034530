import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Breadcrumbs, Grid, Typography, Icon } from '@mui/material';
import CommonUI from '../CommonUI';
import Stepper from '../../components/Stepper/Stepper';
import AddQuestions from './AddQuestions/AddQuestions';
import Preview from './Preview/Preview';
import ShareSurvey from './ShareSurvey/ShareSurvey';
import { getAccessToken } from './auth';
import CreateBasicInfo from './CreateBasicInfo/CreateBasicInfo';
import { useTheme } from '@mui/styles';

const steps = ['Basic Information', 'Add Questions', 'Preview', 'Share Survey'];

export default function CreateSurvey(props) {
  const theme = useTheme();
  const navigate = useNavigate();

  const [moduleId, setmoduleId] = useState('');
  const [surveysCode, setSurveysCode] = useState('');
  const [updatedFlag, setUpdatedFlag] = useState(false);
  const [isAddQuestionVisited, setIsAddQuestionVisited] = useState(false);
  const [sectionId, setSectionId] = useState();
  const [section, setSection] = useState('');
  const [expirydate, setExpiryDate] = useState('');
  const [activeStep, setActiveStep] = useState(0);

  const currentURL = window.location.href;
  const url = new URL(currentURL);
  const stepsParam = url.searchParams.get('step');
  useEffect(() => {
    if (stepsParam === '0') {
      setActiveStep(0);
    }
    if (stepsParam === '1') {
      setActiveStep(1);
    }
    if (stepsParam === '2') {
      setActiveStep(2);
    }
    if (stepsParam === '3') {
      setActiveStep(3);
    }
  }, [stepsParam]);

  const token = getAccessToken();
  const renderStepComponent = (step) => {
    switch (step) {
      case 0:
        return (
          <CreateBasicInfo
            onNextClick={handleChildNextClick}
            activeStep={activeStep}
            setUpdatedFlag={setUpdatedFlag}
            token={token}
            isAddQuestionVisited={isAddQuestionVisited}
            expirydate={expirydate}
            setExpiryDate={setExpiryDate}
          />
        );

      case 1:
        return (
          <AddQuestions
            token={token}
            smId={moduleId}
            survey_code={surveysCode}
            activeStep={activeStep}
            expiryData={expirydate}
            onNextClick={handleChildNextClick}
            handleAddQuesBackButton={handleAddQuesBackButton}
            updatedFlag={updatedFlag}
            setUpdatedFlag={setUpdatedFlag}
            sectionId={sectionId}
            setSectionId={setSectionId}
            section={section}
            setSection={setSection}
            expiryDate={expirydate}
            setIsAddQuestionVisited={setIsAddQuestionVisited}
          />
        );
      case 2:
        return (
          <Preview
            smId={moduleId}
            survey_code={surveysCode}
            token={token}
            activeStep={activeStep}
            onNextClick={handleChildNextClick}
            sectionId={sectionId}
            handlePreviewBackButton={handlePreviewBackButton}
            expiryDate={expirydate}
            setUpdatedFlag={setUpdatedFlag}
          />
        );
      case 3:
        return (
          <ShareSurvey
            smId={moduleId}
            survey_code={surveysCode}
            token={token}
            expiryDate={expirydate}
            setActiveStep={setActiveStep}
          />
        );

      // Add cases for other steps as needed
      default:
        return null;
    }
  };
  const handleChildNextClick = (data, surveyCode, expiryDate) => {
    const stepperURL = `/surveycreation?id=${data}&step=${activeStep + 1}`;
    // console.log("moduleId", data);
    setSurveysCode(surveyCode);
    setExpiryDate(expiryDate);
    setmoduleId(data);
    setActiveStep(activeStep + 1);
    navigate(stepperURL);
  };

  //handle AddQuestionBack
  const handleAddQuesBackButton = (surveyId, surveyCode, sectionId) => {
    setmoduleId(surveyId);
    setSurveysCode(surveyCode);
    setActiveStep((prevState) => prevState - 1);
    setSectionId(sectionId);
    navigate(`/surveycreation?id=${surveyId}&step=0`);
  };
  //handle PreviewBack to Edit
  const handlePreviewBackButton = (surveyId, surveyCode, sectionId) => {
    // console.log("PreviewBtnback>>>>>>", surveyId);
    setmoduleId(surveyId);
    setSurveysCode(surveyCode);
    setActiveStep((prevState) => prevState - 1);
    setSectionId(sectionId);
    setUpdatedFlag(true);
    navigate(`/surveycreation?id=${surveyId}&step=1`);
  };

  function handleManageSurveyyClick(event) {
    event.preventDefault();
    navigate('/survey/summary');
  }

  const breadcrumbs = [
    <Link
      key="1"
      style={{
        cursor: 'pointer',
        textDecoration: 'none',
      }}
      onClick={handleManageSurveyyClick}
    >
      <Typography
        variant="body4"
        sx={(them) => ({
          color: `${theme.palette.blue.dark}!important`,
          fontWeight: 500,
        })}
      >
        Manage Survey
      </Typography>
    </Link>,
    <Typography
      key="2"
      variant="body4"
      sx={(theme) => ({ color: `${theme.palette.black.light}!important` })}
    >
      Create Survey
    </Typography>,
  ];

  return (
    <CommonUI headertext="Create Survey">
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Breadcrumbs
              separator={
                <Icon
                  className="fh-icon fh-icon-right"
                  sx={{ fontSize: '0.875rem', mt: 0.5 }}
                />
              }
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Stepper steps={steps} activeStep={activeStep} />
          </Grid>
          <Grid
            item
            // xs={activeStep === 2 ? 12 : activeStep === 1 ? 8 : 8}
            xs={12}
            sm={activeStep === 2 ? 12 : 8}
          >
            {renderStepComponent(activeStep)}
          </Grid>
        </Grid>
      </React.Fragment>
    </CommonUI>
  );
}
