import { Grid } from '@mui/material';
import React, { lazy, Suspense } from 'react';

// import QuestionsDisplay from "./QuestionsDisplay";
const QuestionsDisplay = lazy(() => import('./QuestionsDisplay'));
const actionsBtn = [
  { id: 1, icon: 'fh-icon fh-icon-edit', btnName: 'edit' },
  { id: 2, icon: 'fh-icon fh-icon-delete', btnName: 'delete' },
];

const Preview = ({
  smId,
  activeStep,
  survey_code,
  onNextClick,
  token,
  handlePreviewBackButton,
  sectionId,
  expiryDate,
}) => {
  return (
    <Suspense fallback={'Loading...'}>
      <Grid container mt={5}>
        <Grid item xs={12}>
          <QuestionsDisplay
            actionsBtn={actionsBtn}
            smId={smId}
            activeStep={activeStep}
            onNextClick={onNextClick}
            survey_code={survey_code}
            token={token}
            handlePreviewBackButton={handlePreviewBackButton}
            expiryDate={expiryDate}
            sectionId={sectionId}
          />
        </Grid>
      </Grid>
    </Suspense>
  );
};

export default Preview;
