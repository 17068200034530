import { makeStyles } from "@mui/styles";
export default makeStyles((theme) => ({
  // root: {
  //     marginTop: theme.spacing(2),
  //     width: { lg: '100%', xs: '60%', md: '50% !important' },
  //     border: '1px solid #ddd'
  // },
  // table: {
  //     borderRadius: '5px',
  //     '& .MuiTableCell-head': {
  //         background: theme.palette.mode === 'light' ? `${theme.palette.black.main}` : `${theme.palette.onSurface2.main}`,
  //         color: theme.palette.mode === 'light' ? `${theme.palette.white.main} !important` : `${theme.palette.white.main} !important`,
  //         fontSize: '12px',
  //         fontWeight: '500',
  //         // textAlign:'center',
  //         padding: '10px'
  //     },
  //     '& .MuiTableCell-body': {
  //         fontWeight: '500',
  //         fontSize: '12px',
  //         // textAlign: 'center',
  //         padding: '10px',
  //         background: theme.palette.mode === 'light' ? `${theme.palette.white.main}` : `${theme.palette.base.main}`,
  //         color: theme.palette.mode === 'light' ? `${theme.palette.black.main}` : `${theme.palette.white.main}`,
  //     },
  //     '& .MuiTableRow-root:hover': {
  //         // background: '#F5F6FE !important',
  //         // backgroundColor:theme.palette.mode==='light'?`red !important`:``,
  //         cursor: 'pointer'
  //     },
  //     tableCellMargin: {
  //         margin: 'auto'
  //     }
  // }
  selectLabel: {
    fontSize: "0.875rem!important", // Custom font size for select label
  },
  select: {
    fontSize: "0.875rem!important", // Custom font size for select input
  },
  displayedRows: {
    fontSize: "0.875rem!important", // Custom font size for displayed rows text
  },
}));
