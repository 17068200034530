import * as React from 'react';
import {
  Box,
  TextField,
  Autocomplete as MuiAutocomplete,
  Paper,
  Typography,
  Icon,
} from '@mui/material';
import useStyles from './styles';
const CustomPaper = (props) => {
  return (
    <Paper
      elevation={2}
      {...props}
      sx={{
        p: 1,
        border: '1px solid #c6c6c6',
        overflow: 'auto',
        '& ul::-webkit-scrollbar-track': {
          // background: "none",
        },
        '& ul::-webkit-scrollbar-thumb': {
          background: '#C6C6C6',
          borderRadius: '10px',
        },
        '& ul::-webkit-scrollbar': {
          width: '4px',
        },
        '& ul>li': {
          fontSize: '16px',
        },
        '& .MuiAutocomplete-noOptions': {
          fontSize: '16px',
        },
      }}
    />
  );
};

export default function AutoComplete(props) {
  const {
    id,
    options,
    placeholder,
    onChange,
    icon,
    value,
    readOnly = false,
    disabled = false,
  } = props;
  const classes = useStyles(props);
  
  return (
    <MuiAutocomplete
      disabled={disabled}
      fullWidth
      value={value || null}
      id={id}
      PaperComponent={CustomPaper}
      sx={{
        '& .MuiAutocomplete-clearIndicator': {
          display: 'none',
        },
        '& .MuiAutocomplete-input': {
          padding: '3px 5px !important',
        },
      }}
      // size={size || "small"}
      onChange={onChange}
      options={options}
      autoHighlight
      getOptionLabel={(option) => option.label || ''}
      getOptionDisabled={(option) => option.disabled || false}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      getOptionSelected={(option, value) => option.label === value.label}
      readOnly={readOnly}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {option.icon ? (
            <Icon
              className={`fh-icon ${option.icon}`}
              sx={{ marginRight: '8px' }}
            />
          ) : (
            <></>
          )}
          <Typography variant="body3">{option.label}</Typography>
        </Box>
      )}
      renderInput={(params) => (
        <Box>
          <Box sx={{ marginRight: 0.5 }}>
            {icon !== null || icon !== undefined ? (
              <Icon className={`${icon} ${classes.gpsIcon}`} />
            ) : (
              <></>
            )}
          </Box>
          <TextField
            {...params}
            placeholder={placeholder}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off', // disable autocomplete and autofill
            }}
          />
        </Box>
      )}
    />
  );
}
