import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
export default function CustomDatePicker({
  size = 'small',
  value,
  onAccept,
  localeText,
  onChange,
}) {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      localeText={localeText} //for changing Ok and cancel button text
    >
      <DatePicker
        // openTo="day"
        autoFocus={false}
        inputFormat="dd-MM-yyyy"
        // PaperProps={dateTimePaperPropsStyles}
        value={value}
        renderInput={(params) => (
          <TextField {...params} error={false} size={size} />
        )}
        PopperProps={{
          placement: 'bottom-start',
          style: {
            border: '1px solid #c6c6c6',
            borderRadius: '4px',
          },
          sx: {
            '.MuiPickersCalendarHeader-label': {
              fontSize: 'initial',
            },
            '.MuiDialogActions-root': {
              justifyContent: 'space-between !important',
            },
          },
        }}
        DialogProps={{
          sx: {
            '.MuiPickersCalendarHeader-label': {
              fontSize: 'initial',
            },
          },
        }}
        showDaysOutsideCurrentMonth={true}
        onChange={onChange}
        views={['year', 'month', 'day']}
        componentsProps={{
          actionBar: {
            actions: ['cancel', 'accept'],
          },
        }}
        onAccept={onAccept}
        size="small"
        sx={{
          '& .MuiPickersCalendarHeader-label': {
            fontSize: 'initial',
          },
        }}
        closeOnSelect={false}
      />
    </LocalizationProvider>
  );
}

// const dateTimePaperPropsStyles = {
//   sx: {
//     '.MuiPickersCalendarHeader-root': {
//       display: 'flex',
//       alignItems: 'center',
//       justifyItems: 'center',
//     },
//     '.MuiPickersCalendarHeader-root:first-of-child': {
//       order: 0,
//       paddingRight: '20px',
//       paddingLeft: '20px',
//     },
//     '.MuiPickersArrowSwitcher-root': {
//       display: 'inline-flex',
//       // visibility: "hidden"
//     },
//     '.MuiPickersCalendarHeader-label': {
//       textAlign: 'center',
//       fontSize: '0.875rem !important',
//     },
//     '.MuiPickersArrowSwitcher-spacer': {
//       width: '220px',
//     },
//     '.css-31ca4x-MuiPickersFadeTransitionGroup-root': {
//       fontSize: '0.875rem',
//       display: 'flex',
//       position: 'absolute',
//       paddingLeft: '92px',
//     },
//     '.css-9reuh9-MuiPickersArrowSwitcher-root': {
//       marginLeft: '-2px',
//     },
//     '.MuiPickersArrowSwitcher-button': {
//       paddingRight: '7px',
//     },
//   },
// };
