import { makeStyles } from '@mui/styles';
export default makeStyles((theme) => ({
  root: {
    '& .MuiFormControlLabel-label': {
      fontSize: '16px',
      fontWeight: '500 !important',
    },
    '& .MuiFormControlLabel-root': {
      marginLeft: '0px',
      marginRight: '15px',
      // fontWeight:'500 !important'
    },
  },
  radio: {
    '&$checked': {
      color:
        localStorage.getItem('theme') === 'light'
          ? `${theme.palette.blue.dark} !important`
          : `${theme.palette.white.main} !important`,
    },
  },
  checked: {},
  // starColor: {

  // },
  '& .fh-icon-sad-filled::before': {
    content: 'e9b5 !important',
    color: '#C6C6C6 !important',
  },

  '& .fh-icon-angry-filled::before': {
    content: 'e9b5 !important',
    color: '#C6C6C6 !important',
  },
}));
