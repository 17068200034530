import React from 'react';
import {
  Box,
  FormControlLabel,
  InputLabel,
  Checkbox as MuiCheckbox,
  Typography,
} from '@mui/material';
import useStyles from './styles';

export default function Checkbox(props) {
  const {
    name,
    label,
    checked,
    icon,
    checkedIcon,
    disabled,
    onChange,
    labelPlacement,
    optionsFontStyle,
    optionsFontSize,
  } = props;
  const classes = useStyles();

  // const convertToDefaultPara = (name, value) => ({
  //     target: {
  //         name, value
  //     }
  // })

  return (
    <Box sx={{ display: 'flex' }}>
      <FormControlLabel
        label={
          <Typography
            variant="body3"
            sx={{
              color: '#333',
              fontFamily: optionsFontStyle
                ? `${optionsFontStyle} !important`
                : 'Poppins',
              fontSize: optionsFontSize
                ? `${optionsFontSize}px !important`
                : '1rem',
            }}
          >
            {label && label}
          </Typography>
        }
        control={
          <MuiCheckbox
            checked={checked}
            icon={icon}
            checkedIcon={checkedIcon}
            disableRipple
            classes={{
              root: classes.root,
              checked: classes.checked,
            }}
            name={name}
            // onChange={e => onChange(convertToDefaultPara(name, e.target.checked))}
            onChange={onChange}
            disabled={disabled}
          />
        }
        labelPlacement={labelPlacement}
        // sx={{m:3}}
      />
      {/* <InputLabel>{labelPlacement}</InputLabel> */}
    </Box>
  );
}
