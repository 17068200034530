import React, { useState} from "react";
import {
  MenuItem,
  OutlinedInput,
  Typography,
  Box,
  Select as MuiSelect,
  Divider,
  InputLabel,
} from "@mui/material";
import useStyles from "./styles";

const Select = (props) => {
  const [openAttribute, setOpenAttribute] = useState("closeMenu");

  const {
    options,
    label,
    name,
    value,
    placeholder,
    onChange,
    helpText,
    flexdirection,
    width,
    passingValue,
  } = props;
  const classes = useStyles();

  function changeAttributes(attribute) {
    if (attribute === true) {
      setOpenAttribute("openMenu");
    } else {
      setOpenAttribute("closeMenu");
    }
  }
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: `${flexdirection || "row"}`,alignItems:'center' }}>
        {/* {label&&<Typography variant="body2" className={flexdirection === "column" ? classes.tagLabel : classes.rowTagLabel} >
          {label}
        </Typography>} */}
        {label && (
          <InputLabel sx={{width:'350px !important'}} className={classes.formLabel}>{label}</InputLabel>
        )}
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            className={`${classes.root} ${classes[openAttribute]}`}
            sx={{ width }}
          >
            <MuiSelect
              sx={{ width }}
              className={`${classes.iconChange}`}
              fullWidth
              MenuProps={{
                className: classes.menuStyle,
                MenuListProps: {
                  className: classes.selectMenu,
                },
              }}
              onOpen={() => changeAttributes(true)}
              onClose={() => changeAttributes(false)}
              IconComponent={() =>
                openAttribute === "openMenu" ? (
                  <Box
                    component="span"
                    className={`fh-icon fh-icon-up ${classes.iconStyle}`}
                  ></Box>
                ) : (
                  <Box
                    component="span"
                    className={`fh-icon fh-icon-down ${classes.iconStyle}`}
                  ></Box>
                )
              }
              value={value}
              onChange={onChange}
              name={name}
              displayEmpty
              placeholder={placeholder}
              input={
                <OutlinedInput
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500 !important",
                    padding: "12px !important",
                  }}
                />
              }
              renderValue={
                value !== ""
                  ? undefined
                  : () => (
                      <Typography
                        variant="body3"
                        className={classes.placeholder}
                      >
                        {placeholder}
                      </Typography>
                    )
              }
            >
              <Box>
                <Divider variant="middle" classes={{ root: classes.divider }} />
              </Box>
              {options.map((item, index) => (
                <MenuItem
                  key={index}
                  value={item[passingValue] || item.title}
                  className={classes.options}
                >
                  {item.title}
                </MenuItem>
              ))}
            </MuiSelect>
          </Box>
          <Typography variant="body4" className={classes.helpText}>
            {helpText}
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};
export default Select;
