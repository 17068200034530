import { Grid, Typography } from '@mui/material';
import React from 'react';
import CommonChartContainer from '../CommonChartContainer/CommonChartContainer';
import { BarChart } from '../../../../../../components/common/charts';

const MultipleTypeChart = ({ item }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <CommonChartContainer>
          <Typography variant="sh3">Submissions Breakdown</Typography>
          <BarChart
            legendDisplay={false}
            barWidth={'100%'}
            barHeight={'auto'}
            barLabels={[
              ['Not Submitted', ' by known'],
              ['Submitted', ' by known'],
              ['Submitted', ' by Un-known'],
              ['Not Submitted', ' by Un-known'],
            ]}
            datasets={[
              {
                data: [
                  item.Not_Submitted_by_known,
                  item.Submitted_by_known,
                  item.Submitted_by_unknown,
                  item.Not_Submitted_by_unknown,
                ],
                backgroundColor: ['#8B45D8', '#D86445', '#458FD8', '#D8459C'],
              },
            ]}
          />
        </CommonChartContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <CommonChartContainer>
          <Typography variant="sh3">Respondent</Typography>
          <BarChart
            legendDisplay={false}
            barWidth={'100%'}
            barHeight={'auto'}
            barLabels={item.labels}
            datasets={[
              {
                data: item.label_counts,
                backgroundColor: ['#458FD8'],
              },
            ]}
          />
        </CommonChartContainer>
      </Grid>
    </Grid>
  );
};

export default MultipleTypeChart;
