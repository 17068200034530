import React, { createContext, useContext, useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";

const ThemeCtx = createContext();
// Creates a new context object

export function ThemeCtxProvider({children}){
    // check whether the client's system has enabled dark theme
  // if enabled then, use dark theme by default
  
    const [darkMode, setDarkMode] = useState();
    const prefersDarkMode = useMediaQuery(`(prefers-color-scheme: ${darkMode})`);


    // state variable to check wheather dark mode is enabled or not
    
    useEffect(()=>{
        localStorage.getItem('theme')==='dark' ? setDarkMode(true) : localStorage.getItem('theme')==='light' ? setDarkMode(false) : setDarkMode(prefersDarkMode)
    }, [prefersDarkMode])

    // toggle the theme function
    const handleDarkMode = () => {
        darkMode ? localStorage.setItem('theme', 'light') || setDarkMode(false) : localStorage.setItem('theme', 'dark') || setDarkMode(true)
    };

    // return the, Provider component that allows the
    // consuming components to subscribe to context
    // changes.
    return (
        <ThemeCtx.Provider value={{ darkMode, handleDarkMode }}>
            {children}
        </ThemeCtx.Provider>
    );
}

export const useThemeMode=(props)=>{
    // return the current context value for themeCtx
  // i.e. darkMode and handleDarkMode
    return useContext(ThemeCtx);
}