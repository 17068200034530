import { FormHelperText, MenuItem, Select, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    backgroundColor: "red",
  },
  list: {
    // backgroundColor: "blue",
    border: "1px solid #C6C6C6",
    minHeight: 220,
    maxHeight: 320,
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#e8e8e8",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#C6C6C6",
      borderRadius: "6px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
}));

const CustomSelect = ({ options, value, onChange, name, size, helperText }) => {
  const classes = useStyles();
  return (
    <>
      <Select
        fullWidth
        placeholder="Choose an Option"
        value={value}
        onChange={onChange}
        name={name}
        size={size || "small"}
        MenuProps={{ classes: { list: classes.list } }}
      >
        <MenuItem value={""}>
          <Typography variant="body3">Choose an Option</Typography>
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            <Typography variant="body3">{option.title}</Typography>
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </>
  );
};

export default CustomSelect;
