import { makeStyles } from "@mui/styles";
export default makeStyles(theme=>({
    mainWrapper: {
        width: "100%",
        marginTop: `${theme.spacing(9)} !important`,
        // border: '1px solid red'
        minHeight:'100vh',
        overflow:'hidden',
        padding:'8px 16px',
    },
    toolbar: theme.mixins.toolbar,
}))