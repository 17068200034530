import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  //QuestionsDisplay Start
  previewContainer: {
    // border: "1px solid #EBECF5",
    borderRadius: "4px",
    padding: "20px",
    "&.desktopDisplay": {
      height: "1000px",
      // border:'1px solid #f00',
      padding: "20px",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "0.4em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,.1)",
      },
    },
    "&.tabletDisplay": {
      height: "1150px",
    },
    "&.mobileDisplay": {
      height: "882px",
    },
  },
  //

  //   PreviewHeader Start
  headerPreview: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#EBECF5",
    paddingLeft: "24px",
    height: "62px",
    paddingRight: "24px",
    borderRadius: "4px",
  },
  leftContent: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  iconButton: {
    borderRadius: "4px!important",
    marginLeft: "8px !important",
    "&.selected": {
      background: "#161E6A",
      borderRadius: "4px",
      color: "#fff",
    },
  },
  icon: {
    fontSize: "1em !important",
    color: "#000",
    "&.selected": {
      color: "#fff",
    },
  }, //   PreviewHeader End
  //   PreviewBody Start
  previewBodyContainer: {
    padding: "32px 50px 32px",
    // display: "flex",
     justifyContent: "left",
    alignItems: "left",
  },
  display: {
    position: "relative",
    // border:'1px solid #f00',
    "&.desktopDisplay": {
      width: "100%",
      //   border: "1px solid #f00",
      padding: "12px 18px",
      height: "100vh",
    },
    "&.tabletDisplay": {
      //   border: "1px solid #0f0",
      width: "719px",
      border: "30px solid #C4C4C4",
      height: 1000,
      overflowY: "scroll",
      borderRadius: 38,
      margin: "0 auto",
      "&::-webkit-scrollbar": {
        width: "0.4em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,.1)",
      },

      //   padding:'8px 16px'
    },
    "&.mobileDisplay": {
      //   border: "1px solid #0f0",
      width: "390px",
      margin: "0 auto",
      //   height: "30vh",
      border: "15px solid #C4C4C4",
      padding: "0px 16px 35px",

      height: 750,
      borderRadius: 57,
      overflowY:'scroll',
      "&::-webkit-scrollbar": {
        width: "0.2em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,.1)",
      },
    },
  },
  deviceTopIcon: {
    position: "absolute",
    "&.tabletTopIcon": {
      top: -30,
      left: 244,
    },
    "&.mobileTopIcon": {
      top: -7,
      left: 122,
    },
  },
  innerDeviceTabletDisplay: {
    // border: "1px solid #f00",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    background: "#ECECEC",
    // position: "absolute",
    // top: 0,
    // left: 0,
    width: "100%",
    height: "40px",
    "&.tabletInnerIcon": {},
    "&.mobileInnerIcon": {},
  },
  innerDeviceMobileDisplay: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 16px",
  },
  urlBox: {
    width: "524px",
    height: "22px",
    background: "#FFF",
    borderRadius: "10px",
  },
  innerMobileDisplayIcon: {
    width: 25,
    height: 12,
  },
  innerDesktopContent: {
    padding: "0px 24",
  },
  innerTabletContent: {
    padding: 32,
  },
  innerMobileContent: {
    padding: "0px 24",
  },
  logo: {
    objectFit: "fill",
    "&.desktopLogo": { width: 160, height: 120 },
    "&.tabletLogo": { width: 80, height: 80 },
    "&.mobileLogo": { width: 40, height: 40 },
  },
  bannerContainer: {
    "&.mobileContainer": {
      height: "80px",
    },
    "&.alldeviceContainer": { height: "160px" },
  },
  banner: { width: "100%", height: "100%", objectFit: "fill" },
  containers :{
    border: "1px solid #C6C6C6",
    width: "897px",
    height: "844px",
    top: "50%", /* Center vertically */
    left: "50%", /* Center horizontally */
    transform: "translate(-50%, -50%)", /* Center horizontally and vertically */
    borderRadius: "10px",
    padding: "32px 40px",
    gap: "23px",
    position: "absolute",
    //color:"#C6C6C6"
  },
  clintcontainer :{
    border: "1px solid #C6C6C6",
    width: "897px",
    height: "844px",
    top: "50%", /* Center vertically */
    left: "50%", /* Center horizontally */
    transform: "translate(-50%, -50%)", /* Center horizontally and vertically */
    borderRadius: "10px",
    padding: "32px 40px",
    gap: "23px",
    position: "absolute",
    //color:"#C6C6C6"
  },
  
  


  //   PreviewBody End
}));
