import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import documentIcon from '../../../assets/svg/ep_document.svg';
import imageIcon from '../../../assets/svg/image-16-regular.svg';
import videoIcon from '../../../assets/svg/iconoir_youtube.svg';
import playIcon from '../../../assets/svg/bi_play-circle.svg';
import ActionMenu from '../ActionMenu/ActionMenu';
import Overlay from './Overlay';
import { toast } from 'react-toastify';

const Fileupload = (props) => {
  const {
    document_type,
    file_type,
    selectedFile,
    setSelectedFile,
    width,
    fileTitle,
    minFileSize,
    maxFileSize,
    documentSubtitle,
    imageSubtitle,
    videoSubtitle,
    documentSubcontent,
    imageSubcontent,
    videoSubcontent,
  } = props;

  const [dropzoneDisabled, setDropzoneDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [msg, setMsg] = useState('');
  const [showOverlay, setShowOverlay] = useState(false);

  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      const errorMessage =
        fileTitle === 'logo' && document_type === 'image'
          ? 'Please upload a valid file type with 200KB'
          : fileTitle === 'banner' && document_type === 'image'
          ? 'Please upload a valid file type with 5MB'
          : '';

      setErrorMsg(errorMessage);
      toast.error(errorMessage, {
        style: { fontSize: '0.9rem' },
      });
    } else {
      setSelectedFile(acceptedFiles[0]);
      setDropzoneDisabled(true);
      setMsg('Uploaded Successfully');
      toast.success('Uploaded Successfully', {
        style: { fontSize: '0.9rem' },
      });
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: file_type,
    onDrop,
    disabled: dropzoneDisabled,
    multiple: false, // Assume single file upload
    minSize: minFileSize,
    maxSize: maxFileSize,
  });

  const actionMenu = [
    {
      value: 'preview',
      text: 'Preview',
      fun: () => setShowOverlay(true),
    },
    {
      value: 'remove',
      text: 'Remove',
      fun: () => {
        setSelectedFile(null);
        setDropzoneDisabled(false);
        setMsg('');
        setErrorMsg('');
      },
    },
  ];

  return (
    <>
      <Box
        {...getRootProps()}
        style={{
          border: isDragActive
            ? '2px dashed #ACB3F1'
            : document_type === 'video' && selectedFile
            ? ''
            : '1px solid #5E5E5E',
          width:
            document_type === 'video'
              ? '280px'
              : document_type === 'image'
              ? width
              : '156px',
          height: '156px',
          borderRadius: '6px',
          textAlign: 'center',
          background: isDragActive ? '#E4E7FE' : '',
          cursor: 'pointer',
        }}
      >
        <input {...getInputProps()} />
        {selectedFile ? (
          <Box sx={{ width: '100%', height: '100%' }}>
            {document_type && (
              <img
                src={
                  document_type === 'file'
                    ? documentIcon
                    : document_type === 'image'
                    ? imageIcon
                    : document_type === 'video'
                    ? playIcon
                    : null
                }
                alt=""
                style={{
                  display: document_type === 'image' ? 'none' : 'inline-block',
                  margin: document_type === 'file' ? '30px 0px 5px' : '',
                }}
              />
            )}
            {document_type === 'file' && (
              <Box sx={{ width: '100%', height: 'auto', position: 'relative' }}>
                <Typography
                  sx={{
                    color: '#333',
                    fontSize: '16px',
                    wordBreak: 'break-all',
                  }}
                >
                  {selectedFile.name}
                </Typography>
              </Box>
            )}
            {document_type === 'image' && (
              <img
                src={URL.createObjectURL(selectedFile)}
                alt=""
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'fill',
                  borderRadius: '6px',
                }}
              />
            )}
            {document_type === 'video' && (
              <video
                width={280}
                height={156}
                style={{ borderRadius: 4 }}
                controls
              >
                <source src={URL.createObjectURL(selectedFile)}></source>
              </video>
            )}
          </Box>
        ) : (
          <Box>
            {isDragActive ? (
              <Box sx={{ padding: '1rem' }}>
                <img
                  src={
                    document_type === 'file'
                      ? documentIcon
                      : document_type === 'image'
                      ? imageIcon
                      : document_type === 'video'
                      ? videoIcon
                      : null
                  }
                  alt={''}
                />
                <Typography
                  variant="sh3"
                  component={'p'}
                  sx={{ color: '#5B6AF1' }}
                >
                  Drop here
                </Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  width: '100%',
                  height: 'auto',
                  padding: document_type === 'video' ? '2rem' : '',
                }}
              >
                {document_type && (
                  <img
                    src={
                      document_type === 'file'
                        ? documentIcon
                        : document_type === 'image'
                        ? imageIcon
                        : document_type === 'video'
                        ? videoIcon
                        : null
                    }
                    alt=""
                    style={{
                      display:
                        document_type === 'file' ? 'inline-block' : 'block',
                      margin: '40px auto 0px',
                    }}
                  />
                )}
                <Typography
                  variant="sh3"
                  component={'p'}
                  sx={{
                    // mt: 1,
                    color: '#161E6A',
                    cursor: 'pointer',
                    '&:hover': { color: '#333' },
                  }}
                >
                  {document_type === 'file'
                    ? documentSubtitle
                    : document_type === 'image'
                    ? imageSubtitle
                    : document_type === 'video'
                    ? videoSubtitle
                    : null}
                </Typography>
                <Typography
                  variant="body4"
                  component={'p'}
                  sx={{
                    width: fileTitle === 'banner' ? '304px' : null,
                    margin: '8px auto',
                    wordBreak:
                      fileTitle === 'banner' ? 'break-all !important' : null,
                  }}
                >
                  {document_type === 'file'
                    ? documentSubcontent
                    : document_type === 'image'
                    ? imageSubcontent
                    : document_type === 'video'
                    ? videoSubcontent
                    : null}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
      {selectedFile && (
        <Box
          sx={{
            position: 'relative',
            // top: -156,
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: -156,
              right: fileTitle === 'banner' ? 0 : 'calc(100% - 155px)',
            }}
          >
            <ActionMenu
              actions={actionMenu}
              menuColor="#fff"
              horizontal={-6}
              vertical={10}
              // onClick={(event) => event.stopPropagation()}
            />
          </Box>
        </Box>
      )}
      {showOverlay && (
        <Overlay
          open={showOverlay}
          onClose={() => setShowOverlay(false)}
          document={
            document_type === 'file' ? URL.createObjectURL(selectedFile) : null
          }
          image={
            document_type === 'image' ? URL.createObjectURL(selectedFile) : null
          }
          video={
            document_type === 'video' ? URL.createObjectURL(selectedFile) : null
          }
          title={
            document_type === 'image' || document_type === 'video'
              ? selectedFile.name
              : null
          }
        />
      )}
    </>
  );
};
export default Fileupload;
