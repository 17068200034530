import axios from 'axios';



export const getMethod = async (url, token) => {
    const response = await axios({
        method:'GET',
        url,
        headers: {"Authorization" : `Bearer ${token}`},
      })
   
  
   return response;


  }
