import React, { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import useStyles from './styles';

export default function useTable(records, headCells, filterFn) {
  const classes = useStyles();
  // console.log(headCells.length);
  const pages = [5, 10, 20, 25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  // Reset page to 0 when the filtered records change
  useEffect(() => {
    setPage(0);
  }, [filterFn, records]);

  const TblContainer = (props) => (
    <Box
      sx={{
        border: '1px solid #C6C6C6',
        borderRadius: '4px',
      }}
    >
      <TableContainer
        component={Paper}
        sx={{
          overflowX: 'auto',
          '&::-webkit-scrollbar': {
            height: '0.3em',
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0.1)',
            width: '0.4em',
          },
          // Responsive widths for different breakpoints
          '@media (min-width: 600px)': {
            width: '100%',
          },
          '@media (min-width: 900px)': {
            width: '100%',
          },
          '@media (min-width: 1200px)': {
            width: '100%',
          },
        }}
      >
        <Table className={classes.table}>{props.children}</Table>
      </TableContainer>
      <TablePagination
        component="div"
        page={page}
        rowsPerPageOptions={pages}
        rowsPerPage={rowsPerPage}
        count={records.length}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        classes={{
          selectLabel: classes.selectLabel, // Custom class for select label
          select: classes.select, // Custom class for select input
          displayedRows: classes.displayedRows, // Custom class for displayed rows text
        }}
        // sx={{
        //   ".MuiTablePagination-toolbar": {
        //     backgroundColor: "#a9aeb3",
        //     // width: "950px",
        //     color: "rgb(41, 39, 39)",
        //     height: "35px",
        //   },
        // }}

        menuprops={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          PaperProps: {
            style: {
              fontSize: '16px', // Custom font size for dropdown list options
            },
          },
        }}
      />
    </Box>
  );

  const TblHead = (props) => {
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.align}
              // align={'center'}
              // className={classes.tableCellMargin}
            >
              <Typography variant={props.variant} color={props.color}>
                {headCell.label}
              </Typography>
              {/*<TableSortLabel>{headCell.label}</TableSortLabel>*/}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // const TblPagination = () => (
  //   <>
  //     <TablePagination
  //       component="div"
  //       page={page}
  //       rowsPerPageOptions={pages}
  //       rowsPerPage={rowsPerPage}
  //       count={records.length}
  //       onPageChange={handleChangePage}
  //       onRowsPerPageChange={handleChangeRowsPerPage}
  //       sx={{
  //         '& .MuiTablePagination-selectLabel': {
  //           color: '#777777',
  //           fontSize: '16px',
  //         },
  //         '& .MuiSelect-select': {
  //           border: '1px solid #161E6A',
  //         },
  //         '& .MuiTablePagination-displayedRows': {
  //           fontSize: '16px',
  //         },
  //         // '& .MuiTablePagination-actions>.MuiButtonBase-root':{
  //         //     border:'1px solid #161E6A',
  //         //     borderRadius:0,
  //         //     color:'#161E6A',
  //         // }
  //       }}
  //     />
  //   </>
  // );
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };
  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const recordsAfterPagingAndSorting = () => {
    return stableSort(
      filterFn.fn(records),
      getComparator(order, orderBy)
    ).slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  };

  return {
    TblContainer,
    TblHead,
    // TblPagination,
    recordsAfterPagingAndSorting,
  };
}
