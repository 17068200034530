import React, { useState, useEffect } from "react";
import {
  MenuItem,
  OutlinedInput,
  Typography,
  Box,
  Select as MuiSelect,
  Divider,
  Chip
} from "@mui/material";
import useStyles from "./styles";

const Select = (props) => {
  const [openAttribute, setOpenAttribute] = useState("closeMenu");
  const [personName, setPersonName] = React.useState([]);

  const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    // 'Miriam Wagner',
    // 'Bradley Wilkerson',
    // 'Virginia Andrews',
    // 'Kelly Snyder',
    // 'Abhishek',
    // "Aman",
    // 'Ashi'
  ];

  const {
    options,
    label,
    name,
    value,
    placeholder,
    onChange,
    helpText,
    flexdirection,
    width
  } = props;
  const classes = useStyles();

//   const [chips, setChips] = useState([]);
    // console.log({ chips })

    const handleDeleteChip = (value) => {
    
      // console.log("clicked delete");

        setPersonName((personName) => personName.filter((person) => person !== value));
        // console.log("personName"+" "+personName)
        
    };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  // const mouseDown = (event, value ) => {
  //   handleDeleteChip(value);
  //   event.stopPropagation();
    
  // }

  

  function changeAttributes(attribute) {
    if (attribute === true) {
      setOpenAttribute("openMenu");
    } else {
      setOpenAttribute("closeMenu");
    }
  }

  // console.log("personName"+personName);
  return (
    
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: `${flexdirection||'row'}` }}>
        <Typography variant="body2" className={flexdirection === "column" ? classes.tagLabel : classes.rowTagLabel} >
          {label}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          className={`${classes.root} ${classes[openAttribute]}`}
          sx={{ width }}
        >
          <MuiSelect
            sx={{ width }}
            className={`${classes.iconChange}`}
            fullWidth
            multiple
            MenuProps={{
              
              className: classes.menuStyle,
              MenuListProps: {
                
                className: classes.selectMenu,
              },
            }}
            onOpen={() => changeAttributes(true)}
            onClose={() => changeAttributes(false)}
            IconComponent={() =>
              openAttribute === "openMenu" ? (
                <Box
                  component="span"
                  className={`fh-icon fh-icon-up ${classes.iconStyle}`}
                ></Box>
              ) : (
                <Box
                  component="span"
                  className={`fh-icon fh-icon-down ${classes.iconStyle}`}
                ></Box>
              )
            }
            value={personName}
            onChange={handleChange}
            name={name}
            onMouseDown={(event)=>event.stopPropagation()}
            displayEmpty
            input={
              <OutlinedInput
                sx={{
                  fontSize: "12px",
                  fontWeight: "500 !important",
                  
                  //padding: "12px !important",
                }}
              />
            } 
            // renderValue={value !== "" ? undefined 
            // : () => <Typography variant="body3" className={classes.placeholder}>{placeholder}</Typography>}
            renderValue={
              personName.length !== 0 ? 
              (selected) =>
             ( 
              
              
              <>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                  {selected.map((value) => (
                    <Chip 
                    key={value} 
                    label={value}
                    onDelete={(e)=>handleDeleteChip(value)}
                    className={`${classes.ChipSelect}`}
                    deleteIcon=
                    {<span className={`fh-icon fh-icon-close`} 
                    onMouseDown={(event) => event.stopPropagation()} 
                  />
                        
                }
                    />

                  ))}
                  <Typography variant="body3" className={classes.placeholder}>Placeholder</Typography>
                </Box>
                
                </>
              )
            : () => <Typography variant="body3" className={classes.placeholder}>Placeholder</Typography>
              //console.log(personName.length)
            
            //   (selected) =>
            //  ( 
              
              
            //   <>
            //     <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            //       {selected.map((value) => (
            //         <Chip 
            //         key={value} 
            //         label={value}
            //         onDelete={(e)=>handleDeleteChip(value)}
            //         className={`${classes.ChipSelect}`}
            //         deleteIcon=
            //         {<span className={`fh-icon fh-icon-close`} 
            //         onMouseDown={(event) => event.stopPropagation()} 
            //       />
                        
            //     }
            //         />

            //       ))}
            //       <Typography variant="body3" className={classes.placeholder}>Placeholder</Typography>
            //     </Box>
                
            //     </>
            //   )
              
            
            }
          >
            <Box>
            
              <Divider variant="middle" classes={{ root: classes.divider }} />
            </Box>
            {names.map((item, index) => (
              <MenuItem
                key={index}
                value={item}
                className={classes.options}
              >
                {item}
              </MenuItem>
            ))}
          </MuiSelect>
          
        </Box>
        <Typography variant="body4" className={classes.helpText}>
          {helpText}
        </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};
export default Select;
