import { Typography } from '@mui/material';
import React from 'react';
import { BarChart } from '../../../../../../components/common/charts';
import CommonChartContainer from '../CommonChartContainer/CommonChartContainer';

const TextTypeChart = ({ item }) => {
  return (
    <CommonChartContainer>
      <Typography variant="sh3">Submissions Breakdown</Typography>
      <BarChart
        legendDisplay={false}
        barWidth={'100%'}
        barHeight={'100%'}
        barLabels={[
          ['Not Submitted', ' by known'],
          ['Submitted', ' by known'],
          ['Submitted', ' by Un-known'],
          ['Not Submitted', ' by Un-known'],
        ]}
        datasets={[
          {
            data: [
              item.Not_Submitted_by_known,
              item.Submitted_by_known,
              item.Submitted_by_unknown,
              item.Not_Submitted_by_unknown,
            ],
            // label: "%",
            backgroundColor: ['#8B45D8', '#D86445', '#458FD8', '#D8459C'],
          },
        ]}
      />
    </CommonChartContainer>
  );
};

export default TextTypeChart;
