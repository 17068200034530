export const AnswerType = [
  { value: 0, text: 'Answers' },
  // { value: 1, text: "Logic" },
];

export const getRole = [
  { id: 1, icon: 'fh-icon fh-icon-short-paragraph', label: 'Short Answer' },
  { id: 2, icon: 'fh-icon fh-icon-long-paragraph', label: 'Paragraph' },
  { id: 3, icon: 'fh-icon fh-icon-circle', label: 'NPS' },
  {
    id: 4,
    icon: 'fh-icon fh-icon-copy',
    label: 'Single Selection',
    //disabled: true,
  },
  {
    id: 5,
    icon: 'fh-icon fh-icon-tick-circle',
    label: 'Multiple Selection',
    //disabled: true,
  },
  {
    id: 6,
    icon: 'fh-icon fh-icon-email-setting',
    label: 'Email',
    //disabled: true,
  },

  { id: 7, icon: 'fh-icon fh-icon-number', label: 'Number' },
  {
    id: 8,
    icon: 'fh-icon fh-icon-circle-star',
    label: 'Rating',
    //disabled: true,
  },
];

export const actionsBtn = [
  { id: 1, icon: 'fh-icon fh-icon-edit', btnName: 'edit' },

  { id: 2, icon: 'fh-icon fh-icon-delete', btnName: 'delete' },
];

export const optionActionButton = [
  { id: 1, icon: 'fh-icon fh-icon-circle-plus', btnName: 'add' },

  { id: 2, icon: 'fh-icon fh-icon-delete', btnName: 'delete' },
];

export const RatingFormat = [
  { id: 1, icon: 'fh-icon fh-icon-star', label: 'Star' },

  { id: 2, icon: 'fh-icon fh-icon-happy-outline', label: 'Smiley' },

  { id: 3, icon: 'fh-icon fh-icon-circle', label: 'Radio Button' },
];

export const Count = [
  { id: 1, label: '3' },

  { id: 2, label: '4' },

  { id: 3, label: '5' },
];

export const colorArray3 = ['#D84945', '#D89E45', '#166A59'];

export const colorArray4 = ['#D84945', '#D86445', '#D89E45', '#166A59'];

export const colorArray5 = [
  '#D84945',
  '#D86445',
  '#D89E45',
  '#45D853',
  '#166A59',
];

export const smileyArray3 = [
  'fh-icon-angry-filled',
  'fh-icon-neutral-filled',
  'fh-icon-happy-filled',
];
export const smileyArrayOutline3 = [
  'fh-icon-angry-outline',
  'fh-icon-neutral-outline',
  'fh-icon-happy-outline',
];

export const smileyArray4 = [
  'fh-icon-angry-filled',
  'fh-icon-sad-filled',
  'fh-icon-neutral-filled',
  'fh-icon-happy-filled',
];
export const smileyArrayOutline4 = [
  'fh-icon-angry-outline',
  'fh-icon-sad-outline',
  'fh-icon-neutral-outline',
  'fh-icon-happy-outline',
];

export const smileyArray5 = [
  'fh-icon-angry-filled',
  'fh-icon-sad-filled',
  'fh-icon-neutral-filled',
  'fh-icon-smile-filled',
  'fh-icon-happy-filled',
];
export const smileyArrayOutline5 = [
  'fh-icon-angry-outline',
  'fh-icon-sad-outline',
  'fh-icon-neutral-outline',
  'fh-icon-smile-outline',
  'fh-icon-happy-outline',
];
