import React from "react";
import useStyles from "./Styles";
import {
  Dialog,
  // Typography,
  // IconButton,
  Box,
} from "@mui/material";

const Overlay = (props) => {
  const {
    open,
    onClose,
    image,
    //  title,
    video,
    document,
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      // maxWidth="lg"
      fullWidth
      className={classes.dialog}
      title="Press Esc to close the preview"
      sx={{
        "& .MuiDialog-paper":{
          backgroundColor:'transparent',
          width:'inherit',
        }
      }}
    >
      <Box className={classes.overlay}>
        {/* <Box className={classes.displayFile}> */}
          {image && (
            
              <img src={image} alt="" className={classes.image} />
            
          )}
          {video && (
            <video
              controls
              className={classes.videoOverlay}
              controlsList="nodownload"
            >
              <source src={video} type="video/mp4" />
            </video>
          )}
          {document && (
            <iframe
              src={document}
              title="Document Preview"
              width="100%"
              height="100%"
            ></iframe>
          )}
          <Box className={classes.innerOverlay}>
            {/* {title && (
              <Typography variant="h6" className={classes.title}>
                {title}
              </Typography>
            )} */}
            {/* <i className={`${classes.closeButton} fh-icon fh-icon-close-circle`} onClick={onClose} /> */}
          </Box>
        </Box>
      {/* </Box> */}
    </Dialog>
  );
};

export default Overlay;
