import { Box, Divider, Grid, Icon, Typography } from "@mui/material";
import React from "react";
import CustomSelect from "../../../../components/common/CustomSelect/CustomSelect";
import { Common } from "../../../../components/common/Common";

const TextStyles = ({ options, values, onChange }) => {
  // console.log("values>>>>", values);
  return (
    <Common.Accordion
      variant="body3"
      title={
        <Typography
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body3"
            sx={{
              padding: "8px 10px",
              backgroundColor: "#E4E7FE",
              borderRadius: 1,
            }}
          >
            Aa
          </Typography>
          <Typography variant="sh3" sx={{ ml: 2, color: "#333" }}>
            Text Style
          </Typography>
        </Typography>
      }
    >
      <Grid container rowSpacing={3} columnSpacing={2}>
        <Grid item xs={12} m={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography variant="sh3">Title Font</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <CustomSelect
                options={options}
                value={values.titleFontStyle}
                onChange={onChange}
                name="titleFontStyle"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant="sh3">Description Font</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <CustomSelect
                options={options}
                value={values.descriptionFontStyle}
                onChange={onChange}
                name="descriptionFontStyle"
              />
            </Grid>
            {/* <Grid item xs={12} sm={3}>
              <Typography variant="sh3">Section Title Font</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <CustomSelect
                options={options}
                value={values.sectionTitleFontStyle}
                onChange={onChange}
                name="sectionTitleFontStyle"
              />
            </Grid> */}
            <Grid item xs={12} sm={3}>
              <Typography variant="sh3">Question Font</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <CustomSelect
                options={options}
                value={values.questionFontStyle}
                onChange={onChange}
                name="questionFontStyle"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant="sh3">Option / Response Font</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <CustomSelect
                options={options}
                value={values.optionResponseFontStyle}
                onChange={onChange}
                name="optionResponseFontStyle"
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
              <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Thank You
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant="sh3">Header Font</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <CustomSelect
                options={options}
                value={values.headerFontStyle}
                onChange={onChange}
                name="headerFontStyle"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant="sh3">Message Font</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <CustomSelect
                options={options}
                value={values.messageFontStyle}
                onChange={onChange}
                name="messageFontStyle"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={3}>
        <Box
          sx={{
            background: "#EBECF5",
            display: "flex",
            alignItems: "center",
            borderRadius: "0 0 4px 4px",
            p: 1,
          }}
        >
          <Icon className="fh-icon fh-icon-info" />
          <Typography variant="body4" sx={{ ml: 1 }}>
            You will be able to view the updated font style in the 'Preview'
            section
          </Typography>
        </Box>
      </Grid>
    </Common.Accordion>
  );
};

export default TextStyles;
