import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { color } from 'chart.js/helpers';
  import { TreemapController, TreemapElement } from 'chartjs-chart-treemap';
  import { Chart } from 'react-chartjs-2';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TreemapController,
    TreemapElement
  );
  
  
  
  
  
  
  export default function TreeMap(props) {
    const {treeData, treeKey, title, subtitle, color, tooltipTitle, tooltipLabel1, tooltipLabel2,} = props
    //Tree Data
    const data = treeData;

    //TreeMap options
    const options = {
        plugins: {
          legend: {
            display: false,
          },
          
          tooltip: {
            enabled: false,
          },
        },
      };
    
    // TreeMap Configuration
    const config = {
        type: 'treemap',
        data: {
          datasets: [
            {
              tree: data,
              key: treeKey,
                            labels: {
                display: true,
                overflow:'fit',
                color:'white',
                font: [
                  {family: 'Poppins',size: 32, weight: 600}, 
                  {family: 'Poppins',size: 20, weight: 500}
                ],
                formatter: (context) => {
                 return(
                  [`${context.raw._data[title]*100}%`, "" ,context.raw._data[subtitle]]
                )
                }
              },
              backgroundColor(ctx) {
                if (ctx.type !== 'data') {
                  return 'transparent';
                }
                return ctx.raw._data[color];
              },
            },
          ],
        },
      };
    return <Chart type="treemap" data={config.data} options={options} />;
  }
  