export const npsSmileyOutlinedIcons = [
  'fh-icon fh-icon-angry-outline',
  'fh-icon fh-icon-sad-outline-3',
  'fh-icon fh-icon-sad-outline',
  'fh-icon fh-icon-sad-outline-1',
  'fh-icon fh-icon-neutral-outline',
  'fh-icon fh-icon-smile-outline-1',
  'fh-icon fh-icon-smile-outline',
  'fh-icon fh-icon-smile-outline-3',
  'fh-icon fh-icon-happy-outline',
  'fh-icon fh-icon-heart-reaction-outline',
];

export const npsSmileyFilledIcons = [
  'fh-icon fh-icon-angry-filled',
  'fh-icon fh-icon-sad-filled-3',
  'fh-icon fh-icon-sad-filled',
  'fh-icon fh-icon-sad-filled-1',
  'fh-icon fh-icon-neutral-filled',
  'fh-icon fh-icon-smile-filled-1',
  'fh-icon fh-icon-smile-filled',
  'fh-icon fh-icon-smile-filled-3',
  'fh-icon fh-icon-happy-filled',
  'fh-icon fh-icon-heart-reaction-filled',
];
export const colorArray10 = [
  '#D86445',
  '#D86445',
  '#D86445',
  '#D86445',
  '#D86445',
  '#D89E45',
  '#D89E45',
  '#D89E45',
  '#45D853',
  '#45D853',
];
