import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  Icon,
  Link,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Common } from '../../../../components/common/Common';
import useTable from '../../../../components/common/Table/useTable';
import CommonUI from '../../../CommonUI';
// import ReactDateRangePicker from '../../../../components/common/ReactDatePicker/ReactDateRangePicker';
import { useNavigate } from 'react-router-dom';
import { getMethod } from '../../../../api/getMethod';
import { getAccessToken } from '../../auth';
import AutoCompleteTwo from '../../../../components/common/AutoCompleteTwo/AutoCompleteTwo';
import Popup from '../../../../components/common/NewPopup/Popup';
import InputWthActionBtn from '../../../../components/common/InputBoxWthActionButton/InputWthActionBtn';
import { useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
// copy
import copy from 'copy-to-clipboard';
import Footer from '../../../../components/Footer/Footer';
import SurveyUsage from '../SurveyUsage/SurveyUsage';
// import DateRangePicker from '../../../../components/common/ReactDatePicker/DateRangePicker/DateRangePicker';

const url = new URL(window.location.href);
const host = url.host;

const headCells = [
  { id: 'SNo', label: 'S.No' },
  { id: 'title', label: 'Title' },
  { id: 'isPii', label: 'Is PII' },
  { id: 'channel', label: 'Channel' },
  { id: 'lastExecutionDate', label: 'Last Execution Date' },
  { id: 'expiryDate', label: 'Expiry Date' },
  { id: 'status', label: 'Status' },
  { id: 'recipientsCount', label: 'Recipients Count' },
  { id: 'knownResponders', label: 'Known Responders' },
  { id: 'unknownResponders', label: 'Unknown Responders' },
  { id: 'createddate', label: 'Created Date' },
  { id: 'modifieddate', label: 'Last Modified Date' },
  { id: 'action', label: 'Action' },
];
let handleDownload,
  editSurvey,
  copyLink,
  analytics,
  duplicate,
  expiryDate,
  handlePreview;
const actionMenu = [
  {
    value: 'duplicate',
    text: 'Duplicate',
    icon: 'fh-icon fh-icon-copy',
    fun: { duplicate },
  },
  {
    value: 'download',
    text: 'Download Data',
    icon: 'fh-icon fh-icon-download',
    fun: { handleDownload },
  },
  {
    value: 'preview',
    text: 'Preview',
    icon: 'fh-icon fh-icon-eye-open',
    fun: { handlePreview },
  },
  {
    value: 'analytics',
    text: 'Analytics',
    icon: ' fh-icon fh-icon-analytics',
    fun: { analytics },
  },

  {
    value: 'edit',
    text: 'Edit',
    icon: 'fh-icon fh-icon-edit',
    fun: { editSurvey },
  },
  {
    value: 'link',
    text: 'CopyLink',
    icon: 'fh-icon fh-icon-link',
    fun: { copyLink },
  },
  {
    value: 'expiryDate',
    text: 'Change ExpiryDate',
    icon: 'fh-icon fh-icon-calendar',
    fun: { expiryDate },
  },
];

const Summeryreport = () => {
  const [openCopyPopup, setOpenCopyPopup] = useState(false);
  const [copyText, setCopyText] = useState('');
  const [activeStep, setActiveStep] = useState(0);

  const [open, setOpen] = useState(false);

  const inputRef = useRef(null);
  const [selectedSurveyId, setSelectedSurveyId] = useState(null);

  const [dateVal, setDateVal] = useState({
    fromDate: '',
    toDate: '',
  });

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setDateVal({ ...dateVal, [name]: value });
  };

  // console.log(dateVal.fromDate, dateVal.toDate);
  ///download the data
  const getActionHandler =
    (actionFunction, id, survey_code, status, sm_expiry_date) => () => {
      // setSurvey_code(survey_code);
      actionFunction(id, survey_code, status, sm_expiry_date);
    };

  //edit survey
  const editSurvey = (id) => {
    const creationUrl = `/surveycreation?id=${id}&step=${activeStep}`;
    navigate(creationUrl);
  };

  const copyLink = async (id, survey_code) => {
    // setSurveyNewId(id);
    // setSurveyNewCode(survey_code);
    setOpenCopyPopup(true);
    let constructedLink = '';
    if (window.location.hostname === 'localhost') {
      constructedLink = `http://${host}/ClientSurveyView?survey_code=${survey_code}`;
    } else {
      constructedLink = `https://${host}/ClientSurveyView?survey_code=${survey_code}`;
    }
    setCopyText(constructedLink);
  };

  const handleCopySurveyLink = () => {
    copy(copyText);
    toast.success('Link Copied successfully', {
      style: {
        fontSize: 16,
      },
    });
    setOpenCopyPopup(false);
  };

  const expiryDate = (id, survey_code, sm_expiry_date) => {
    navigate(`/surveycreation?id=${id}&step=3`);
  };

  const analytics = (id) => {
    // console.log('Id>>>>', id);
    navigate(`/surveyAnalytics/?id=${id}`);
  };

  const duplicate = (id) => {
    getMethod(
      process.env.REACT_APP_BASE_URL +
        process.env.REACT_APP_DUPLICATE +
        id +
        '/',
      token
    )
      .then((res) => {
        toast.success('Survey Duplicated Successfully', {
          style: {
            fontSize: 16,
          },
        });
        getHomeData();
      })
      .catch((err) => {
        const errorMessages = Object.values(err.response.data.errors).flatMap(
          (errors) => errors
        );
        const errorMessage = errorMessages.join(' ');
        toast.error(errorMessage, {
          style: {
            fontSize: 16,
          },
        });
      });
  };

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  // handlePreview
  const handlePreview = (id) => {
    navigate(`/surveycreation?id=${id}&step=2`);
  };
  // download
  const handleDownload = async (id) => {
    await getMethod(
      process.env.REACT_APP_BASE_URL +
        process.env.REACT_APP_PREFIX_URL +
        process.env.REACT_APP_DOWNLOAD_REPORT +
        `?survey_id=${id}`,
      token
    )
      .then((res) => {
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const formattedDate = currentDate.toISOString().slice(0, 10);
        const suggestedFileName = `Output_report_${formattedDate}_${timestamp}.csv`;
        const blob = new Blob([res.data], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = suggestedFileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => console.log(err));
  };

  actionMenu.forEach((item) => {
    if (item.value === 'download' && item.fun) {
      item.fun = handleDownload;
    }
    if (item.value === 'link' && item.fun) {
      item.fun = copyLink;
    }
    if (item.value === 'edit' && item.fun) {
      item.fun = editSurvey;
    }
    if (item.value === 'duplicate' && item.fun) {
      item.fun = duplicate;
    }
    if (item.value === 'analytics' && item.fun) {
      item.fun = analytics;
    }
    if (item.value === 'expiryDate' && item.fun) {
      item.fun = expiryDate;
    }
    if (item.value === 'preview' && item.fun) {
      item.fun = handlePreview;
    }
  });

  const [tableData, setTableData] = useState([]);
  ///filter of channels
  const [SurveyName, setSurveyName] = useState('');
  // Date Range Picker
  // const [rangeStartDate, setRangeStartDate] = useState();
  // const [rangeEndDate, setRangeEndDate] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [serialNumber, setSerialNumber] = useState(1);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const token = getAccessToken();
  const getHomeData = async () => {
    setLoading(true);

    getMethod(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_SURVEY_ALL,
      token
    )
      .then((res) => {
        if (res.data) {
          const datas = Object.keys(res.data.data).map((key) => ({
            key,
            value: res.data.data[key],
          }));
          setTableData(datas);
          const searchTitle = datas.map((item) => item.value.sm_title);
          setSearchResults(searchTitle);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })

      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getHomeData();
  }, [token]);

  const searchFilter = async (survey, rangeStartDate, rangeEndDate) => {
    let url = '';
    const date = new Date(rangeStartDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-based
    const day = String(date.getDate()).padStart(2, '0');

    const startDate = `${year}-${month}-${day}`;

    const endDate = new Date(rangeEndDate);
    const year1 = endDate.getFullYear();
    const month1 = String(endDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-based
    const day1 = String(endDate.getDate()).padStart(2, '0');

    const toDate = `${year1}-${month1}-${day1}`;
    if (survey) {
      url += `?title=${survey}`;
    }
    if (rangeStartDate && rangeEndDate) {
      // const startDate = rangeStartDate.toISOString().split('T')[0];
      // const endDate = rangeEndDate.toISOString().split('T')[0];
      url += url
        ? `&from_date=${rangeStartDate}&to_date=${rangeEndDate}`
        : `?from_date=${rangeStartDate}&to_date=${rangeEndDate}`;
    }
    setLoading(true);
    getMethod(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_SURVEY_ALL + url,
      token
    )
      .then((res) => {
        if (res.data) {
          const datas = Object.keys(res.data.data).map((key) => ({
            key,
            value: res.data.data[key],
          }));
          setTableData(datas);
          // console.log('List', datas);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })

      .catch((err) => {
        setLoading(false);
      });
  };
  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    tableData,
    headCells,
    filterFn
  );

  const handleChannelUsage = (surveyId) => {
    setOpen(true);
    setSelectedSurveyId(surveyId);
    // console.log('SurveyId>>>>>', surveyId);
  };

  return (
    <CommonUI headertext="Manage Survey ">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <Typography variant="sh3">From Date</Typography>
          {/* <ReactDateRangePicker
            inline="false"
            rangeStartDate={rangeStartDate}
            setRangeStartDate={setRangeStartDate}
            rangeEndDate={rangeEndDate}
            setRangeEndDate={setRangeEndDate}
          /> */}
          {/* <DateRangePicker /> */}
          <TextField
            type="date"
            fullWidth
            size="small"
            name="fromDate"
            value={dateVal.fromDate}
            onChange={handleDateChange}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="sh3">To Date</Typography>
          {/* <ReactDateRangePicker
            inline="false"
            rangeStartDate={rangeStartDate}
            setRangeStartDate={setRangeStartDate}
            rangeEndDate={rangeEndDate}
            setRangeEndDate={setRangeEndDate}
          /> */}
          <TextField
            type="date"
            fullWidth
            size="small"
            name="toDate"
            value={dateVal.toDate}
            onChange={handleDateChange}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant="sh3">Search</Typography>
          <AutoCompleteTwo
            disablePortal
            placeholder="Select Survey"
            id="surveyname"
            options={searchResults}
            value={SurveyName}
            onChange={(event, newValue) => setSurveyName(newValue)}
            size="small"
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          container
          spacing={3}
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <Grid item xs={6} sm={6}>
            <Common.Button
              variant="contained"
              color="primary"
              size="small"
              endIcon={<span className="fh-icon fh-icon-arrow-right"></span>}
              onClick={() =>
                searchFilter(SurveyName, dateVal.fromDate, dateVal.toDate)
              }
              fullWidth
            >
              Search
            </Common.Button>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Common.Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<Icon className="fh-icon fh-icon-plus" />}
              onClick={() => navigate('/surveycreation')}
              fullWidth
            >
              Create Survey
            </Common.Button>
          </Grid>
        </Grid>
      </Grid>

      {loading === true ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress size={72} thickness={3} />
        </Box>
      ) : (
        <Grid container>
          <Grid item xs={12} mt={5}>
            {tableData.length === 0 ? (
              <Box sx={{ height: '70vh' }}>
                <Typography variant="body1" align="center" mt={4} mb={4}>
                  No survey available for selected filters
                </Typography>
              </Box>
            ) : (
              <TblContainer>
                <TblHead variant="sh5" />
                <TableBody>
                  {recordsAfterPagingAndSorting().map((item, index) => (
                    <TableRow key={item.value.sm_pk_id}>
                      <TableCell>{serialNumber + index}</TableCell>
                      <TableCell>{item.value.sm_title}</TableCell>
                      <TableCell>
                        {item.value.survey_ispii === 1 ? 'Yes' : 'No'}
                      </TableCell>
                      <TableCell>
                        {item.value.camptypes && (
                          <Link
                            sx={{ cursor: 'pointer', color: '#161E6A' }}
                            underline="hover"
                            onClick={() =>
                              handleChannelUsage(item.value.sm_pk_id)
                            }
                          >
                            <Typography
                              variant="body3"
                              sx={{ fontSize: '1em' }}
                            >
                              {item.value.camptypes}
                            </Typography>
                          </Link>
                        )}
                      </TableCell>
                      <TableCell>{item.value.last_executed}</TableCell>
                      <TableCell>{item.value.sm_expiry_date}</TableCell>
                      <TableCell sx={{ textTransform: 'capitalize' }}>
                        {item.value.sm_status}
                      </TableCell>
                      <TableCell>{item.value.recipientsCount}</TableCell>
                      <TableCell>{item.value.known_responders}</TableCell>
                      <TableCell>{item.value.unknown_responders}</TableCell>
                      <TableCell>{item.value.fh_created_date}</TableCell>
                      <TableCell>{item.value.fh_updated_date}</TableCell>
                      <TableCell>
                        <Common.ActionMenu
                          actions={actionMenu
                            .filter((actionItem) => {
                              // Check if the status is "draft" and the action is "analytics download" or "copy link"
                              if (
                                item.value.sm_status === 'draft' &&
                                (actionItem.value === 'analytics' ||
                                  actionItem.value === 'download' ||
                                  actionItem.value === 'link' ||
                                  actionItem.value === 'expiryDate' ||
                                  actionItem.value === 'preview')
                              ) {
                                return false; // Don't include the action
                              }
                              // // Preserve the original condition for "edit" and "expired"
                              if (
                                item.value.sm_status === 'Expired' &&
                                (actionItem.value === 'edit' ||
                                  actionItem.value === 'link')
                              ) {
                                return false; // Don't include the action
                              }
                              if (
                                actionItem.value === 'edit' &&
                                item.value.sm_status === 'Active'
                              ) {
                                return false; // Don't include the action
                              }
                              if (
                                item.value.sm_status ===
                                  'ready for execution' &&
                                (actionItem.value === 'analytics' ||
                                  actionItem.value === 'download' ||
                                  actionItem.value === 'link')
                              ) {
                                return false; // Don't include the action
                              }
                              return true; // Include all other actions
                            })
                            .map((actionItem) => ({
                              ...actionItem,
                              fun: getActionHandler(
                                actionItem.fun,
                                item.value.sm_pk_id,
                                item.value.survey_code,
                                item.value.sm_status,
                                item.value.sm_expiry_date
                              ),
                            }))}
                          vertical={-5}
                          horizontal={0}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </TblContainer>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'end', mt: 3, mb: 3 }}>
              <Footer />
            </Box>
          </Grid>
        </Grid>
      )}
      <Popup openPopup={openCopyPopup} setOpenPopup={setOpenCopyPopup}>
        <Box sx={{ p: 3 }}>
          <InputWthActionBtn
            direction="column"
            id="copyCode"
            inputRef={inputRef}
            value={copyText}
            size="small"
            endAdornment="fh-icon fh-icon-copy"
            onClick={handleCopySurveyLink}
            disabled={true}
          />
        </Box>
      </Popup>
      <SurveyUsage
        open={open}
        setOpen={setOpen}
        selectedSurveyId={selectedSurveyId}
      />
      <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
    </CommonUI>
  );
};

export default Summeryreport;
