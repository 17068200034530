import { makeStyles } from "@mui/styles";
export default makeStyles(theme=>({
    customTextField: {
        '& .MuiInputBase-sizeSmall': {
            width: '100%',
            // border:'1px solid red !important',
            padding: '0px !important',
        },
        "& input": {
            fontSize: "12px !important",
            // color:'#000 !important',
            fontWeight: '500',
            padding: '12px !important'
        },
        "& input::placeholder": {
            fontSize: "12px",
            // color:'rgb(51,51,51) !important',
            fontWeight: '400'
        },
    },
    noOptions: {
        fontSize: '12px !important',
        fontFamily: 'Poppins',
        fontWeight: '500 !important',
        // color:'#000'
    },
    gpsIcon: {
        position: "absolute",
        borderLeftColor: "3%"
      },
      input: {
        "& > label": {
          paddingLeft: "5em !important"
        },
        "& > div > input": {
          paddingLeft: "9em !important"
        }
      }
}));