import React from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function LineChart(props) {
  const { datasets, labels, aspectRatio, fontColor, gridColor } = props;

  const canvasBackgroundColor = {
    id: 'canvasBackgroundColor',
    beforeDraw(chart,args,pluginOptions){
      // console.log(chart);
    }
  }

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: aspectRatio,

    elements: {
      line: {
        borderWidth: 2,
      },
    },

    plugins: {
      
      legend: {
        position: "right",
        labels: {
          color: fontColor,
          usePointStyle: true,
          pointStyle: "rect",
          align: "end",
          font: {
            family: "poppins",
            size: 12,
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          color: fontColor,
          font: {
            family: "poppins",
            size: 12,
            weight: 400,
          },
        },
      },
      y: {
        grid:{
          color: gridColor,
          lineWidth: 0.5,
        },
        border: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          color: fontColor,
          font: {
            family: "poppins",
            size: 12,
            weight: 400,
          },
        },
      },
    },
  };

  const data = {
    labels,
    datasets: datasets,
  };
  return (
    <Box>
      <Box
        sx={{
          marginBottom: "20px",
        }}
      ></Box>
      <Line options={options} data={data} plugins={canvasBackgroundColor}/>
    </Box>
  );
}
