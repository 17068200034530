import React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Typography,
} from '@mui/material';
import FontsForm from './FontsForm/FontsForm';
import { styled } from '@mui/styles';

const FontModal = ({ openPopup, setOpenPopup, fetchData }) => {
  const handleClose = () => {
    setOpenPopup(false);
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openPopup}
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle id="customized-dialog-title">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="sh2"
              sx={{
                background: '#e4e7fe',
                p: '0.5rem 1rem',
                borderRadius: '0.5rem',
                mr: 1,
              }}
            >
              Aa
            </Typography>
            <Typography variant="sh2">Custom Font</Typography>
          </Box>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon className="fh-icon fh-icon-close" sx={{ fontSize: '1.2rem' }} />
        </IconButton>
        <DialogContent>
          <FontsForm fetchData={fetchData} setOpenPopup={setOpenPopup} />
        </DialogContent>
        {/* <DialogActions>
          <Common.Button autoFocus onClick={handleClose}>
            Save changes
          </Common.Button>
        </DialogActions> */}
      </BootstrapDialog>
    </>
  );
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(4),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(4),
  },
}));

export default FontModal;
