import React from "react";
import {
  Accordion as MuiAccordian,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
  Icon,
} from "@mui/material";
import useStyles from "./styles";

export default function Accordion(props) {
  const { title, children, variant } = props;
  const classes = useStyles();
  return (
    <>
      {/* <Box className={classes.root}> */}
        <MuiAccordian>
          <AccordionSummary
            expandIcon={<Icon className="fh-icon fh-icon-down" sx={{fontSize:20,color:'#333'}}/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
            //   className={classes.heading}
              variant={variant || "body1"}
            >
              {title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>{children}</AccordionDetails>
        </MuiAccordian>
      {/* </Box> */}
    </>
  );
}
