import { makeStyles } from "@mui/styles";
export default makeStyles(theme=>({
    root: {
        "& .MuiOutlinedInput-root": {
            padding: '5px !important',
            "& > fieldset": {
                // border:'none',
                // borderColor: localStorage.getItem('theme') === 'light' ? `${theme.palette.grey.light} !important` : ``,  
            },
            '&.Mui-disabled': {
                // background: localStorage.getItem('theme') === 'light' ? `${theme.palette.lightBgBlue.light} !important` : `${theme.palette.onSurface.main} !important`,
                borderRadius: '4px',
                border:'none !important'
            },
        },

        '& .MuiFormHelperText-root': {
            margin: '0px !important'
        },
        '& input': {
            fontSize: '160px',
            paddingRight: '12px'
        },
        '& input:placeholder': {
            fontSize: '18px !important',
            color:'#f00 !important'
        },
    },
    input:{
        fontSize: '16px !important',
        // padding:'8px 12px !important',
        '&::-webkit-scrollbar-track': {
            background: 'none'
            // background:'red',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#C6C6C6',
            borderRadius: '10px',
        },
        '&::-webkit-scrollbar': {
            width: '4px',
        },
    },
    inputLabel:(props)=>({
        // minWidth:'65px',
        marginRight:props.direction==='row'?'10px':'',
        fontSize:'18px !important',
        color:'#000 !important',
        textTransform:'capitalize'

    })
}));