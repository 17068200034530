import { Autocomplete, Box, Chip, TextField, Typography } from '@mui/material';
import React, { useState } from 'react'
import useStyles from './styles';

const ReusableTags = (props) => {
    const { label, deletable,flexdirection } = props;
    const classes = useStyles();
    const [chips, setChips] = useState([]);
    // console.log({ chips })

    const handleDeleteChip = (chipToDelete) => {
        setChips((chips) => chips.filter((chip) => chip !== chipToDelete));
    };
    return (
        <React.Fragment>
            <Box sx={{display:'flex',flexDirection:`${flexdirection||'row'}`}}>
            <Typography variant='body3' className={classes.tagLabel}>{label}</Typography>
            <Autocomplete
                fullWidth
                multiple
                freeSolo
                options={[]}
                value={chips}
                onChange={(event, newValue) => {
                    setChips(newValue);
                }}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        deletable?
                            <Chip
                            key = { index }
                            label = { option }
                            onDelete = { deletable ? () => handleDeleteChip(option) : ""}
                            deleteIcon={deletable && <i className={`fh-icon fh-icon-close ${classes.deleteIcon}`}/>}
                            className={classes.tag}
                            /> : <Chip
                                key={index}
                                label={option}
                                className={classes.tag}
                            />
                        
                    ))
                }
                renderInput={(params) => ( 
                    <TextField
                        classes={{
                            root: classes.root,
                        }}
                        {...params}
                        variant="outlined"
                        placeholder="Enter Content Tag"
                    />
                )}
            />
            </Box>
        </React.Fragment>
    );
}

export default ReusableTags
