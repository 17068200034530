import React, { useState } from 'react';
import {Chip,Box} from '@mui/material';
import { Common } from '../Common';
import useStyles from './styles';

const trackContent = new Set();

const Tags = (props) => {

  const {tagWidth, deletable} = props
  const classes = useStyles();

  const [value, setValue] = useState('');
  const [tagContent, setTagContent] = useState([]);

  const handleInputChange = (e) => {
    setValue(e.target.value);
  }

  const tagFormation = (e) => {
    if (e.code === "Enter") {
      if (value !== "" && value !== "\n" && !trackContent.has(value)) {
        if (trackContent.size === 0) {
          trackContent.add("\n" + value);
          setTagContent([...tagContent, value]);
        } else {
          trackContent.add(value);
          setTagContent([...tagContent, value]);
        }
      }
      setValue("");
    }
}
  const handleClose =(value)=>{
    setTagContent((tagContent) =>
      tagContent.filter((data) => data !== value)
    );
    trackContent.delete(value);
  }
  return (
    <Box className={classes.tagContainer}>
        {tagContent.length !== 0 ? tagContent.map((value, index) => {
          return (
            <Chip
              key={index}
              className={classes.tag}
              onDelete={props.deletable ? () => handleClose(value) : ""}
              deleteIcon={props.deletable ?<span className={`fh-icon fh-icon-close ${classes.deleteIcon}`}></span>: ""}
              label={value}
            />
          );
        }):''}

      <Common.InputField
        value={value}
        InputProps={{ disableUnderline: true }}
        onChange={handleInputChange}
        onKeyDown={tagFormation}
        variant="standard"
        placeholder="Press Enter to add tag"
        multiline
      />
    </Box>
  );
};
export default Tags;
