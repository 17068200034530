import { Autocomplete, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
const useStyles = makeStyles((theme) => ({
  option: {
    fontSize: "1rem", // Set your desired font size here
    fontFamily: "Poppins !important",
    fontWeight: "500",
    color: "#333",
  },
}));

const CustomizeAutocomplete = ({
  options,
  label,
  defaultValue,
  placeholder,
  onChange,
  id,
  name,
  size,
}) => {
  const classes = useStyles();
  const getOptionLabel = (option) => option.label || ""; // Handle the case where label is undefined

  const getOptionSelected = (option, value) => {
    if (value === "") {
      // Handle empty value as a special case
      return true;
    }
    return option.value === value.value; // Assuming each option has a 'value' property
  };

  // console.log("Options:", options);
  // console.log("Default Value:", defaultValue);
  return (
    <>
      <Typography
        variant="sh3"
        sx={{ color: "#333", mb: 1, display: "inline-block" }}
      >
        {label}
      </Typography>
      <Autocomplete
        componentsProps={{
          paper: {
            sx: {
              margin: "auto",
              p: 0.5,
              border: "1px solid #C6C6C6",
              "& ::-webkit-scrollbar": {
                width: "4px",
              },
              "& ::-webkit-scrollbar-track": {
                background: "#e8e8e8",
              },
              "& ::-webkit-scrollbar-thumb": {
                background: "#C6C6C6",
                borderRadius: "6px",
              },
              "& ::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            },
          },
        }}
        sx={{
          "& .MuiAutocomplete-clearIndicator": {
            display: "none",
          },
          "& .MuiAutocomplete-input": {
            padding: "3px 5px !important",
          },
        }}
        options={options}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={getOptionSelected}
        // isOptionEqualToValue={(option, value) => option.value === value.value}
        id={id}
        size={size}
        renderInput={(params) => (
          <TextField
            name={name}
            //    label="Choose an option"
            placeholder={placeholder}
            variant="outlined"
            size={size}
            {...params}
          />
        )}
        value={defaultValue}
        onChange={(event, newValue) => onChange(newValue)}
        classes={{
          option: classes.option, // Apply the custom font size here
        }}
      />
    </>
  );
};

export default CustomizeAutocomplete;
