import * as React from "react";
import { Button as MuiButton } from "@mui/material";
// import useStyles from './styles';

export default function Button(props) {
  // const classes= useStyles();
  const {
    children,
    size,
    color,
    variant = "contained",
    type,
    onClick,
    ...other
  } = props;
  return (
    <MuiButton
      size={size}
      variant={variant}
      onClick={onClick}
      type={type}
      {...other}
      disableRipple
      color={color}
    >
      {children}
    </MuiButton>
  );
}
