import React, { useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  Icon,
  Typography,
  FormHelperText,
} from '@mui/material';
import {
  colorArray3,
  colorArray4,
  colorArray5,
  smileyArray3,
  smileyArray4,
  smileyArray5,
  smileyArrayOutline3,
  smileyArrayOutline4,
  smileyArrayOutline5,
} from './ratingutility';

const RatingEmoji = ({
  options,
  ratingStyle,
  handleClickedRatingStar,
  selectedId,
  optionsFontStyle,
  onClick,
  errorMessage,
}) => {
  const [feelingsArray, setFeelingsArray] = useState([]);

  useEffect(() => {
    let count = options.length;
    const newFeelingArray = options.map((option, index) => ({
      id: option.opt_pk_id,
      title: option.opt_label,
      color:
        count === 3
          ? colorArray3[index]
          : count === 4
          ? colorArray4[index]
          : colorArray5[index],
      icon:
        count === 3
          ? smileyArray3[index]
          : count === 4
          ? smileyArray4[index]
          : smileyArray5[index],
      iconOutline:
        count === 3
          ? smileyArrayOutline3[index]
          : count === 4
          ? smileyArrayOutline4[index]
          : smileyArrayOutline5[index],
    }));

    setFeelingsArray(newFeelingArray);
  }, [options]);

  return (
    <>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {feelingsArray.length !== 0 ? (
          ratingStyle === 2 ? (
            feelingsArray.map((emoji, index) => (
              <Box
                key={emoji.id}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mr: 2,
                }}
              >
                <IconButton
                  sx={{
                    display: 'flex',
                    direction: 'column',
                  }}
                  onClick={() => onClick(emoji.id)}
                  disableRipple
                >
                  <Icon
                    // sx={{
                    //   color: selectedId
                    //     ? selectedId === emoji.id
                    //       ? `${emoji.color}`
                    //       : `#C6C6C6`
                    //     : `#C6C6C6`,
                    //   fontSize: '2rem',
                    // }}
                    sx={{
                      color: selectedId
                        ? selectedId === emoji.id
                          ? `${emoji.color}`
                          : `#C6C6C6`
                        : `${emoji.color}`,
                      fontSize: '2rem',
                    }}
                    className={
                      selectedId
                        ? selectedId === emoji.id
                          ? `fh-icon ${emoji.icon}`
                          : `fh-icon ${emoji.iconOutline}`
                        : `fh-icon ${emoji.icon}`
                    }
                  />
                </IconButton>
                <Typography
                  variant="body3"
                  sx={{
                    color: '#333',
                    fontFamily: optionsFontStyle
                      ? `${optionsFontStyle} !important`
                      : 'Poppins',
                    // fontSize: optionsFontSize
                    //   ? `${optionsFontSize}px !important`
                    //   : '1rem',
                  }}
                >
                  {emoji.title}
                </Typography>
              </Box>
            ))
          ) : ratingStyle === 5 ? (
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <Box mt={1} mr={1.7}>
                <Typography
                  variant="body3"
                  sx={{
                    fontFamily: optionsFontStyle
                      ? `${optionsFontStyle} !important`
                      : 'Poppins',
                  }}
                >
                  {feelingsArray[0].title}
                </Typography>
              </Box>

              {feelingsArray.map((emoji, index) => (
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', mr: 2 }}
                  key={emoji.id}
                >
                  <IconButton
                    sx={{ display: 'flex', direction: 'column' }}
                    onClick={() => onClick(emoji.id)}
                    disableRipple
                  >
                    <Icon
                      sx={{
                        color: selectedId
                          ? selectedId === emoji.id
                            ? `${emoji.color}`
                            : `#C6C6C6`
                          : `${emoji.color}`,
                        fontSize: '2rem',
                      }}
                      className={
                        selectedId
                          ? selectedId === emoji.id
                            ? `fh-icon ${emoji.icon}`
                            : `fh-icon ${emoji.iconOutline}`
                          : `fh-icon ${emoji.icon}`
                      }
                    />
                  </IconButton>
                </Box>
              ))}
              <Box mt={1}>
                <Typography
                  variant="body3"
                  sx={{
                    fontFamily: optionsFontStyle
                      ? `${optionsFontStyle} !important`
                      : 'Poppins',
                  }}
                >
                  {feelingsArray[feelingsArray.length - 1].title}
                </Typography>
              </Box>
            </Box>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </Box>

      {errorMessage && (
        <FormHelperText
          variant="body4"
          sx={{
            color: '#d32f2f',
            // mt: 2,
          }}
        >
          {errorMessage}
        </FormHelperText>
      )}
    </>
  );
};

export default RatingEmoji;
