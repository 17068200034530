import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Common } from '../../../../../components/common/Common';
import { toast, ToastContainer } from 'react-toastify';
import Fileuploader from '../../../../../components/common/FileUploader/FileUploader';
import { getAccessToken } from '../../../auth';
import { postMethodFile } from '../../../../../api/postMethodFile';

const FontsForm = ({ fetchData, setOpenPopup }) => {
  const token = getAccessToken();

  const initialFieldValues = {
    fontLink: '',
    isAgree: false,
  };

  const [selectedFontFile, setSelectedFontFile] = useState(null);
  const [dropzoneDisabled, setDropzoneDisabled] = useState(false);
  const [formValue, setFormValue] = useState(initialFieldValues);
  const [fileError, setFileError] = useState('');
  const [linkError, setLinkError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false); // Added state for button disable

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;

    // Clear the other field if one is being filled
    // if (name === 'fontLink' && formValue.fontLink) {
    //   setSelectedFontFile(null);
    //   setDropzoneDisabled(true);
    // }

    setFormValue({
      ...formValue,
      [name]: inputValue,
    });
  };

  const handleApply = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable the button when form is submitted

    // Validation: Ensure only one of file or link is provided
    if (selectedFontFile && formValue.fontLink) {
      setFileError('Please provide either a file or a link, not both.');
      setLinkError('Please provide either a file or a link, not both.');
      setIsSubmitting(false); // Re-enable the button on error
      return;
    }

    if (!selectedFontFile && !formValue.fontLink) {
      setFileError('Please provide a file or a link.');
      setLinkError('Please provide a file or a link.');
      setIsSubmitting(false); // Re-enable the button on error
      return;
    }

    // Validation: Ensure fontLink starts with https://
    if (formValue.fontLink && !formValue.fontLink.startsWith('https://')) {
      setLinkError('URL must start with https://');
      setIsSubmitting(false); // Re-enable the button on error
      return;
    }

    // Reset errors if validation passes
    setFileError('');
    setLinkError('');

    const formData = new FormData();
    formData.append('fs_font_file_rights', 1);

    if (selectedFontFile) {
      formData.append('fs_font_file', selectedFontFile);
      formData.append('fs_font_file_type', 1);
    } else {
      formData.append('fontLink', formValue.fontLink);
      formData.append('fs_font_file_type', 0);
    }

    if (formValue.isAgree) {
      try {
        const res = await postMethodFile(
          process.env.REACT_APP_BASE_URL +
            process.env.REACT_APP_PREFIX_URL +
            'upload-font/',
          token,
          formData
        );

        if (res.data) {
          toast.success('Font uploaded Successfully', {
            style: {
              fontSize: '0.875rem',
              textTransform: 'capitalize',
            },
          });
          setSelectedFontFile(null);
          setFormValue(initialFieldValues);
          fetchData();
          setOpenPopup(false); // Close the popup if needed
        }
      } catch (err) {
        console.error('Error>>>', err);
        toast.error(err.response?.data?.errors || 'An error occurred', {
          style: {
            fontSize: '0.875rem',
            textTransform: 'capitalize',
          },
        });
        setIsSubmitting(false); // Re-enable the button on error
      }
    }
  };

  return (
    <form onSubmit={handleApply}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Fileuploader
                minFileSize={0}
                fileTitle="Upload Font"
                documentSubtitle="Drag/Upload"
                documentSubcontent="If any conditions like .ttf,.otf,.woff,.woff2"
                selectedFile={selectedFontFile}
                setSelectedFile={(file) => {
                  setSelectedFontFile(file);
                  setFormValue({ ...formValue, fontLink: '' }); // Clear link if file is selected
                  setDropzoneDisabled(false);
                }}
                document_type="file"
                file_type={{
                  'font/ttf': ['.ttf'],
                  'font/otf': ['.otf'],
                  'font/woff': ['.woff'],
                  'font/woff2': ['.woff2'],
                }}
                dropzoneDisabled={formValue.fontLink ? true : dropzoneDisabled}
                setDropzoneDisabled={setDropzoneDisabled}
                actionMenuItems={[
                  {
                    value: 'remove',
                    text: 'Remove',
                    fun: () => {
                      setSelectedFontFile(null);
                      setDropzoneDisabled(false);
                    },
                  },
                ]}
              />
              {fileError && (
                <Typography style={{ color: '#d32f2f', fontSize: '0.75rem' }}>
                  {fileError}
                </Typography>
              )}
            </Grid>
            {/* <Grid item xs={12} mt={2} mb={2}>
              <Divider>
                <Typography variant="body3">OR</Typography>
              </Divider>
            </Grid> */}
            {/* <Grid item xs={12} md={12}>
              <TextField
                disabled={selectedFontFile && true}
                fullWidth
                size="small"
                name="fontLink"
                placeholder="Enter link https://"
                value={formValue.fontLink}
                onChange={handleInputChange}
                error={!!linkError}
              />
              {linkError && (
                <Typography style={{ color: '#d32f2f', fontSize: '0.75rem' }}>
                  {linkError}
                </Typography>
              )}
            </Grid> */}
          </Grid>
        </Grid>
        <Grid item xs={12} mt={2}>
          <FormControlLabel
            control={
              <Checkbox
                name="isAgree"
                checked={formValue.isAgree}
                onChange={handleInputChange}
              />
            }
            label="I hereby confirm that I have all rights to use this font commercially."
          />
        </Grid>
        <Grid item xs={12} mt={2} display={'flex'} justifyContent={'center'}>
          <Common.Button
            type="submit"
            size="small"
            // disabled={
            //   isSubmitting ||
            //   !(formValue.isAgree && (selectedFontFile || formValue.fontLink))
            // }
            disabled={isSubmitting || !(formValue.isAgree && selectedFontFile)}
          >
            Upload
          </Common.Button>
        </Grid>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar
        />
      </Grid>
    </form>
  );
};

export default FontsForm;
