import React, { useState } from "react";
import useStyles from "./styles";
import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import { Common } from "../Common";
const Dialog = (props) => {
  const classes = useStyles();
  const {
    open,
    onClose,
    title,
    subtitle,
    description,
    text,
    image,
    video,
    document,
    buttonOne,
    buttonTwo,
    maxWidth,
    fullScreen,
    Transition,
    fields,
  } = props;

  const [formValues, setFormValues] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submit logic here
  };

  const handleChange = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  return (
    <MuiDialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      TransitionComponent={Transition}
      classes={{ paper: classes.dialog }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ flexBasis: 540, textAlign: "center" }}>
          {title && <DialogTitle variant="h1">{title}</DialogTitle>}
          {subtitle && (
            <DialogTitle sx={{ fontSize: "12px", p: 0 }}>
              {subtitle}/{description}
            </DialogTitle>
          )}
        </Box>
        <Box>
          <IconButton onClick={onClose}>
            <i className="fh-icon fh-icon-close" />
          </IconButton>
        </Box>
      </Box>
      <DialogContent>
        {image && <img src={image} alt="dialog-image" />}
        {video && <video src={video} controls width="100%" height={180} />}
        {document && (
          <iframe
            src={document}
            title="Document Preview"
            width="100%"
            height={500}
          ></iframe>
        )}
        <DialogContentText variant="body1">{text}</DialogContentText>
        <form onSubmit={handleSubmit}>
          {fields.map((field) => (
            <Common.InputField
              key={field.name}
              label={field.label}
              name={field.name}
              value={formValues[field.name] || ""}
              onChange={handleChange}
              // error = { errors.fullName }
            />
          ))}
        </form>
      </DialogContent>
      <DialogActions>
        {buttonOne && (
          <Button onClick={onClose} variant="text">
            {buttonOne}
          </Button>
        )}
        {buttonTwo && (
          <Button onClick={onClose} variant="contained">
            {buttonTwo}
          </Button>
        )}
      </DialogActions>
    </MuiDialog>
  );
};
export default Dialog;
