import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import SideBar from '../../components/SideBar/SideBar';
import useStyles from './styles';
import { useLocation } from 'react-router-dom';

export default function Layout({ children, endUsers = true }) {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down('md'));
  const config = isMatch
    ? { width: '98%', '.fh-icon': { fontSize: '24px' }, margin: '0px 5px' }
    : { width: '80%', margin: '0px auto' };
  const config1 = {
    //width: '100%'
    width: 'calc(100% - 244px) !important',
  };
  // Check if the current route is "/ClientSurveyView"
  const hideSidebar =
    location.pathname === '/ClientSurveyView' ||
    location.pathname === '/ClientDetails' ||
    location.pathname === '/ClientResponse';

  return (
    <React.Fragment>
      <Box className={classes.root}>
        {!hideSidebar && <SideBar />} {/* Conditionally render the Sidebar */}
        <Box
          // className={classes.children}
          sx={!hideSidebar ? config1 : config}
        >
          {children}
        </Box>
      </Box>
    </React.Fragment>
  );
}
