import {
  Box,
  IconButton,
  Icon,
  Typography,
  FormHelperText,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { colorArray3, colorArray4, colorArray5 } from './ratingutility';

const RatingStar = ({
  options,
  ratingStyle,
  selectedId,
  optionsFontStyle,
  optionsFontSize,
  onClick,
  errorMessage,
}) => {
  const [starArray, setStarArray] = useState([]);

  useEffect(() => {
    let count = options.length;
    const newStarArray = options.map((option, index) =>
      // console.log('option>>>>', option),
      ({
        id: option.opt_pk_id,
        title: option.opt_label,
        // value: option.opt_text,
        color:
          count === 3
            ? colorArray3[index]
            : count === 4
            ? colorArray4[index]
            : colorArray5[index],
      })
    );
    setStarArray(newStarArray);
  }, [options]);

  return (
    <>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {starArray.length !== 0 ? (
          ratingStyle === 3 ? (
            starArray.map((star) => (
              <Box
                key={star.id}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mr: 2,
                }}
              >
                <IconButton
                  sx={{ display: 'flex', direction: 'column' }}
                  onClick={() => onClick(star.id)}
                  disableRipple
                >
                  <Icon
                    sx={{
                      color: selectedId
                        ? selectedId === star.id
                          ? `${star.color}`
                          : `#C6C6C6`
                        : `#C6C6C6`,
                    }}
                    className={`fh-icon fh-icon-star-filled`}
                  />
                </IconButton>
                <Typography
                  // variant="body3"
                  sx={{
                    color: '#333',
                    fontFamily: optionsFontStyle
                      ? `${optionsFontStyle} !important`
                      : 'Poppins',
                    fontSize: optionsFontSize
                      ? `${optionsFontSize}px !important`
                      : '1rem',
                  }}
                >
                  {star.title}
                </Typography>
              </Box>
            ))
          ) : ratingStyle === 6 ? (
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <Box mt={0.5} mr={1.7}>
                <Typography
                  variant="body3"
                  sx={{
                    fontFamily: optionsFontStyle
                      ? `${optionsFontStyle} !important`
                      : 'Poppins',
                  }}
                >
                  {starArray[0].title}
                </Typography>
              </Box>

              {starArray.map((star, index) => (
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', mr: 2 }}
                  key={star.id}
                >
                  <IconButton
                    sx={{ display: 'flex', direction: 'column' }}
                    onClick={() => onClick(star.id)}
                    disableRipple
                  >
                    <Icon
                      sx={{
                        color: selectedId
                          ? selectedId === star.id
                            ? `${star.color}`
                            : `#C6C6C6`
                          : `#C6C6C6`,
                      }}
                      className={`fh-icon fh-icon-star-filled`}
                    />
                  </IconButton>
                </Box>
              ))}
              <Box mt={0.5}>
                <Typography
                  variant="body3"
                  sx={{
                    fontFamily: optionsFontStyle
                      ? `${optionsFontStyle} !important`
                      : 'Poppins',
                  }}
                >
                  {starArray[starArray.length - 1].title}
                </Typography>
              </Box>
            </Box>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </Box>

      {errorMessage && (
        <FormHelperText
          variant="body4"
          sx={{
            color: '#d32f2f',
            // mt: 2,
          }}
        >
          {errorMessage}
        </FormHelperText>
      )}
    </>
  );
};

export default RatingStar;
