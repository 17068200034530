import React from 'react';
import {
  Box,
  Stepper as MuiStepper,
  Step,
  StepButton,
  StepLabel,
  Icon,
  Typography,
} from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '0px 4px 4px 0px',
    '& .MuiStep-root': {
      width: '35%',
      height: '98px',
      borderTop: '1px solid #c6c6c6',
      borderBottom: '1px solid #c6c6c6',
      borderLeft: '1px solid #c6c6c6',
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .MuiStepLabel-root': {
      flexDirection: 'column',
    },
    '& .MuiStepConnector-root': {
      display: 'none',
      // borderRight: "1px solid #c6c6c6",
    },
  }),
  lastContainer: (props) => ({
    borderRight: '1px solid #c6c6c6',
    // display: "none",
  }),
  // active: {
  //     color: 'red',
  // },
  // completed: {
  //     color: 'green',
  // },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerstate }) => ({
  // color:'#333333',
  // backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  // color: '#fff',
  // width: 50,
  // height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerstate.active && {
    color: '#166A59',
    // backgroundImage:
    //     'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    // boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerstate.completed &&
    {
      // backgroundImage:'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;
  const icons = {
    1: 'fh-icon fh-icon-info',
    2: 'fh-icon fh-icon-question-mark',
    3: 'fh-icon fh-icon-eye-open',
    4: 'fh-icon  fh-icon-link',
  };

  return (
    <ColorlibStepIconRoot
      ownerstate={{ completed, active }}
      className={className}
    >
      <Icon
        className={`${icons[String(props.icon)]}`}
        sx={{ fontSize: 32 }}
      ></Icon>
    </ColorlibStepIconRoot>
  );
}

const Stepper = (props) => {
  const classes = useStyles(props);
  const { steps, activeStep, completed } = props;
  // console.log({ activeStep })

  const isLastStep = () => {
    return activeStep === steps.length - 1;
  };
  const totalSteps = () => {
    return steps.length;
  };

  // const completedSteps = () => {
  //     return Object.keys(completed).length;
  // };
  // const isLastStep = () => {
  //     return activeStep === totalSteps() - 1;
  // };
  // const allStepsCompleted = () => {
  //     return completedSteps() === totalSteps();
  // };
  // const handleNext = () => {
  //     const newActiveStep =
  //         isLastStep() && !allStepsCompleted()
  //             ? // It's the last step, but not all steps have been completed,
  //             // find the first step that has been completed
  //             steps.findIndex((step, i) => !(i in completed))
  //             : activeStep + 1;
  //     setActiveStep(newActiveStep);
  // };

  // const handleBack = () => {
  //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  const handleStep = (step) => () => {
    // setActiveStep(step);
  };
  return (
    <Box>
      <MuiStepper
        nonLinear
        activeStep={activeStep}
        className={`${classes.root} ${classes.active}`}
      >
        {steps.map((label, index) => (
          <Step
            key={index}
            sx={{
              background: activeStep === index ? '#EBF5F3' : '',
              borderRight:
                index !== steps.length - 1 ? '' : '1px solid #c6c6c6',
            }}
          >
            <StepButton
              color="inherit"
              onClick={handleStep(index)}
              sx={{ cursor: 'default' }}
            >
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <Typography
                  variant="sh3"
                  sx={{
                    color: activeStep === index ? '#166A59' : '#333',
                    fontFeatureSettings: 'normal',
                  }}
                >
                  {index + 1 + '.' + ' ' + label}
                </Typography>
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </MuiStepper>
      {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    variant="contained" size="small"
                >
                    Back 
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleNext} sx={{ mr: 1 }} variant="contained" size="small">
                    Next
                </Button>
            </Box> */}
      {/* <Typography>Steps{activeStep+1}</Typography> */}
    </Box>
  );
};

export default Stepper;
