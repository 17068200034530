import * as React from "react";
import { Tabs as MuiTabs, Tab as MuiTab, Box } from "@mui/material";

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </Box>
  );
}

function CustomTabs(props) {
  const { tabLabels, tabContent, tabsPlace } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: `${tabsPlace}`,
          alignItems: "center",
        }}
      >
        <MuiTabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            display: "inline-flex",
            marginTop: "15px",
          }}
          indicatorColor="none"
        >
          {tabLabels.map((label, index) => (
            <MuiTab
              key={index}
              label={label}
              sx={{
                // border: "1px solid #f00",
                width: "230px",
                background: value === index ? "#161E6A" : "#ACB3F1",
                borderRadius: index === 0 ? "6px 0px 0px 6px" : "0px 6px 6px 0",
                borderBottom: "none",
                fontSize:'16px',
                fontStyle:'normal',
                fontWeight:'500',
                textTransform:'capitalize',
                transition: "all 0.4s",
              }}
            />
          ))}
        </MuiTabs>
      </Box>
      {tabContent.map((content, index) => (
        <TabPanel key={index} value={value} index={index}>
          {content}
        </TabPanel>
      ))}
    </>
  );
}

export default CustomTabs;
