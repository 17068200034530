import { makeStyles } from "@mui/styles";

export default makeStyles(theme=>({
    customTooltip: {
        // backgroundColor: 'transparent',
        // color: 'white',
        fontSize: 16,
        // padding:'0px !important',
    },
    customArrow:{
        '&:before': {
            // background: localStorage.getItem('theme') === 'light' ? `${theme.palette.blue.light} !important` : `${theme.palette.blue.dark} !important`,
        },
    }
}));