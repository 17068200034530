import { Autocomplete, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
const useStyles = makeStyles((theme) => ({
  option: {
    fontSize: "1rem", // Set your desired font size here
    fontFamily: "Poppins !important",
    fontWeight: "500",
  },
}));
const AutoCompleteTwo = ({
  options,
  value,
  onChange,
  placeholder,
  size,
  id,
}) => {
  const classes = useStyles();
  return (
    <>
      <Autocomplete
        componentsProps={{
          paper: {
            sx: {
              margin: "auto",
              p: 0.5,
              border: "1px solid #C6C6C6",
              "& ::-webkit-scrollbar": {
                width: "4px",
              },
              "& ::-webkit-scrollbar-track": {
                background: "#e8e8e8",
              },
              "& ::-webkit-scrollbar-thumb": {
                background: "#C6C6C6",
                borderRadius: "6px",
              },
              "& ::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            },
          },
        }}
        disablePortal
        id="combo-box-demo"
        options={options}
        value={value}
        onChange={onChange}
        size={size}
        sx={{
          "& .MuiAutocomplete-clearIndicator": {
            display: "none",
          },
          "& .MuiAutocomplete-input": {
            padding: "3px 5px !important",
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            inputProps={{
              ...params.inputProps,
              autoComplete: "off", // disable autocomplete and autofill
            }}
            id={id}
          />
        )}
        classes={{
          option: classes.option, // Apply the custom font size here
        }}
      />
    </>
  );
};

export default AutoCompleteTwo;
