import { makeStyles } from "@mui/styles";
export default makeStyles(theme=>({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        borderRadius:'15px',
        '& .MuiInputBase-input': {
            // border: '1px solid #5E5E5E !important',
            borderRadius: '6px',
        },
        '& .MuiInputBase-root':{
            paddingRight:'0px',
            borderRadius: '6px',
            outline:'none !important'
        },
        '& .MuiButtonBase-root':{
            outline: 'none !important',
            background: localStorage.getItem('theme')==='light' ? theme.palette.blue.dark : theme.palette.blue.main,
            color: localStorage.getItem('theme') === 'light' ? theme.palette.white.main : theme.palette.base.main,
            '&:hover': {
                background: localStorage.getItem('theme') === 'light' ? `${theme.palette.black.dark} !important` : `${theme.palette.blue.light} !important`,
            },
        },
        '& input': {
            padding: '12px !important',
            outline: 'none !important',
            fontWeight: '500 !important',
        },
        '& input::focus': {
            borderColor: 'none !important'
        },
        "& input::placeholder": {
            fontSize: "12px !important",
        },
        '& .Mui-error': {
            marginLeft: '0px',
            fontSize: '12px',
            fontWeight: '500',
        }
    },
    formLabel: {
        fontSize: '12px !important',
        fontWeight: '500 !important',
        marginBottom: theme.spacing(1),
    },
}));