import { makeStyles } from '@mui/styles';
export default makeStyles((theme) => ({
  root: {
    '& .MuiFormControlLabel-label': {
      fontSize: '16px',
      // fontWeight:'500 !important'
    },
    '& .MuiFormControlLabel-root': {
      marginLeft: '0px',
      marginRight: '6px',
      // fontWeight:'500 !important'
    },
  },
  radio: {
    '&$checked': {
      color:
        localStorage.getItem('theme') === 'light'
          ? `${theme.palette.blue.dark} !important`
          : `${theme.palette.white.main} !important`,
    },
  },
  // checked: {}
}));
