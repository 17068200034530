import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    // border: "1px solid #ff0",
  },
  appBarOpen: {
    width: "calc(100% - 244px) !important",
    height: `64px !important`,
    boxShadow: "none !important",
    borderBottom: `1px solid #c6c6c6 !important`,
    background: `${theme.palette.white.main} !important`,
  },
  appBarClosed: {
    // width: "calc(100% - 244px) !important",
    height: `64px !important`,
    boxShadow: "none !important",
    borderBottom: `1px solid #c6c6c6 !important`,
    background: `${theme.palette.white.main} !important`,
  },
  toolbar: theme.mixins.toolbar,
  headertext: {
    color: `${theme.palette.black.main} !important`,
    fontWeight: "600 !important",
    fontSize: "16px !important",
    textTransform: "capitalize",
  },
  userProfile: {
    border: "1px solid #888 !important",
  },
}));
