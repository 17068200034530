import React, { useEffect, useRef, useState } from 'react';
import InputWthActionBtn from '../../../components/common/InputBoxWthActionButton/InputWthActionBtn';
import { Box, Grid, Typography } from '@mui/material';
import pointingHandImg from '../../../components/Images/survey_info_image.png';
import successCreatedSurveyImg from '../../../components/Images/survey_image.png';
// import ReactCustomDatePicker from '../../../components/common/ReactDatePicker/ReactCustomDatePicker';
import { putMethod } from '../../../api/putMethod';
import { Common } from '../../../components/common/Common';
import { ToastContainer, toast } from 'react-toastify';
import { getAccessToken } from '../auth';
import { getMethod } from '../../../api/getMethod';
import CustomDatePicker from '../../../components/common/CustomDatePicker/CustomDatePicker';

const localeText = {
  okButtonLabel: 'Update',
  cancelButtonLabel: 'Cancel',
};
const ShareSurvey = (props) => {
  let smId;
  if (props.smId) {
    smId = props.smId;
  } else {
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    smId = url.searchParams.get('id');
  }

  const token = getAccessToken();
  const url = new URL(window.location.href);
  const host = url.host;
  // console.log('host>>>>', host);
  let constructedUrl = '';
  if (window.location.hostname === 'localhost') {
    constructedUrl = 'http://';
  } else {
    constructedUrl = 'https://';
  }

  const inputRef = useRef(null);

  const [selectedDate, setSelectedDate] = useState(new Date());
  // const [dateSelected, setDateSelected] = useState('');
  // const [showMonthPicker, setShowMonthPicker] = useState(false);
  // const [monthSelected, setMonthSelected] = useState(false);
  // const [showYearPicker, setShowYearPicker] = useState(false);
  // const [yearSelected, setYearSelected] = useState(false);
  const [showdate, setShowDate] = useState(false);
  const [changedDate, setChangedDate] = useState(false);
  const [date, setDate] = useState('');
  const [surveyCode, setSurveyCode] = useState('');
  const [surveyExpiryDate, setSurveyExpiryDate] = useState('');

  const inputDate = new Date(surveyExpiryDate);

  // Extract year, month, and day components
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are 0-based
  const day = String(inputDate.getDate()).padStart(2, '0');

  const expiryDates = `${day}-${month}-${year}`; // Replace the month and day with '09-23'

  const handleCopy = () => {
    const inputValue = inputRef.current.value;

    if (inputValue) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(inputValue)
          .then(() => {
            // console.log("Text copied successfully");
            toast.success('Link Copied successfully', {
              style: {
                fontSize: 16,
              },
            });
          })
          .catch((error) => {
            console.error('Copy failed:', error);
          });
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = inputValue;
        document.body.appendChild(textArea);
        textArea.select();

        try {
          document.execCommand('copy');

          toast.success('Link Copied successfully', {
            style: {
              fontSize: 16,
            },
          });
        } catch (err) {
          toast.error('Copy Failed', {
            style: {
              fontSize: 16,
            },
          });
          //  }
          console.error('Copy failed:', err);
        } finally {
          document.body.removeChild(textArea);
        }
      }
    }
  };
  const ChangeExpiryDateShow = () => {
    setShowDate(!showdate);
  };
  const handleExpiryDateOnChange = (newValue) => {
    setSelectedDate(newValue);
  };
  const handleDateAccept = (selectedDate) => {
    // console.log('Hi');
    if (selectedDate === '' || selectedDate === undefined) {
      return;
    } else {
      const inputDate = new Date(selectedDate);
      const year = inputDate.getFullYear();
      const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are 0-based
      const day = String(inputDate.getDate()).padStart(2, '0');

      const formattedDate = `${year}-${month}-${day} 23:56:56.000000`;
      const updatedDate = `${day}-${month}-${year} 23:56:56`;
      const formData = new FormData();
      formData.append('expiry_date', formattedDate);

      setShowDate(false);
      putMethod(
        process.env.REACT_APP_BASE_URL +
          process.env.REACT_APP_PREFIX_URL +
          `${smId}/expiry_date/`,
        token,
        formData
      )
        .then((res) => {
          setChangedDate(true);
          setDate(updatedDate);
          toast.success('Expiry Date Updated successfully', {
            style: {
              fontSize: 16,
              textTransform: 'capitalize',
            },
          });
        })
        .catch((err) => {
          const errorMessages = Object.values(err.response.data.errors).flatMap(
            (errors) => errors
          );
          const errorMessage = errorMessages.join(' ');
          toast.error(errorMessage, {
            style: {
              fontSize: 16,
              textTransform: 'capitalize',
            },
          });
        });
    }
  };

  useEffect(() => {
    getMethod(
      process.env.REACT_APP_BASE_URL +
        process.env.REACT_APP_PREFIX_URL +
        `${smId}/surveyInfo/`,
      token
    )
      .then((res) => {
        setSurveyExpiryDate(res.data.data.sm_expiry_date);
        setSurveyCode(res.data.data.sm_unique_code);
        // Set selected date to expiry date
        setSelectedDate(new Date(res.data.data.sm_expiry_date));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [surveyCode, surveyExpiryDate, smId, token]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justifyContent={'center'} spacing={2}>
          <Grid item xs={12} align="center">
            <Box>
              <img
                src={successCreatedSurveyImg}
                alt="survey created successfully"
                style={{ width: 120, height: 120 }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} mt={1} textAlign="center">
            <Typography variant="sh1">Survey Created Successfully</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={12} mt={2} justifyContent={'center'}>
        {/* <Grid item xs={9}>
          <Box sx={{ display: "flex" }}>
            <Icon
              className="fh-icon fh-icon-warning"
              sx={{
                fontSize: "1.5rem",
                marginRight: "0.5rem",
                color: "#6A5216",
              }}
            />
            <Typography variant="body3" sx={{ color: "#5E5E5E" }}>
              "For surveys not executed via FirstHive campaign channels, append
              question for respondent's Name, Email, and Mobile number as
              mandatory fields"
            </Typography>
          </Box>
        </Grid> */}
        <Grid item xs={9} mt={2} justifyContent={'center'}>
          <InputWthActionBtn
            id="copyCode"
            inputRef={inputRef}
            readOnly={true}
            value={
              `${constructedUrl}` +
              host +
              `/ClientSurveyView?survey_code=${surveyCode}`
            }
            size="small"
            endAdornment="fh-icon fh-icon-copy"
            onClick={handleCopy}
            disabled={true}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} align="center" mt={1}>
        <Box>
          <img src={pointingHandImg} alt="Example" />
        </Box>
      </Grid>

      <Grid item xs={12} align="center">
        <Typography variant="body3">
          Expires On {changedDate ? date : expiryDates}{' '}
        </Typography>
        <Common.Button
          variant="text"
          size="small"
          onClick={ChangeExpiryDateShow}
        >
          change Date
        </Common.Button>
      </Grid>

      {showdate && (
        <Grid item xs={12} align="center" mt={2}>
          <Grid container item xs={4}>
            <CustomDatePicker
              localeText={localeText}
              onAccept={() => handleDateAccept(selectedDate)}
              onChange={handleExpiryDateOnChange}
              value={selectedDate}
            />
          </Grid>
        </Grid>
      )}
      <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
    </Grid>
  );
};

export default ShareSurvey;
