import { makeStyles, styled } from "@mui/styles";
import { Drawer as MuiDrawer } from "@mui/material";
export default makeStyles((theme) => ({
  root: {
    padding: "20px !important",
    verticalAlign: "top !important",
    overflowX: "hidden",
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    "& .MuiDrawer-paper": {
      background: theme.palette.linearGradient.main,
      color:
        theme.palette.mode === "light"
          ? theme.palette.white.main
          : theme.palette.base.main,
    },
  },
  rowWraperBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  columnWraperBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  backward: {
    color: theme.palette.white.main,
  },
  forward: {
    color: theme.palette.white.main,
  },
}));

const drawerWidth = 244;

const openedMixin = (theme) => ({
  width: drawerWidth,
  minWidth: drawerWidth,
  maxWidth: drawerWidth,
  overflow: "hidden",
  transition: theme.transitions.create("all", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  // overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("all", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // padding: '20px !important',
  overflow: "hidden !important",
  minWidth: `calc(${theme.spacing(12)} + 8px)`,
  maxWidth: `calc(${theme.spacing(12)} + 8px)`,

  [theme.breakpoints.up("sm")]: {
    minWidth: `calc(${theme.spacing(12)} + 8px)`,
  },
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  minWidth: drawerWidth,
  maxWidth: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    ...theme.mixins.toolbar,
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    ...theme.mixins.toolbar,
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
