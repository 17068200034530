import axios from 'axios';
export const putMethod = async (url, token, values) => {

    const response = await axios({
        method: 'PUT',
        url,
        data: values,
        headers: {
            "Authorization": `Bearer ${token}`,
            'Content-Type': "application/json",
        }
    })
    return response;
}
