import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Box, Icon, IconButton, Typography } from '@mui/material';
import useStyles, { StyledMenu } from './styles';
export default function ActionMenu(props) {
  const { actions, vertical, horizontal, menuColor, ...others } = props;
  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box>
      <IconButton
        id="demo-customized-button"
        // className={`${classes.actionButton}`}
        className={classes.root}
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        {...others}
      >
        <Icon
          className={`fh-icon fh-icon-kebab-menu ${classes.menuIcon}`}
          sx={{ fontSize: '1rem', color: props.menuColor }}
        />
      </IconButton>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        position="right"
        horizontal={horizontal}
        vertical={vertical}
      >
        {actions.map((item, index) => (
          <MenuItem
            value={item.value}
            key={index}
            disableRipple
            onClick={item.fun}
            className={classes.action}
          >
            {item.icon && <Icon className={item.icon} />}
            <Typography variant="body5" sx={{ ml: 1 }}>
              {item.text}
            </Typography>
          </MenuItem>
        ))}
      </StyledMenu>
    </Box>
  );
}
