import { Typography } from '@mui/material';
import React from 'react';

function StringToMuiTypography({
  inputString,
  questionsFontStyle,
  questionFontSize,
}) {
  // Use a regular expression to match HTML tags and their content
  const tagRegex = /<(strong|em|ins|del)>(.*?)<\/\1>/g;

  // Replace matched tags with appropriate text-decoration
  const replacedString = inputString.replace(
    tagRegex,
    (match, tag, content) => {
      let textDecoration = '';

      switch (tag) {
        case 'strong':
          textDecoration = 'font-weight: bold;';
          break;
        case 'em':
          textDecoration = 'font-style: italic;';
          break;
        case 'ins':
          textDecoration = 'text-decoration: underline;';
          break;
        case 'del':
          textDecoration = 'text-decoration: line-through;';
          break;
        default:
          break;
      }

      return `<span style="${textDecoration}">${content}</span>`;
    }
  );

  return (
    <Typography
      dangerouslySetInnerHTML={{ __html: replacedString }}
      // variant="sh3"
      component={'span'}
      sx={{
        fontFamily: questionsFontStyle
          ? `${questionsFontStyle}!important`
          : 'Poppins',
        color: '#333',
        fontWeight: '500!important',
        fontSize: questionFontSize
          ? `${questionFontSize}px`
          : '1rem !important',
      }}
    />
  );
}

export default StringToMuiTypography;
