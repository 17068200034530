import { Box, Grid, Typography } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Common } from '../../../components/common/Common';
import { Form, useForm } from '../../../components/common/Form/useForm';
import InputBox from '../../../components/common/InputBox/InputBox';
import EndUserFooter from '../../../components/Footer/EndUserFooter';

export default function ClientDetails(props) {
  const { logo, surveyCode, geoData } = props;
  const navigate = useNavigate();
  const initialFieldValues = {
    userName: '',
    userEmail: '',
    userMobileNo: '',
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ('userName' in fieldValues) {
      if (!fieldValues.userName || !fieldValues.userName.trim()) {
        temp.userName = 'This field is required.';
      } else {
        temp.userName = '';
      }
    }
    if ('userEmail' in fieldValues) {
      if (!fieldValues.userEmail || !fieldValues.userEmail.trim()) {
        temp.userEmail = 'Email is required.';
      } else if (
        !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
          fieldValues.userEmail
        )
      ) {
        temp.userEmail = 'Invalid email format.';
      } else {
        temp.userEmail = '';
      }
    }

    if ('userMobileNo' in fieldValues) {
      if (!fieldValues.userMobileNo || !fieldValues.userMobileNo.trim()) {
        temp.userMobileNo = 'Mobile number is required.';
      } else if (!/^\d{10}$/.test(fieldValues.userMobileNo)) {
        temp.userMobileNo = 'Invalid mobile number.';
      } else {
        temp.userMobileNo = '';
      }
    }

    // Check if all fields are valid
    setErrors({ ...temp });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === '');
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFieldValues, true, validate);

  const handleSubmitDetails = (e) => {
    e.preventDefault();
    if (validate()) {
      // setValues({ ...values });

      const payload = {
        state:
          geoData.regionName || geoData.region
            ? geoData.regionName || geoData.region
            : '',
        city: geoData.city ? geoData.city : '',
        country: geoData.country ? geoData.country : '',
        lat: geoData.lat ? geoData.lat : '',
        lon: geoData.lon ? geoData.lon : '',
        ipaddress:
          geoData.ip || geoData.query ? geoData.ip || geoData.query : '',
        name: values.userName,
        email: values.userEmail,
        mobile: values.userMobileNo,
      };
      const urls =
        process.env.REACT_APP_BASE_URL +
        process.env.REACT_APP_SURVEY_USER_CAPTURE +
        `/?sm_unique_code=${surveyCode}`;
      axios
        .post(urls, payload)
        .then((res) => {
          if (res.data) {
            navigate({
              pathname: '/ClientSurveyView',
              search: `?usrId=${res.data.data.usr_unique_id}&survey_code=${surveyCode}`,
            });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Form onSubmit={handleSubmitDetails}>
      <Grid
        container
        sx={{
          border: '1px solid #ccc',
          borderRadius: 2,
          padding: 4,
          minHeight: '310px',
          maxHeight: '710px',
        }}
      >
        <Grid item xs={12}>
          {logo && (
            <Box sx={{ width: '8rem', height: '8rem' }}>
              <img
                src={logo && `${process.env.REACT_APP_BASE_URL}` + logo}
                alt="logo"
                style={{ width: '100%', height: '100%' }}
              />
            </Box>
          )}
          <Box mt={2}>
            <Typography variant="h4">Participant Information</Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body1" sx={{ color: '#5E5E5E' }}>
              Please fill below details to proceed
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography variant="sh3">Name*</Typography>
          <InputBox
            name={'userName'}
            placeholder="Enter your Name"
            value={values.userName}
            onChange={handleInputChange}
            helperText={errors.userName}
            error={errors.userName}
          />
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography variant="sh3">Mobile*</Typography>
          <InputBox
            name="userMobileNo"
            placeholder="Enter your Mobile Number"
            value={values.userMobileNo}
            onChange={handleInputChange}
            helperText={errors.userMobileNo}
            error={errors.userMobileNo}
          />
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography variant="sh3">Email*</Typography>
          <InputBox
            name="userEmail"
            placeholder="Enter your Email Id"
            value={values.userEmail}
            onChange={handleInputChange}
            helperText={errors.userEmail}
            error={errors.userEmail}
          />
        </Grid>

        <Grid item xs={12} mb={3} mt={2}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Common.Button type="submit" size="small">
              Continue
            </Common.Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              p: 4,
            }}
          >
            <EndUserFooter />
          </Box>
        </Grid>
      </Grid>
    </Form>
  );
}
