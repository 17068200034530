import { Box } from '@mui/material';
import React from 'react';

const CommonChartContainer = ({ children }) => {
  return (
    <Box
      sx={{
        minHeight: 430,
        border: '1px solid #C6C6C6',
        borderRadius: 1,
        p: 3,
      }}
    >
      {children}
    </Box>
  );
};

export default CommonChartContainer;
