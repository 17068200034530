import React from 'react'
import { Button as MuiButton } from '@mui/material';
import {makeStyles} from '@mui/styles';

const useStyles=makeStyles(theme=>({
    root: {
        minWidth: 0,
        margin: `${theme.spacing(0.5)} !important`,
    },
    // primary: {
    //     backgroundColor: theme.palette.mode==='light'?`${theme.palette.blue.dark} !important`:``,
    //     '& .MuiButton-label': {
    //         color: theme.palette.primary.main,
    //     }
    // },
    // secondary: {
    //     backgroundColor: theme.palette.secondary.light,
    //     '& .MuiButton-label': {
    //         color: theme.palette.secondary.main,
    //     }
    // },
}))

export default function ActionButton(props) {
    const {color,children,onClick,size}=props;
    const classes=useStyles();
  return (
    <MuiButton
        color={color || "primary"}
        className={`${classes.root} ${classes[color]}`}
        onClick={onClick}
        size={size||"large"}
    >
        {children}
    </MuiButton>
  )
}
