import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { ThemeCtxProvider, useThemeMode } from './context/themeContext';
import { lightTheme, darkTheme } from './theme/theme';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import './index.css';
import '../src/assets/css/main.css';
import { SideBarContextProvider } from './context/sidebarContext';
import { FormProvider } from './context/formContext';

const Root = () => {
  const { darkMode } = useThemeMode();

  useEffect(() => {
    localStorage.getItem('theme')
      ? localStorage.getItem('theme')
      : localStorage.setItem('theme', 'light');
  }, []);

  let theme = React.useMemo(() => {
    return createTheme(darkMode ? darkTheme : lightTheme);
  }, [darkMode]);

  // console.log({theme})
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {<App />}
    </ThemeProvider>
  );
};

// Start the Rendering from the
// Root component
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeCtxProvider>
    <SideBarContextProvider>
      <FormProvider>
        <Router>
          <Root />
        </Router>
      </FormProvider>
    </SideBarContextProvider>
  </ThemeCtxProvider>
);
