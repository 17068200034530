import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import EndUserFooter from '../../../../components/Footer/EndUserFooter';

const SubmittedSurvey = ({ message }) => {
  return (
    <Grid
      sx={{ flexGrow: 1, border: '1px solid #c6c6c6', borderRadius: 2 }}
      container
      spacing={2}
      mt={0.1}
    >
      <Grid item xs={12}>
        <Grid
          container
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ height: '90vh' }}
        >
          <Grid item xs={12}>
            {/* <Box sx={{ width: "25rem", height: "25rem" }}>
            <img
              src={submitedimage}
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
          </Box> */}
            <Typography variant="h1" sx={{ textAlign: 'center' }}>
              {message}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: '#5E5E5E',
                mt: 4,
                textAlign: 'center',
              }}
            >
              {message === 'Survey Not Found'
                ? null
                : 'Thank you for participating! Your survey response has already been submitted.'}
            </Typography>
          </Grid>
          <Grid item xs={12} mt={4} mr={2}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <EndUserFooter />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SubmittedSurvey;
