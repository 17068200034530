import { makeStyles } from "@mui/styles";
export default makeStyles(theme => ({
  root: {
    '& .MuiOutlinedInput-root':{
      padding:'0px 0px 0px 8px !important',
    },
    "& .MuiOutlinedInput-root>input": {
      // "& > fieldset": {
      //   borderColor: localStorage.getItem('theme') === 'light' ? `${theme.palette.grey.light} !important` : `${theme.palette.grey.dark} !important`,
      // },
      padding:'12px 0px',
      // paddingRight:'0px',
      // paddingLeft:'8px',
      '&.Mui-disabled': {
        borderRadius:'4px',
      },
    },
    
    '& .MuiFormHelperText-root':{
      margin:'0px'
    },
    '& input': {
      // fontSize: '16px',
      // paddingRight: '12px'
    },
    '& input::placeholder':{
      color: localStorage.getItem('theme') === 'light' ? `${theme.palette.black.main} !important` : `${theme.palette.white.main}!important`
    }
  },
  icon: {
    width: '24px',
    height: '24px',
    textAlign: 'center',
    background: localStorage.getItem('theme') === 'light' ? `${theme.palette.black.dark} !important` : `${theme.palette.blue.light} !important`,
    color: '#FFFFFF'
  },
  iconButton: {
    width: '24px',
    height: '24px',
    textAlign: 'center',
    background: localStorage.getItem('theme') === 'light' ? `${theme.palette.black.dark} !important` : `${theme.palette.blue.light} !important`,
    color: '#FFFFFF !important',
    borderRadius: '0% !important',
    '&:hover': {
      background: localStorage.getItem('theme') === 'light' ? `${theme.palette.blue.dark} !important` : `${theme.palette.blue.dark} !important`,
    }
  },
  formLabel:{
    minWidth: '65px',
    color:'#333',
    marginBottom:'8px',
    display:'inline-block'
  },
  // errorBorder:{
  //   border: '1px solid #f00',
  //   color: '#f00', // Additional styles for text color
  //   display: 'inline-block',
  // }
}));