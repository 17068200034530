import React, { useEffect, useState } from 'react';
import CommonUI from '../../CommonUI';
import {
  Box,
  CircularProgress,
  Grid,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';

import useTable from '../../../components/common/Table/useTable';
import { Common } from '../../../components/common/Common';
import FontModal from './FontModal/FontModal';
import { getMethod } from '../../../api/getMethod';
import { getAccessToken } from '../auth';

const headCells = [
  { id: 1, label: 'S.No' },
  { id: 2, label: 'Font Name' },
  { id: 3, label: 'Url' },
  { id: 4, label: 'Action' },
];

const ManageFonts = () => {
  const token = getAccessToken();
  const [openFontModal, setOpenFontModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const url =
        process.env.REACT_APP_BASE_URL +
        process.env.REACT_APP_PREFIX_URL +
        `upload-font`;
      const response = await getMethod(url, token);
      // console.log('res>>>>>', response.data.data);
      setTableData(response.data.data.reverse()); // Handle the response data here
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    tableData,
    headCells,
    filterFn
  );

  const handleOpenFontModalClick = () => {
    setOpenFontModal(true);
  };
  // console.log('values.customFontFormats>>>>', typeof values.customFontFormats);

  // if (loading) {
  //   return <div>Loading...</div>; // Show loading indicator while fetching
  // }

  return (
    <CommonUI headertext="Manage Fonts">
      <Grid container mt={2}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={9}>
              <Typography variant="sh3">Custom fonts</Typography>
            </Grid>
            <Grid item xs={3} display="flex" justifyContent={'end'}>
              <Common.Button onClick={handleOpenFontModalClick} size="small">
                Add Font
              </Common.Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={2}>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
              }}
            >
              <CircularProgress size={72} thickness={3} />
            </Box>
          ) : (
            <TblContainer>
              <TblHead variant="sh5" />
              <TableBody>
                {recordsAfterPagingAndSorting().map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.fs_name}</TableCell>
                    <TableCell>{item.fs_font_file}</TableCell>
                    <TableCell>-</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TblContainer>
          )}
        </Grid>
      </Grid>
      <FontModal
        openPopup={openFontModal}
        setOpenPopup={setOpenFontModal}
        fetchData={fetchData}
      />
    </CommonUI>
  );
};

export default ManageFonts;
