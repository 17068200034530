import { makeStyles } from "@mui/styles";
export default makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "& > fieldset": {
        //  borderColor: localStorage.getItem('theme') === 'light' ? `${theme.palette.grey.light} !important` : `${theme.palette.grey.main} !important`,
      },
      "&.Mui-disabled": {
        //  background: localStorage.getItem('theme') === 'light' ? `${theme.palette.black.dark} !important` : `${theme.palette.black.dark} !important`,
        borderRadius: "4px",
      },
    },

    "& .MuiFormHelperText-root": {
      margin: "0px !important",
    },
    "& input": {
      fontSize: "16px",
      padding: "10px",
    },
    "& input::placeholder": {
      fontSize: "16px",
      textTransform: "capitalize",
    },
    "& .MuiInputAdornment-root": {
      marginRight: "-14px !important",
    },
  },
  icon: {
    width: "24px",
    height: "24px",
    textAlign: "center",
    background:
      localStorage.getItem("theme") === "light"
        ? `${theme.palette.black.dark} !important`
        : `${theme.palette.black.dark} !important`,
  },
  iconButton: (props) => ({
    minWidth: "43px",
    textAlign: "center",
    // background: localStorage.getItem('theme') === 'light' ? `${theme.palette.blue.dark} !important` : `${theme.palette.blue.light} !important`,
    background: props.disabled
      ? `${theme.palette.blue.dark} !important`
      : `${theme.palette.blue.light} !important`,
    // color: localStorage.getItem('theme') === 'light' ? `${theme.palette.white.main} !important` : `${theme.palette.blue.dark}!important`,
    color: `${theme.palette.white.main} !important`,
    borderRadius: "4px !important",
    zIndex: props.disabled && 1,
    // marginRight:'0px !important',
    "&:hover": {
      background:
        localStorage.getItem("theme") === "light"
          ? `${theme.palette.black.main} !important`
          : `${theme.palette.blue.light} !important`,
      // color: localStorage.getItem('theme') === 'light' ? `${theme.palette.white.main} !important` : `${theme.palette.blue.light} !important`,
      color:
        localStorage.getItem("theme") === "light"
          ? `${theme.palette.white.main} !important`
          : `${theme.palette.blue.dark} !important`,
    },
  }),
  formLabel: {
    minWidth: "65px",
  },
}));
