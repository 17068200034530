import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
   root: {
    padding: `${theme.spacing(1, 2)} !important`,
    justifyContent:"flex-start !important",
    
   },
   buttonWidth:{
    width:props=>`${props.buttonMinWidth} !important`
   },
   MenuWidth:{
    width:props=>`${props.menuMinWidth} !important`,
    overflow:"hidden",
   },

    contained:{
      background: `${theme.palette.blue.dark} !important`,
      borderColor: `${theme.palette.white.main} !important`,
      color:`${theme.palette.white.main} !important`
    },
    outlined:{
      borderColor: `${theme.palette.blue.dark} !important`,
      color:`${theme.palette.blue.dark} !important`
    },
    text:{
      borderColor: `${theme.palette.blue.dark} !important`,
      color:`${theme.palette.blue.dark} !important`
    },
    textSizeSmall:{
        fontSize: '14px !important',
    },
    textSizeMedium:{
        fontSize: '16px !important',
    },
    textSizeLarge:{
        fontSize: '18px !important',
    },
    iconSizeSmall:{
       fontSize: '16px !important',
    },
    iconSizeMedium:{
        fontSize: '18px !important',
    },
    iconSizeLarge:{
        fontSize: '20px !important',
    },
    iconButtonPadding:{
      padding:`${theme.spacing(2)} !important`
    },
    buttonPadding:{
      padding: `${theme.spacing(2)} !important`,
    },
    textMargin:{
     marginRight:`10px !important`
    }
  
  })
  );