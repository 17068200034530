import React, { useState } from 'react';
import { Box, FormControl, TextField, Typography } from '@mui/material';
import useStyles from './styles';

const TextArea = (props) => {
  const {
    label,
    id,
    direction,
    cols,
    disabled,
    rows,
    maxChars,
    charCount,
    setCharCount,
    helperMsg,
    setHelperMsg,
    error,
    value,
    onChange,
    name,
    maxLength,
    questionsFontStyle,
    ...other
  } = props;
  const classes = useStyles(props);

  const [text, setText] = useState('');
  // const [charCount, setCharCount] = useState(0);
  /*  const [helperMsg,setHelperMsg]=useState(''); */
  // console.log("Charcount", charCount);
  // const hasError = Boolean(helperMsg);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: direction }}>
        {/* <Box>
          <Typography
            variant="sh3"
            sx={{ color: "#333", marginBottom: 1, display: "inline-block" }}
          >
            {label}
          </Typography>
        </Box> */}
        <FormControl fullWidth>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 0, width: '100%' },
              '& ::-webkit-scrollbar': {
                width: '4px',
              },
              '& ::-webkit-scrollbar-track': {
                background: '#e8e8e8',
              },
              '& ::-webkit-scrollbar-thumb': {
                background: '#888',
                borderRadius: '6px',
              },
              '& ::-webkit-scrollbar-thumb:hover': {
                background: '#555',
              },
            }}
            noValidate
            autoComplete="off"
          >
            <Box>
              <TextField
                id={id}
                disabled={disabled}
                multiline
                rows={rows}
                name={name}
                value={value}
                inputProps={{ maxLength: maxLength }}
                onChange={onChange}
                className={`${classes.root} ${
                  helperMsg && classes.errorBorder
                }`}
                sx={{
                  '& .MuiInputBase-input': {
                    fontSize: '1rem',
                    fontWeight: '400',
                    fontFamily: `${questionsFontStyle}, sans-serif`,
                  },
                  '& ::placeholder': {
                    fontSize: '0.875rem !important',
                  },
                }}
                {...(error && { error: true, helperText: error })}
                {...other}
              />
            </Box>
          </Box>
        </FormControl>
      </Box>
      {/* {!disabled && (
        <Box
          sx={{
            marginLeft: direction === "row" ? "60px" : "",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body4" sx={{ color: "#f00" }}>
            {helperMsg}
          </Typography>
          {charCount || maxChars ? (
            <Typography variant="body4">{`${charCount}/${maxChars}`}</Typography>
          ) : (
            ""
          )}
        </Box>
      )} */}
    </>
  );
};

export default TextArea;
