import { Box } from '@mui/material';
import React from 'react';
import Filters from '../Filters/Filters';

const TabWrapper = ({
  children,
  dateVal,
  handleGoFilterClick,
  options,
  gettingAllSurveyData,
  surveyName,
  channelName,
  handleDateChange,
  handleOnSurveyChange,
  handleOnChannelChange,
}) => {
  return (
    <Box>
      <Filters
        handleGoFilterClick={handleGoFilterClick}
        surveyName={surveyName}
        channelName={channelName}
        gettingAllSurveyData={gettingAllSurveyData}
        options={options}
        handleOnSurveyChange={handleOnSurveyChange}
        handleOnChannelChange={handleOnChannelChange}
        dateVal={dateVal}
        handleDateChange={handleDateChange}
      />
      {children}
    </Box>
  );
};

export default TabWrapper;
