import { Grid, Typography } from '@mui/material';
import React from 'react';
import TextTypeChart from './TextTypeChart/TextTypeChart';
import SingleTypeChart from './SingleTypeChart/SingleTypeChart';
import MultipleTypeChart from './MultipleTypeChart/MultipleTypeChart';
import NpsTypeChart from './NpsTypeChart/NpsTypeChart';
import StarTypeChart from './StarTypeChart/StarTypeChart';

// Utility function to remove all HTML tags
const stripHtmlTags = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

const QuestionAnalytics = (props) => {
  const { questionAnalyticsData } = props;

  if (questionAnalyticsData.length === 0) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} textAlign="center" height={'64vh'}>
          <Typography variant="body1">No records found</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      {questionAnalyticsData.map((item, index) => {
        const cleanQuestionText = stripHtmlTags(item.question); // Strip HTML tags from question text

        return (
          <Grid item container key={index} spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={
                item.question_type_id === 4 ||
                item.question_type_id === 5 ||
                item.question_type_id === 3 ||
                item.question_type_id === 8
                  ? 12
                  : 6
              }
              lg={
                item.question_type_id === 4 ||
                item.question_type_id === 5 ||
                item.question_type_id === 3 ||
                item.question_type_id === 8
                  ? 12
                  : 6
              }
              xl={
                item.question_type_id === 4 ||
                item.question_type_id === 5 ||
                item.question_type_id === 3 ||
                item.question_type_id === 8
                  ? 12
                  : 6
              }
            >
              <Typography variant="sh3">
                {'Q'}
                {index + 1}
                {'. '}
                {cleanQuestionText}
              </Typography>
              {/* Render different chart components based on question type */}
              {item.question_type_id === 1 || item.question_type_id === 2 ? (
                <TextTypeChart item={item} />
              ) : item.question_type_id === 4 ? (
                <SingleTypeChart item={item} />
              ) : item.question_type_id === 5 ? (
                <MultipleTypeChart item={item} />
              ) : item.question_type_id === 3 ? (
                <NpsTypeChart item={item} />
              ) : item.question_type_id === 8 ? (
                <StarTypeChart item={item} />
              ) : null}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default QuestionAnalytics;
