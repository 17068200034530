import { Box, Toolbar } from "@mui/material";
import React from "react";
import Header from "../../components/Header";
import useStyles from "./styles";

export default function CommonUI(props) {
  const { headertext, children } = props;
  const classes = useStyles();
  return (
    <Box>
      <Header headertext={headertext} />
      <Toolbar className={classes.toolbar}>
        <Box className={classes.mainWrapper}>{children}</Box>
      </Toolbar>
    </Box>
  );
}
