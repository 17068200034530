import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ClientDetails from './ClientDetails';
import axios from 'axios';
import KnownUserResponsePage from './KnownUserResponsePage/KnownUserResponsePage';

const DisplayBody = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [geoData, setGeoData] = useState({
    country: '',
    regionName: '',
    city: '',
    userIp: '',
    lat: '',
    lon: '',
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userUniqueId, setUserUniqueId] = useState(null);
  const [smId, setSmId] = useState('');
  const [position, setPosition] = useState({ latitude: null, longitude: null });

  const [httpReferrer, setHttpReferrer] = useState('');
  const [httpUserAgent, setHttpUserAgent] = useState('');

  // Extract query parameters from the URL
  const searchParams = new URLSearchParams(location.search);
  const usrId = searchParams.get('usrId');
  const survey_code = searchParams.get('survey_code');
  const cmp_fk_id = searchParams.get('dcfh');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const prefixUrl = process.env.REACT_APP_PREFIX_URL;

      if (!baseUrl || !prefixUrl) {
        // console.error('Base URL or Prefix URL is not defined.');
        setLoading(false);
        return;
      }

      const urls = survey_code
        ? `${baseUrl}${prefixUrl}surveycheck/?surveycode=${survey_code}`
        : '';

      // console.log('Request URL:', urls); // Add this to debug the URL

      if (urls) {
        try {
          const response = await axios.get(urls);
          if (response.data && response.data.data) {
            setData(response.data.data);
            setSmId(response.data.data.sm_pk_id);
            setLoading(false);
          } else {
            console.error('Invalid data structure in response:', response.data);
            setLoading(false);
          }
        } catch (error) {
          console.log('Error fetching survey data:', error);
          setLoading(false);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    if (survey_code) {
      fetchData();
    }
  }, [survey_code]);

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setPosition({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    } else {
      console.log('Geolocation is not available in your browser.');
    }
  }, []);

  useEffect(() => {
    const callingLocationGettingApi = async () => {
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const prefixUrl = process.env.REACT_APP_PREFIX_URL;

      if (!baseUrl || !prefixUrl) {
        console.error('Base URL or Prefix URL is not defined');
        return;
      }

      const apiUrl = `${baseUrl}${prefixUrl}get-ip-and-geo/`;
      console.log('API URL:', apiUrl); // Log the API URL to debug

      try {
        const response = await axios.get(apiUrl);

        if (response.data) {
          console.log('Received Geo Data:', response.data);
          console.log('response.data.ip:', response.data.ip);

          const res = {
            country: response.data.country || '',
            region: response.data.region || response.data.regionName || '',
            city: response.data.city || '',
            ip: response.data.ip || response.data.query || '',
            lat: position.latitude || '', // Use latitude from position
            lon: position.longitude || '', // Use longitude from position
          };

          console.log('res>>>>>>>>>', res);

          setGeoData(res);
          console.log('setted data comed>>>>>>', geoData);
        }
      } catch (error) {
        setGeoData({
          country: '',
          region: '',
          city: '',
          ip: '',
          lat: '',
          lon: '',
        });
        console.error('Error fetching geo data:', error);
      }
    };

    // Ensure that `data` is defined and its status is valid before calling the API
    if (
      data &&
      (data.sm_status === 'Active' || data.sm_status === 'ready for execution')
    ) {
      callingLocationGettingApi();
    }
  }, [data, position]); // Dependency on `data` to avoid unnecessary calls

  useEffect(() => {
    const fetchUserUniqueId = async () => {
      // const payload = {
      //   name: '',
      //   email: '',
      //   mobile: '',
      //   state: geoData.region || geoData.regionName || '',
      //   city: geoData.city || '',
      //   country: geoData.country || '',
      //   lat: position.latitude || '',
      //   lon: position.longitude || '',
      //   ipaddress: geoData.ip || geoData.query || '',
      // };

      const baseUrl = process.env.REACT_APP_BASE_URL;
      const surveyUserCapture = process.env.REACT_APP_SURVEY_USER_CAPTURE;

      if (!baseUrl || !surveyUserCapture) {
        console.error('Base URL or Survey User Capture URL is not defined.');
        return;
      }

      const urls = `${baseUrl}${surveyUserCapture}/?sm_unique_code=${survey_code}`;
      console.log('API URL:', urls);

      try {
        console.log('ggggggggggggggggggggggggggg>>>>>>', geoData);
        const res = await axios.post(urls, {
          name: '',
          email: '',
          mobile: '',
          state: geoData.region || geoData.regionName || '',
          city: geoData.city || '',
          country: geoData.country || '',
          lat: position.latitude || '',
          lon: position.longitude || '',
          ipaddress: geoData.ip || '',
        });
        if (res.data && res.data.data) {
          setUserUniqueId(res.data.data.usr_unique_id);
          // Navigate
          navigate({
            pathname: '/ClientSurveyView',
            search: `?usrId=${res.data.data.usr_unique_id}&survey_code=${survey_code}`,
          });
        } else {
          console.error('Unexpected response structure:', res);
        }
      } catch (err) {
        console.error('Error posting survey data:', err);
      }
    };

    const dataIspii = {
      surveyPii: data.survey_ispii,
    };

    if (dataIspii.surveyPii === 0 && !usrId && geoData) {
      const timeoutId = setTimeout(() => {
        fetchUserUniqueId();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [data, position, geoData]);

  useEffect(() => {
    const http_referrer = document.referrer;
    if (http_referrer) setHttpReferrer(http_referrer);
  }, []);
  useEffect(() => {
    const usrAgent = navigator.userAgent;
    if (usrAgent) setHttpUserAgent(usrAgent);
  }, []);

  useEffect(() => {
    if (httpReferrer || httpUserAgent) {
      const data = {
        page: 'ClientSurveyView',
        http_refer: httpReferrer,
        user_agent: httpUserAgent,
      };
      if (document.location.pathname === '/ClientSurveyView') {
        const url =
          process.env.REACT_APP_BASE_URL +
          process.env.REACT_APP_PREFIX_URL +
          'httprfer/';
        axios
          .post(url, data)
          .then((res) => {
            if (res.data) {
              // console.log('res.data>>>>>>>>', res.data);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  }, [httpReferrer, httpUserAgent]);

  if (data.length === 0 && !loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '90vh',
        }}
      >
        <Typography variant="sh1">Invalid Survey!</Typography>
      </Box>
    ); // Render invalid survey message
  } else if (data.sm_status === 'Expired') {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '90vh',
        }}
      >
        <Typography variant="sh1">
          Thank you for your participation. This survey has now closed.
        </Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {data && data.survey_ispii === 1 && !usrId ? (
          <ClientDetails surveyCode={survey_code} geoData={geoData} />
        ) : data.survey_ispii === 1 && usrId ? (
          <KnownUserResponsePage
            surveyCode={survey_code}
            geoData={geoData}
            userUniqueId={usrId}
            cmp_fk_id={cmp_fk_id}
            smId={smId}
          />
        ) : data.survey_ispii === 0 && !usrId ? (
          <KnownUserResponsePage //this is for if user id not present
            surveyCode={survey_code}
            geoData={geoData}
            smId={smId}
            userUniqueId={userUniqueId}
          />
        ) : (
          <KnownUserResponsePage //this is for if user id present
            surveyCode={survey_code}
            geoData={geoData}
            smId={smId}
            userUniqueId={usrId}
            cmp_fk_id={cmp_fk_id}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default DisplayBody;
