import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Box } from '@mui/system';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function DoughnutChart(props) {
  const { width, height, labels, datasets, fontColor } = props;
  const data = {
    labels,
    datasets,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 2,
    categoryPercentage: 0.4,
    cutout: '60%',
    // radius: 110,
    plugins: {
      tooltip: {
        titleFont: {
          size: 12,
        },
        bodyFont: {
          size: 12,
        },
      },

      legend: {
        display: true,
        position: 'right',
        align: 'start',
        labels: {
          color: fontColor,
          usePointStyle: true,
          pointStyle: 'rect',
          boxWidth: 8,
          boxHeight: 8, // Adjust the height of the color box
          // padding: 20, // Adjust the padding to increase space between color and text
          font: {
            size: 12,
            family: 'poppins',
          },
        },
      },
      title: {
        display: false,
      },
    },
  };
  return <Doughnut data={data} options={options} />;
}
