import { makeStyles } from "@mui/styles";
export default makeStyles((theme) => ({
  root: {
    color: "#fff",
    "&:hover": {
      background:
        localStorage.getItem("theme") === "light"
          ? `rgba(198, 198, 198, 0.4) !important`
          : `rgba(0, 0, 0, 0.3) !important`,
      color:
        localStorage.getItem("theme") === "light"
          ? `${theme.palette.white.main} !important`
          : `#000 !important`,
      borderRadius: `${theme.spacing(1)} !important`,
    },
  },
  active: {
    background:
      localStorage.getItem("theme") === "light"
        ? `${theme.palette.white.main} !important`
        : `${theme.palette.base.main} !important`,
    borderRadius: `4px !important`,
    color:
      localStorage.getItem("theme") === "light"
        ? `${theme.palette.blue.dark} !important`
        : `#fff !important`,
  },
  icon: {
    fontWeight: "900 !important",
    color:
      localStorage.getItem("theme") === "light"
        ? `${theme.palette.blue.dark} !important`
        : `#fff !important`,
    fontFamily: "Poppins !important",
  },
  title: {
    fontSize: "16px !important",
    fontWeight: "500 !important",
    margin: "0 12px !important",
    // color: localStorage.getItem('theme') === 'light' ? `${theme.palette.white.main}` :`${theme.palette.base.main}`,
    // marginLeft: `${theme.spacing(6)} !important`,
    // border:'1px solid #0F0'
  },
  subNav: {
    fontSize: "12px !important",
    fontWeight: "500 !important",
    // border:'1px solid #f00',
    // color: localStorage.getItem('theme') === 'light' ? `${theme.palette.white.main}` : `${theme.palette.base.main}`,
  },
  iconWhite: {
    color:
      localStorage.getItem("theme") === "light"
        ? `${theme.palette.white.main} !important`
        : `${theme.palette.base.main} !important`,
    fontWeight: "500 !important",
    fontFamily: "Poppins !important",
  },
  listIconItem: {
    minWidth: "0px !important",
  },
}));
