import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, ListItem, Typography, } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const DownArrow = (props) => {
  const theme = useTheme()
    
  return (
    <Box>
    <Box sx={{
      width: '0.05rem',
      height: props.height,
      marginLeft: props.arrowMarginLeft,
      background: `${theme.palette.blue.main} !important`,
    }} >
    
    </Box>
    <Box sx={{
      marginLeft: props.iconMarginLeft/*'0.04rem'*/,
      marginTop: props.iconMarginTop/*'-0.5rem'*/,
      color: `${theme.palette.blue.main} !important`,
      fontSize: '13px'
    }}>
    <Box className="fh-icon fh-icon-down"></Box>
    </Box>
    
    </Box>
  )
}

export default DownArrow