import { makeStyles } from "@mui/styles";
export default makeStyles((theme) => ({
  footerContent: {
    display: "flex",
    alignItems: "center",
    // border: "1px solid #f00",
    // width: `calc(100% - 244px)`,
    // textAlign:'right'
  },
}));
