import React, { useEffect, useState } from 'react';
import InputBox from '../../../../components/common/InputBox/InputBox';
import TextArea from '../../../../components/common/TextArea/TextArea';
import { Grid } from '@mui/material';
import { Common } from '../../../../components/common/Common';
import RadioGroup from '../../../../components/common/RadioGroup/RadioGroup';
import { postMethodFile } from '../../../../api/postMethodFile';
import { getAccessToken } from '../../auth';
import Fileupload from '../../../../components/common/DocumentUploader/FileUpload';
import { getMethod } from '../../../../api/getMethod';
import { putMethodFile } from '../../../../api/putMethodFile';
import { toast, ToastContainer } from 'react-toastify';
import { Box, Typography } from '@mui/material';
import axios from 'axios';
import { Form, useForm } from '../../../../components/common/Form/useForm';
import TextStyles from '../TextStyles/TextStyles';
// import FileUploader from '../../../../components/common/DocumentUploader/FileUploader';
const items = [
  { id: 'all', title: 'All Questions in one page' },
  // { id: "section_wise", title: "One Section per page" },
  { id: 'question_wise', title: 'One Question at a time' },
];
const piItems = [
  { id: 1, title: 'Yes' },
  { id: 0, title: 'No' },
];
const itemss = [
  { id: 'left', title: 'Left' },
  { id: 'center', title: 'Center' },
  { id: 'right', title: 'Right' },
];
const maxChars = 250;
export default function EditBasicInfo({
  surveyId,
  survey_code,
  activeStep,
  onNextClick,
  duplicateId,
  setUpdatedFlag,
  isAddQusetionVisited,
}) {
  const token = getAccessToken();
  const [showContent, setShowContent] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const [charCounts, setCharCounts] = useState(0);
  const [expiryDate, setExpiryDate] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');
  // const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedImage, setselectedImage] = useState('');
  const [titleFontSize, setTitleFontSize] = useState(18);
  const [descriptionFontSize, setDescriptionFontSize] = useState(18);
  const [sectionTitleFontSize, setSectionTitleFontSize] = useState(18);
  const [questionFontSize, setQuestionsFontSize] = useState(18);
  const [optionsFontSize, setOptionsFontSize] = useState(18);
  const [headerFontSize, setHeaderFontSize] = useState(18);
  const [messageFontSize, setMessageFontSize] = useState(18);
  const [newSurveyId, setNewSurveyId] = useState('');
  const [newSurveyCode, setNewSurveyCode] = useState('');
  const [fontSetting, setFontSetting] = useState([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [surveyStatus, setSurveyStatus] = useState('');
  console.log('surveyStatus>>>>>>', surveyStatus);

  const initialFieldValues = {
    sm_title: '',
    description: '',
    surveyFormat: '',
    personalInfo: '',
    logoPosition: '',
    surveyHeader: '',
    surveyMessage: '',
    titleFontStyle: '',
    descriptionFontStyle: '',
    sectionTitleFontStyle: '',
    questionFontStyle: '',
    optionResponseFontStyle: '',
    headerFontStyle: '',
    messageFontStyle: '',
  };
  // console.log('SelectedFileEdit>>>>>', selectedFile);
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ('sm_title' in fieldValues) {
      if (!fieldValues.sm_title || !fieldValues.sm_title.trim()) {
        temp.sm_title = 'This field is required.';
      } else if (fieldValues.sm_title.length > 100) {
        setIsSaveDisabled(true);
        temp.sm_title = 'Title cannot exceed 100 characters.';
      } else {
        temp.sm_title = '';
        setIsSaveDisabled(false);
      }
    }
    if ('description' in fieldValues) {
      if (!fieldValues.description || !fieldValues.description.trim()) {
        temp.description = 'This field is required.';
      } else if (fieldValues.description.length > 200) {
        setIsSaveDisabled(true);
        temp.description = 'Description cannot exceed 200 characters.';
      } else {
        temp.description = '';
        setIsSaveDisabled(false);
      }
    }
    if ('surveyFormat' in fieldValues) {
      temp.surveyFormat = fieldValues.surveyFormat
        ? ''
        : 'This field is required.';
    }
    if ('personalInfo' in fieldValues) {
      temp.personalInfo = fieldValues.personalInfo
        ? ''
        : 'This field is required.';
    }
    if ('logoPosition' in fieldValues) {
      temp.logoPosition = fieldValues.logoPosition
        ? ''
        : 'Logo position is required when a logo is selected.';
    }
    if ('surveyMessage' in fieldValues) {
      if (fieldValues.surveyMessage.length > 200) {
        temp.surveyMessage = 'Message cannot exceed 200 characters.';
      } else {
        temp.surveyMessage = '';
      }
    }
    setErrors({ ...temp });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === '');
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFieldValues, true, validate);

  const handleCheckboxChange = (event) => {
    setShowContent(event.target.checked);
  };

  //To update flag so that when it lands on add question page it didnt create section
  useEffect(() => {
    setUpdatedFlag(true);
  }, []);

  const addQuestion = (surveyId, survey_code, activeStep, expiryDate) => {
    activeStep = activeStep + 1;
    onNextClick(surveyId, survey_code, expiryDate);
  };

  console.log('sahkdagdjk');
  const EditApply = (e) => {
    e.preventDefault();

    if (validate()) {
      setValues({ ...values });
    }
    // Check if a logo is selected
    if (selectedFile) {
      // If logo is selected, check if logo position is also selected
      if (!values.logoPosition) {
        // If logo position is not selected, set error and return
        setErrors((prevErrors) => ({
          ...prevErrors,
          logoPosition: 'Logo position is required when a logo is selected.',
        }));
        return;
      }
    }
    const formData = new FormData();
    formData.append('sm_title_font', parseInt(values.titleFontStyle));
    formData.append('sm_title_font_size', parseInt(titleFontSize));
    formData.append('sm_desc_font', parseInt(values.descriptionFontStyle));
    formData.append('sm_desc_font_size', parseInt(descriptionFontSize));
    formData.append('sm_section_font', parseInt(values.sectionTitleFontStyle));
    formData.append('sm_section_font_size', parseInt(sectionTitleFontSize));
    formData.append('sm_question_font', parseInt(values.questionFontStyle));
    formData.append('sm_question_font_size', parseInt(questionFontSize));
    formData.append('sm_option_font', parseInt(values.optionResponseFontStyle));
    formData.append('sm_option_font_size', parseInt(optionsFontSize));
    formData.append('sm_ty_msg_font', parseInt(values.messageFontStyle));
    formData.append('sm_ty_msg_font_size', parseInt(messageFontSize));
    formData.append('sm_ty_header_font', parseInt(values.headerFontStyle));
    formData.append('sm_ty_header_font_size', parseInt(headerFontSize));
    formData.append('sm_title', values.sm_title);
    formData.append('sm_description', values.description);
    formData.append('sm_format', values.surveyFormat);
    formData.append('sm_ty_header', values.surveyHeader);
    formData.append('sm_ty_message', values.surveyMessage);
    formData.append('sm_is_pii', values.personalInfo);

    // if (selectedFile) {
    //   const filename = 'download.png'; // Make sure the filename has a valid extension like '.png'
    //   const logofile = new File([selectedFile], filename, {
    //     type: selectedFile.type,
    //   });

    //   formData.append('sm_logo_link', logofile);
    //   formData.append('sm_logo_position', values.logoPosition);
    // }
    // if (selectedImage) {
    //   const filebanner = 'download.png'; // Make sure the filename has a valid extension like '.png'
    //   const bannerfile = new File([selectedImage], filebanner, {
    //     type: selectedImage.type,
    //   });
    //   formData.append('sm_banner_link', bannerfile);
    // }
    // Add logo file
    const logoFile = selectedFile || null;
    const logoFileName = logoFile ? 'download.png' : '';
    const logoFileBlob = logoFile
      ? new File([selectedFile], logoFileName, {
          type: selectedFile.type,
        })
      : '';
    formData.append('sm_logo_link', logoFileBlob);
    formData.append('sm_logo_position', values.logoPosition);

    // Add banner file
    const bannerFile = selectedImage || null;
    const bannerFileName = bannerFile ? 'download.png' : '';
    const bannerFileBlob = bannerFile
      ? new File([selectedImage], bannerFileName, {
          type: selectedImage.type,
        })
      : '';
    formData.append('sm_banner_link', bannerFileBlob);

    putMethodFile(
      process.env.REACT_APP_BASE_URL +
        process.env.REACT_APP_PREFIX_URL +
        `${surveyId}/surveyInfo/`,
      token,
      formData
    )
      .then((res) => {
        console.log('resDTATSTSATSTASS>>>>>>>', res);
        toast.success('Survey Updated successfully', {
          style: {
            fontSize: '0.875rem',
          },
        });
        setExpiryDate(res.data.data.sm_expiry_date);
        setIsSubmitted(true);

        setTimeout(() => {
          setIsSubmitted(false);
        }, 10000);
        setUpdatedFlag(true);
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.errors) {
          const errorMessages = Object.values(err.response.data.errors).flatMap(
            (errors) => errors
          );
          const errorMessage = errorMessages.join(' ');
          toast.error(errorMessage, {
            style: {
              fontSize: '0.875rem',
            },
          });
        }
      });
  };
  /////////////////dublicateId///////
  const handleDuplicate = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('sm_title', values.sm_title);
    formData.append('sm_description', values.description);
    formData.append('sm_format', values.surveyFormat);
    formData.append('sm_logo_link', selectedFile);
    formData.append('sm_logo_position', values.logoPosition);
    formData.append('sm_banner_link', selectedImage);
    formData.append('sm_ty_header', values.surveyHeader);
    formData.append('sm_ty_message', values.surveyMessage);
    formData.append('sm_is_pii', values.personalInfo);
    formData.append('sm_title_font', parseInt(values.titleFontStyle));
    formData.append('sm_title_font_size', parseInt(titleFontSize));
    formData.append('sm_desc_font', parseInt(values.descriptionFontStyle));
    formData.append('sm_desc_font_size', parseInt(descriptionFontSize));
    formData.append('sm_sec_font', parseInt(values.sectionTitleFontStyle));
    formData.append('sm_sec_font_size', parseInt(sectionTitleFontSize));
    formData.append('sm_que_font', parseInt(values.questionFontStyle));
    formData.append('sm_que_font_size', parseInt(questionFontSize));
    formData.append('sm_option_font', parseInt(values.optionResponseFontStyle));
    formData.append('sm_option_font_size', parseInt(optionsFontSize));
    formData.append('sm_ty_msg_font', parseInt(values.messageFontStyle));
    formData.append('sm_ty_msg_font_size', parseInt(messageFontSize));
    formData.append('sm_ty_header_font', parseInt(values.headerFontStyle));
    formData.append('sm_ty_header_font_size', parseInt(headerFontSize));

    postMethodFile(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_SURVEY_CREATE,
      token,
      formData
    )
      .then((res) => {
        toast.success('Survey dublicated successfully', {
          style: {
            fontSize: '0.875rem',
          },
        });
        setNewSurveyId(res.data.data.sm_pk_id);
        setExpiryDate(res.data.data.sm_expiry_date);
        setNewSurveyCode(res.data.data.sm_unique_code);
        setIsSubmitted(true);

        setTimeout(() => {
          setIsSubmitted(false);
        }, 10000);
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.errors) {
          const errorMessages = Object.values(err.response.data.errors).flatMap(
            (errors) => errors
          );
          const errorMessage = errorMessages.join(' ');
          toast.error(errorMessage, {
            style: {
              fontSize: '0.875rem',
            },
          });
        }
      });
  };

  // get survey Api Call
  const getSurveyInfo = async (surveyId) => {
    const apiUrl = `${process.env.REACT_APP_BASE_URL}v1/survey/${surveyId}/surveyInfo/`;
    getMethod(apiUrl, token)
      .then((res) => {
        console.log('asdadsassddadadd', res.data.data.sm_status);
        values.sm_title = res.data.data.sm_title;
        values.description = res.data.data.sm_description;
        values.surveyHeader = res.data.data.sm_ty_header;
        values.surveyMessage = res.data.data.sm_ty_message;
        values.surveyFormat = res.data.data.sm_format;
        values.logoPosition = res.data.data.sm_logo_position;
        values.personalInfo = res.data.data.sm_is_pii === true ? '1' : '0';
        // char count set if is there...
        setCharCount(res.data.data.sm_description.length);
        setCharCounts(res.data.data.sm_ty_message.length);
        if (res.data.data.sm_status === 'Active') {
          setSurveyStatus(res.data.data.sm_status);
        }

        const smLogoLink =
          process.env.REACT_APP_BASE_URL + res.data.data.sm_logo_link;
        const smBannerLink =
          process.env.REACT_APP_BASE_URL + res.data.data.sm_banner_link;

        axios
          .get(smLogoLink, {
            responseType: 'blob',
          })
          .then((response) => {
            const filebanner = 'logo.png'; // Make sure the filename has a valid extension like '.png'
            const logofile = new File([response.data], filebanner, {
              type: 'image/png',
            });
            setSelectedFile(logofile);
            // setSelectedFiles((prevFiles) => [...prevFiles, logofile]);
          })
          .catch(function (err) {
            if (err.response && err.response.data && err.response.data.errors) {
              const errorMessages = Object.values(
                err.response.data.errors
              ).flatMap((errors) => errors);
              const errorMessage = errorMessages.join(' ');
              toast.error(errorMessage, {
                style: {
                  fontSize: '0.875rem',
                },
              });
            }
          });

        axios
          .get(smBannerLink, {
            responseType: 'blob',
          })
          .then(function (response) {
            const filebanner = 'banner.png'; // Make sure the filename has a valid extension like '.png'
            const bannerfile = new File([response.data], filebanner, {
              type: 'image/png',
            });
            setselectedImage(bannerfile);
          })
          .catch((err) => {
            // console.log("Error:", err);
          });
        setShowContent(true);
      })
      .catch((err) => {
        // console.log("API ERROR");
      });
  };
  useEffect(() => {
    if (surveyId) {
      getSurveyInfo(surveyId);
    }
  }, []);

  const getStyleData = async () => {
    getMethod(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_STYLES,
      token
    )
      .then((res) => {
        const datas = Object.keys(res.data.data).map((key) => ({
          key,
          value: res.data.data[key],
        }));
        const fntstyle = {
          values: datas.map((item) => ({
            id: item.value.fs_pk_id,
            title: item.value.fs_name,
          })),
        };
        setFontSetting(fntstyle.values);
      })
      .catch((err) => {
        console.log('API ERROR');
      });
  };
  useEffect(() => {
    getStyleData();
  }, []);

  /////////////get the surveyIds font data///////////
  const getFontSizeData = async () => {
    getMethod(
      process.env.REACT_APP_BASE_URL +
        process.env.REACT_APP_PREFIX_URL +
        `${surveyId}/style/`, //dynamic servey_code
      token
    )
      .then((res) => {
        if (res.data) {
          values.titleFontStyle = res.data.data.sm_title_font;
          setTitleFontSize(res.data.data.sm_title_font_size);
          values.descriptionFontStyle = res.data.data.sm_desc_font;
          setDescriptionFontSize(res.data.data.sm_desc_font_size);
          values.sectionTitleFontStyle = res.data.data.sm_sec_font;
          setSectionTitleFontSize(res.data.data.sm_sec_font_size);
          values.questionFontStyle = res.data.data.sm_que_font;
          setQuestionsFontSize(res.data.data.sm_que_font_size);
          values.optionResponseFontStyle = res.data.data.sm_opt_font;
          setOptionsFontSize(res.data.data.sm_opt_font_size);
          values.headerFontStyle = res.data.data.sm_ty_header_font;
          setHeaderFontSize(res.data.data.sm_ty_header_font_size);
          values.messageFontStyle = res.data.data.sm_ty_msg_font;
          setMessageFontSize(res.data.data.sm_ty_msg_font_size);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getFontSizeData();
  }, []);

  return (
    <Form onSubmit={EditApply}>
      <Grid container>
        <Grid item xs={12} mt={2}>
          <TextStyles
            options={fontSetting}
            values={values}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography variant="sh3">Survey Title *</Typography>
          <InputBox
            name="sm_title"
            placeholder="Enter Survey Title"
            size="small"
            value={values.sm_title}
            onChange={handleInputChange}
            helperText={errors.sm_title}
            error={errors.sm_title}
          />
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography variant="sh3">Description *</Typography>
          <TextArea
            placeholder="Write Description Here..."
            value={values.description}
            name={'description'}
            maxChars={250}
            charCount={charCount}
            setCharCount={setCharCount}
            helperMsg={errors.description}
            error={errors.description}
            onChange={handleInputChange}
            rows={3}
            cols={35}
          />
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography variant="sh3">Select Survey format *</Typography>
          <Box>
            <RadioGroup
              items={items}
              name="surveyFormat"
              onChange={handleInputChange}
              value={values.surveyFormat}
              error={errors.surveyFormat}
              helperText={errors.surveyFormat}
            />
          </Box>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography variant="sh3">
            Do you want personal information (PI) to be captured and reported
            for the survey respondent?*
          </Typography>
          <RadioGroup
            name="personalInfo"
            onChange={handleInputChange}
            error={errors.personalInfo}
            helperText={errors.personalInfo}
            value={values.personalInfo}
            items={piItems}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="sh3">Upload Logo</Typography>
          <Fileupload
            minFileSize={0}
            maxFileSize={200 * 1024} //200KB
            fileTitle="logo"
            imageSubtitle="Upload logo here"
            imageSubcontent="Only PNG or JPG formats in 240*160px"
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            document_type="image"
            width={156}
            file_type={{
              'image/png': ['.png'],
              'image/jpg': ['.jpg'],
              'image/jpeg': ['.jpeg'],
            }}
          />
          {/* <FileUploader
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
          /> */}
        </Grid>

        {selectedFile && (
          <Grid item xs={12} mt={2}>
            <Typography variant="sh3">Select Logo Position *</Typography>
            <Box>
              <RadioGroup
                name="logoPosition"
                onChange={handleInputChange}
                value={values.logoPosition}
                error={errors.logoPosition}
                helperText={errors.logoPosition}
                items={itemss}
                row
              />
            </Box>
          </Grid>
        )}

        <Grid item xs={12} mt={selectedFile ? 0 : 2}>
          <Typography variant="sh3">Upload Banner</Typography>
          <Fileupload
            minFileSize={0}
            maxFileSize={5 * 1024 * 1024} // 5MB
            fileTitle="banner"
            document_type="image"
            imageSubtitle={'Upload'}
            imageSubcontent={
              'Only PNG or JPG, recommended size is 728*90 PX, Banner will be positioned on the top of the page'
            }
            selectedFile={selectedImage || null}
            setSelectedFile={setselectedImage}
            file_type={{
              'image/png': ['.png'],
              'image/jpg': ['.jpg'],
              'image/jpeg': ['.jpeg'],
            }}
          />
        </Grid>

        <Grid item xs={12} mt={1.75}>
          <Common.Checkbox
            label="Show Thank you page"
            checked={showContent}
            onChange={handleCheckboxChange}
          />
        </Grid>
        {showContent && (
          <React.Fragment>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Typography variant="sh3">Header</Typography>
                <InputBox
                  name="surveyHeader"
                  value={values.surveyHeader}
                  onChange={handleInputChange}
                  placeholder="Thank You"
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} mt={2}>
              <Grid item xs={12}>
                <Typography variant="sh3">Message</Typography>
                <TextArea
                  name="surveyMessage"
                  onChange={handleInputChange}
                  value={values.surveyMessage}
                  helperMsg={errors.surveyMessage}
                  error={errors.surveyMessage}
                  placeholder="Write your message Here..."
                  rows={3}
                  cols={35}
                  maxChars={250}
                  charCount={charCounts}
                  setCharCount={setCharCounts}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        )}

        <Grid container>
          <Grid item xs={12} mt={4} mb={2}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              {duplicateId ? (
                <Common.Button
                  size="small"
                  type="submit"
                  onClick={handleDuplicate}
                >
                  Save
                </Common.Button>
              ) : (
                <Common.Button
                  size="small"
                  type="submit"
                  // disabled={isSaveDisabled}
                  disabled={surveyStatus === 'Active' ? true : false}
                >
                  Save
                </Common.Button>
              )}

              {isSubmitted && (
                <>
                  {newSurveyId && (
                    <Common.Button
                      style={{ marginLeft: 5 }}
                      type="button"
                      onClick={() =>
                        addQuestion(
                          newSurveyId,
                          newSurveyCode,
                          activeStep,
                          expiryDate
                        )
                      }
                    >
                      Next
                    </Common.Button>
                  )}

                  <Common.Button
                    style={{ marginLeft: 15 }}
                    size="small"
                    type="button"
                    onClick={() =>
                      addQuestion(surveyId, survey_code, activeStep, expiryDate)
                    }
                  >
                    Next
                  </Common.Button>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
    </Form>
  );
}
