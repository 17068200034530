import React, { useEffect, useState } from 'react';
import { Box, FormHelperText } from '@mui/material';
import RatingRadio from './RatingTypes/RatingRadio';
import RatingStar from './RatingTypes/RatingStar';
import RatingEmoji from './RatingTypes/RatingEmoji';

const Ratings = ({
  options,
  ratingStyle,
  optionsFontStyle,
  optionsFontSize,
  buttonClicked,
  onChange,
  starSelectedId,
  emojiSelectedId,
  radioValue,
  onClick,
  handleEmojiClick,
  errorMessage,
  value,
  name,
}) => {
  // console.log('errorMessage>>>>', errorMessage);
  const [labelCount, setLabelCount] = useState([]);
  useEffect(() => {
    const newInputs = options.map((option) => ({
      id: option.opt_pk_id,
      title: option.opt_label,
    }));
    setLabelCount(newInputs);
  }, [options]);

  return ratingStyle === 1 || ratingStyle === 4 ? (
    <Box>
      <RatingRadio
        row
        name={name}
        value={value}
        onChange={onChange}
        items={labelCount}
        size="small"
        labelPlacement="bottom"
        ratingStyle={ratingStyle}
        optionsFontStyle={optionsFontStyle}
        optionsFontSize={optionsFontSize}
        errorMessage={errorMessage}
      />
      {errorMessage && (
        <FormHelperText
          variant="body4"
          sx={{
            color: '#d32f2f',
            // mt: 2,
          }}
        >
          {errorMessage}
        </FormHelperText>
      )}
    </Box>
  ) : ratingStyle === 3 || ratingStyle === 6 ? (
    <RatingStar
      options={options}
      ratingStyle={ratingStyle}
      onClick={onClick}
      selectedId={starSelectedId}
      optionsFontStyle={optionsFontStyle}
      optionsFontSize={optionsFontSize}
      errorMessage={errorMessage}
    />
  ) : ratingStyle === 2 || ratingStyle === 5 ? (
    <>
      <RatingEmoji
        options={options}
        ratingStyle={ratingStyle}
        onClick={handleEmojiClick}
        selectedId={emojiSelectedId}
        buttonClicked={buttonClicked}
        optionsFontStyle={optionsFontStyle}
        optionsFontSize={optionsFontSize}
        errorMessage={errorMessage}
      />
    </>
  ) : (
    <></>
  );
};

export default Ratings;
