import axios from 'axios';
export const putMethodFile = async (url, token, values) => {

    const response = await axios({
        method: 'PUT',
        url,
        data: values,
        headers: {
            "Authorization": `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        }
    })
    return response;
}
