import React, { useState, useEffect } from 'react';
import CommonUI from '../../CommonUI';
import { Box, Grid } from '@mui/material';
import CustomTabs from '../../../components/Tabs/Tabs';
import OverAllSurveyReports from './OverAllSurveyReports/OverAllSurveyReports';
import QuestionSpecificReports from './QuestionSpecificReports/QuestionSpecificReports';
import { getMethod } from '../../../api/apiCallingMethods';
import { getAccessToken } from '../auth';
import Footer from '../../../components/Footer/Footer';
// import Filters from './Filters/Filters';
import TabWrapper from './TabWrapper/TabWrapper';

const SurveyAnalytics = () => {
  const options = [
    { id: 1, label: 'All', value: '' },
    { id: 2, label: 'Email', value: 'email' },
    { id: 3, label: 'SMS', value: 'sms' },
    { id: 4, label: 'Whatsapp', value: 'whatsapp' },
  ];

  const searchUrl = window.location.search;
  const searchParams = new URLSearchParams(searchUrl);
  const surveyId = searchParams.get('id');
  // console.log(searchParams.get('id'));

  const tabLabels = ['Overall Survey Report', 'Question Specific Report'];
  const [isLoading, setIsLoading] = useState(false);
  // filter
  // const [rangeStartDate, setRangeStartDate] = useState(null);
  // const [rangeEndDate, setRangeEndDate] = useState(null);

  const [dateVal, setDateVal] = useState({
    fromDate: '',
    toDate: '',
  });

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setDateVal({ ...dateVal, [name]: value });
  };

  console.log(dateVal);

  const [channelName, setChannelName] = useState(options[0]);

  const [participantData, setParticipantData] = useState({});
  const [stateWiseparticipantData, setStateWiseparticipantData] = useState([]);
  const [submissionReportData, setSubmissionReportData] = useState([]);
  const [channelReportData, setChannelReportData] = useState([]);

  const [questionAnalyticsData, setQuestionAnalyticsData] = useState([]); //question

  const token = getAccessToken();
  const [gettingAllSurveyData, setGettingAllSurveyData] = useState([]);
  const [surveyName, setSurveyName] = useState('');
  let changedSurveyID = surveyName.id || surveyId;
  // Api Calling (Get All Survey)
  useEffect(() => {
    getAllSurvey();
  }, []);

  useEffect(() => {
    if (surveyId) {
      console.log(surveyId);
      getParticipantData();
      getStateWiseData();
      // getAgeWiseData();
      getSubmissionReportData();
      getChannelWiseReportData();
      getQuestionWiseReportData();
    }
  }, []);
  const getAllSurvey = async () => {
    setIsLoading(true);
    try {
      const res = await getMethod(
        process.env.REACT_APP_BASE_URL + process.env.REACT_APP_SURVEY_ALL,
        token
      );
      if (res.data) {
        const convertedData = res.data.data.map((item) => ({
          id: item.sm_pk_id,
          label: item.sm_title,
          value: item.sm_title,
        }));

        setGettingAllSurveyData(convertedData);

        // Set surveyName based on the first element of gettingAllSurveyData
        if (convertedData.length > 0) {
          setSurveyName(convertedData[0]);
        }

        if (surveyId !== null || surveyId !== undefined) {
          convertedData.map((item) => {
            if (item.id == surveyId) {
              setSurveyName(item);
            }
          });
        }

        setIsLoading(false);
      } else {
        setGettingAllSurveyData([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching survey data:', error);
      setGettingAllSurveyData([]);
      setIsLoading(false);
    }
  };
  // ==========================Participant API Call============================================
  const getParticipantData = async () => {
    setIsLoading(true);
    await getMethod(
      process.env.REACT_APP_BASE_URL +
        process.env.REACT_APP_ANALYTICS_BASE_URL +
        process.env.REACT_APP_SURVEY_COMPLETION_ANALYTICS +
        changedSurveyID +
        `&channel=${channelName.value}&start_date=${dateVal.fromDate}&end_date=${dateVal.toDate}`,
      token
    )
      .then((res) => {
        if (res.data) {
          setParticipantData(res.data.data);
          setIsLoading(false);
        } else {
          setParticipantData([]);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setParticipantData([]);
        setIsLoading(false);
      });
  };
  // ==========================State Wise API Call============================================

  const getStateWiseData = async () => {
    setIsLoading(true);
    await getMethod(
      process.env.REACT_APP_BASE_URL +
        process.env.REACT_APP_ANALYTICS_BASE_URL +
        process.env.REACT_APP_SURVEY_COMPLETION_STATE_ANALYTICS +
        changedSurveyID +
        `&channel=${channelName.value}&start_date=${dateVal.fromDate}&end_date=${dateVal.toDate}`,
      token
    )
      .then((res) => {
        if (res.data) {
          setStateWiseparticipantData(res.data.data);
          setIsLoading(false);
        } else {
          setStateWiseparticipantData([]);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setStateWiseparticipantData([]);
        setIsLoading(false);
      });
  };

  // ==========================Submission Report API Call============================================
  const getSubmissionReportData = async () => {
    console.log('asdadd');
    setIsLoading(true);
    await getMethod(
      process.env.REACT_APP_BASE_URL +
        process.env.REACT_APP_ANALYTICS_BASE_URL +
        process.env.REACT_APP_SURVEY_ANALYTICS_SUBMISSION_REPORT +
        changedSurveyID +
        `&channel=${channelName.value}&start_date=${dateVal.fromDate}&end_date=${dateVal.toDate}`,
      token
    )
      .then((res) => {
        if (res.data) {
          setSubmissionReportData(res.data.data);
          setIsLoading(false);
        } else {
          setSubmissionReportData([]);
          setIsLoading(false);
        }
      })

      .catch((err) => {
        setSubmissionReportData([]);
        setIsLoading(false);
      });
  };

  // ==========================Channel API Call============================================
  const getChannelWiseReportData = async () => {
    setIsLoading(true);
    await getMethod(
      process.env.REACT_APP_BASE_URL +
        process.env.REACT_APP_ANALYTICS_BASE_URL +
        process.env.REACT_APP_SURVEY_ANALYTICS_Channel_REPORT +
        changedSurveyID +
        `&channel=${channelName.value}&start_date=${dateVal.fromDate}&end_date=${dateVal.toDate}`,
      token
    )
      .then((res) => {
        if (res.data) {
          setChannelReportData(res.data.data);
          setIsLoading(false);
        } else {
          setChannelReportData([]);
          setIsLoading(false);
        }
      })

      .catch((err) => {
        setChannelReportData([]);
        setIsLoading(false);
      });
  };

  // =============================QuestionWise Analytics Api Call====================================
  const getQuestionWiseReportData = async () => {
    setIsLoading(true);
    await getMethod(
      process.env.REACT_APP_BASE_URL +
        process.env.REACT_APP_ANALYTICS_BASE_URL +
        process.env.REACT_APP_SURVEY_ANALYTICS_QUESTIONS_WISE +
        changedSurveyID +
        `&channel=${channelName.value}&start_date=${dateVal.fromDate}&end_date=${dateVal.toDate}`,
      token
    )
      .then((res) => {
        if (res.data) {
          setQuestionAnalyticsData(res.data.data);
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        } else {
          setQuestionAnalyticsData([]);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setQuestionAnalyticsData([]);
        setIsLoading(false);
      });
  };

  const onSurveyNameChange = (newValue) => {
    setSurveyName(newValue);
  };
  const onChannelNameChange = (newValue) => {
    setChannelName(newValue);
  };

  const handleGoFilterClick = () => {
    // console.log(surveyName.id);
    getParticipantData();
    getStateWiseData();
    getSubmissionReportData();
    getChannelWiseReportData();
    getQuestionWiseReportData();
  };

  const tabContent = [
    <TabWrapper
      handleGoFilterClick={handleGoFilterClick}
      gettingAllSurveyData={gettingAllSurveyData}
      dateVal={dateVal}
      handleDateChange={handleDateChange}
      surveyName={surveyName}
      channelName={channelName}
      options={options}
      handleOnSurveyChange={onSurveyNameChange}
      handleOnChannelChange={onChannelNameChange}
    >
      <OverAllSurveyReports
        gettingAllSurveyData={gettingAllSurveyData}
        participantData={participantData}
        stateWiseparticipantData={stateWiseparticipantData}
        submissionReportData={submissionReportData}
        channelReportData={channelReportData}
      />
    </TabWrapper>,
    <TabWrapper
      handleGoFilterClick={handleGoFilterClick}
      gettingAllSurveyData={gettingAllSurveyData}
      dateVal={dateVal}
      handleDateChange={handleDateChange}
      surveyName={surveyName}
      channelName={channelName}
      options={options}
      handleOnSurveyChange={onSurveyNameChange}
      handleOnChannelChange={onChannelNameChange}
    >
      <QuestionSpecificReports
        token={token}
        dateVal={dateVal}
        surveyName={surveyName}
        channelName={channelName}
        questionAnalyticsData={questionAnalyticsData}
      />
    </TabWrapper>,
  ];

  return (
    <CommonUI headertext="Analytics">
      <Grid container mt={0.5}>
        <Grid item xs={12}>
          <CustomTabs
            tabLabels={tabLabels}
            tabContent={tabContent}
            tabsPlace="center" //tabs place(justifyContent:'center')
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'end', mt: 3, mb: 3 }}>
          <Footer />
        </Box>
      </Grid>
    </CommonUI>
  );
};

export default SurveyAnalytics;
