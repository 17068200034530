import { Box, InputLabel, TextField, Typography } from '@mui/material'
import React from 'react'
import { Common } from '../Common';
import useStyles from './styles'; 

const InputField = (props) => {
  const classes = useStyles();
  const { name, label, value, error = null, onChange, defaultValue, inputProps, tooltip, isTooltip, tooltipContent, multiline, placement, rows, ...other } = props;
  return (
    <>
      <InputLabel className={classes.formLabel}>
        <Typography variant="sh4">
          {label}
          {isTooltip ?
            <Common.ReusableTooltip
              title={
                <Typography variant="sh4">
                  {tooltipContent}
                </Typography>
              }
              placement={placement}
            >
              {tooltip}
            </Common.ReusableTooltip> : ""
          }
        </Typography>
      </InputLabel>
      <Box className={classes.root} component="div">
        <TextField
          fullWidth
          classes={{
            root: classes.root,
          }}
          inputProps={{
            className: classes.root,
            style: { fontSize: '12px', fontWeight: '400', padding: '12px !important' },
          }}
          InputLabelProps={{
            shrink: true,
          }}
          variant='outlined'
          name={name}
          value={value}
          onChange={onChange}
          {...other}
          {...(error && { error: true, helperText: error })}
          multiline={multiline === true ? true : false}
          rows={multiline === true ? rows : 0}
        />
      </Box>
    </>
  )
}

export default InputField;