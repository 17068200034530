import React from 'react';
import { Box,ListItemButton, Typography, } from '@mui/material';
import useStyles from './styles';


const Hexagon = (styleProps) => {
    // const { channelIcon, channelTitle, colorHover}=props;
    const props = {
        channelTitle: styleProps.channelTitle,
        channelIcon: styleProps.channelIcon,
        hoverColor: styleProps.myColor,
        onClick:styleProps.onClick,
    };
    // console.log("color" + props.colorHover)
    const classes = useStyles(props);
    return (
        <Box
            component="div"
            sx={{
                position:'absolute',
                top:'8px',
                left:'0px',
                borderRadius:'2px',
                width:'68px',
                height:'39px',
                borderRight: localStorage.getItem('theme') === 'light' ? `1px solid ${props.hoverColor}` : `1px solid #FFFFFF`,
                borderLeft: localStorage.getItem('theme') === 'light' ? `1px solid ${props.hoverColor}` : `1px solid #FFFFFF`,
                color:props.hoverColor,
                zIndex: '0 !important',
                '&:before,&:after': {
                    content: "''",
                    border: 'inherit',
                    color: localStorage.getItem('theme') === 'light'?`${props.hoverColor}`:`#FFFFFF`,
                    position: 'absolute',
                    top: '-0.4px',
                    left: '-0.4px',
                    borderRadius: 'inherit',
                    width: '68px',
                    height: '39px',
                },
                '&:before':{
                    transform: 'rotate(60deg)',
                },
                '&:after': {
                    transform: 'rotate(-60deg)',
                },
                '&:hover,&:hover:after,&:hover:before':{
                    background: props.hoverColor,
                    zIndex:'-1',
                    color:'#F4F4F4'
                },
                '& .fh-icon-sms,& .fh-icon-mail,& .fh-icon-web-notification,& .fh-icon-mic,& .fh-icon-mobile-notification,& .fh-icon-question-mark,& .fh-icon-whatsapp-filled,& .fh-icon-messenger': {
                    fontSize:'30px',
                    position:'absolute',
                    top:'5px',
                    left:'18px',
                },
            }}
        >
            <ListItemButton 
                // sx={{height:'40px'}} 
                disableRipple 
                onClick={props.onClick}
            >
                <Typography component="span">{props.channelIcon}</Typography>
            </ListItemButton>
            <Typography component="span" variant='body5' className={classes.channelTitle}>{props.channelTitle}</Typography>
        </Box>
    )
}
export default Hexagon;
