import React from 'react';
import { Box, Dialog, DialogContent, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    padding: theme.spacing(2),
    position: 'absolute', // This can be removed to rely on MUI's default centering
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  smallContent: {
    width: '100%',
  },
  mediumContent: {
    width: '100%',
  },
  largeContent: {
    width: '100%',
  },
}));

export default function Popup(props) {
  const { children, openPopup, setOpenPopup, maxWidth = 'md' } = props;
  const classes = useStyles();

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery((theme) =>
    theme.breakpoints.between('sm', 'md')
  );
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return (
    <Box>
      <Dialog
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        fullWidth
        maxWidth={maxWidth}
        classes={{ paper: classes.dialogWrapper }}
        componentsProps={{
          backdrop: { style: { backgroundColor: 'rgb(0 0 0 / 20%)' } },
        }}
        // BackdropProps={{ style: { backgroundColor: 'rgb(0 0 0 / 20%)' } }}
        PaperProps={{
          style: {
            // background: 'transparent',
            boxShadow: 'none',
            margin: 0,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        <DialogContent
          className={`${classes.dialogContent} ${
            isSmallScreen ? classes.smallContent : ''
          } ${isMediumScreen ? classes.mediumContent : ''} ${
            isLargeScreen ? classes.largeContent : ''
          }`}
        >
          {children}
        </DialogContent>
      </Dialog>
    </Box>
  );
}
