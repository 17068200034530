import { Typography } from '@mui/material';
import React from 'react';
import useStyles from './styles';

const Status = (props) => {
    const { statusLabel, state, colored} = props;
    const classes = useStyles();
    switch(state){
        case 'active':
            return colored === true ? <Typography variant="body4" className={`${classes.active} ${classes.wrapperBox}`}>{statusLabel}</Typography> : <Typography variant="body4" className={`${classes.activeDefault} ${classes.wrapperBox}`}>{statusLabel}</Typography>
        case 'success':
            return colored === true ? <Typography variant="body4" className={`${classes.success} ${classes.wrapperBox}`}>{statusLabel}</Typography> : <Typography variant="body4" className={`${classes.successDefault} ${classes.wrapperBox}`}>{statusLabel}</Typography>
        case 'expired':
            return colored === true ? <Typography variant="body4" className={`${classes.expired} ${classes.wrapperBox}`}>{statusLabel}</Typography> : <Typography variant="body4" className={`${classes.expiredDefault} ${classes.wrapperBox}`}>{statusLabel}</Typography>
        case 'suspended':
            return colored === true ? <Typography variant="body4" className={`${classes.suspended} ${classes.wrapperBox}`}>{statusLabel}</Typography> : <Typography variant="body4" className={`${classes.suspendedDefalut} ${classes.wrapperBox}`}>{statusLabel}</Typography>
        default:
            return colored === true ? <Typography variant="body4" className={`${classes.bydefault} ${classes.wrapperBox}`}>{statusLabel}</Typography> : <Typography variant="body4" className={`${classes.bydefaultDefault} ${classes.wrapperBox}`}>{statusLabel}</Typography>
    }
}

export default Status
