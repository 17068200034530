import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme)=>({
    cardBox:{
        // maxWidth: 272,
        height: '132px !important',
        overflow:"hidden",
        border : '1px solid #C6C6C6',
        // : props => props.border,
        borderRadius:'8px',
        backgroundColor: `${theme.palette.onSurface.main} !important`,
        // textAlign: "center",
        boxShadow:'none !important',
    }
  }));




export default function CardType2(props) {
    const classes = useStyles(props);
  return (
    // <Box className={classes.cardBox}>
       <Card className={classes.cardBox} 
       sx={{
        display:"flex", 
        alignItems:"center", 
        justifyContent:"center"
      }}
       >
      <CardContent sx={{textAlign:"center"}}> 
      <Typography variant="sh3" component="div" color="black.light">
      
      {props.title}
        
      </Typography>
      <Typography variant="sh1" component="div" color="black.main" mt={2}>
        {props.number}
      </Typography>
      
      
     </CardContent>
      </Card>
    // </Box>
  );
}
