import { Grid, Typography } from '@mui/material';
import React from 'react';
import CommonChartContainer from '../CommonChartContainer/CommonChartContainer';
import {
  BarChart,
  DoughnutChart,
} from '../../../../../../components/common/charts';

const NpsTypeChart = ({ item }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <CommonChartContainer>
          <Typography variant="sh3">Submissions Breakdown</Typography>
          <BarChart
            // min={0}
            // max={100}
            legendDisplay={false}
            barWidth={'100%'}
            barHeight={'100%'}
            barLabels={[
              ['Not Submitted', ' by known'],
              ['Submitted', ' by known'],
              ['Submitted', ' by Un-known'],
              ['Not Submitted', ' by Un-known'],
            ]}
            datasets={[
              {
                data: [
                  item.Not_Submitted_by_known,
                  item.Submitted_by_known,
                  item.Submitted_by_unknown,
                  item.Not_Submitted_by_unknown,
                ],
                backgroundColor: ['#8B45D8', '#D86445', '#458FD8', '#D8459C'],
              },
            ]}
          />
        </CommonChartContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <CommonChartContainer>
          <Typography variant="sh3">Respondent %</Typography>
          <BarChart
            min={0}
            max={100}
            legendDisplay={false}
            barWidth={'100%'}
            barHeight={'100%'}
            barLabels={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
            datasets={[
              {
                label: '%',
                data: item.label_counts,
                backgroundColor: [
                  '#8B45D8',
                  '#8B45D8',
                  '#8B45D8',
                  '#8B45D8',
                  '#8B45D8',
                  '#8B45D8',
                  '#D86445',
                  '#D86445',
                  '#458FD8',
                  '#458FD8',
                ],
              },
            ]}
          />
        </CommonChartContainer>
      </Grid>
      <Grid item xs={12} md={6} mt={2}>
        <CommonChartContainer>
          <Typography variant="sh3">Responders types</Typography>
          <DoughnutChart
            labels={[
              `Detractors  - ${Math.round(item.second_graph.Detractors)}%`,
              `Passives - ${Math.round(item.second_graph.Passives)}%`,
              `Promoters - ${Math.round(item.second_graph.Promoters)}%`,
            ]}
            height={'auto'}
            datasets={[
              {
                label: '',
                data: [
                  Math.round(item.second_graph.Detractors),
                  Math.round(item.second_graph.Passives),
                  Math.round(item.second_graph.Promoters),
                ],
                backgroundColor: ['#8B45D8', '#D86445', '#458FD8'],
                borderColor: ['#8B45D8', '#D86445', '#458FD8'],
                borderWidth: 1,
                hoverOffset: 4,
              },
            ]}
          />
        </CommonChartContainer>
      </Grid>
    </Grid>
  );
};

export default NpsTypeChart;
