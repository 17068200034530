import { Grid } from '@mui/material';
import React from 'react';
import DisplaySurvey from './DisplaySurvey';

const ClientView = ({ smId, activeStep, survey_code, onNextClick }) => {
  return (
    <Grid container mt={4}>
      <Grid item xs={12} md={12} sm={12}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <DisplaySurvey
              smId={smId}
              activeStep={activeStep}
              onNextClick={onNextClick}
              survey_code={survey_code}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ClientView;
