import React from 'react';
import { Routes as ReactRoutes, Route } from 'react-router-dom';
import { routes } from './routesPath';
const Routes = () => {
  return (
    <ReactRoutes>
      {routes.map((route) => (
        <Route
          key={route.path}
          exact
          path={route.path}
          element={route.element}
        />
      ))}
    </ReactRoutes>
  );
};

export default Routes;
