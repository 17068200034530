import { makeStyles } from "@mui/styles";
export default makeStyles((theme) => ({
  root: {
    // borderRadius:'5px',

    "& .MuiSelect-select": {
      padding: "8px 16px",
    },
    "& .MuiOutlinedInput-input": {
      fontSize: "16px !important",
      fontWeight: "400 !important",
      color:
    localStorage.getItem("theme") === "light"
      ? `${theme.palette.black.main} !important`
      : `${theme.palette.white.main} !important`,
    },
    "& .MuiInputLabel-root": {
      fontSize: "16px !important",
      fontWeight: "400 !important",
      color:
    localStorage.getItem("theme") === "light"
      ? `${theme.palette.black.main} !important`
      : `${theme.palette.white.main} !important`,
    },
    "&:hover .MuiOutlinedInput-input": {
      fontSize: "16px !important",
      fontWeight: "400 !important",
      color:
    localStorage.getItem("theme") === "light"
      ? `${theme.palette.black.main} !important`
      : `${theme.palette.white.main} !important`,
    },
    "&:hover .MuiInputLabel-root": {
      fontSize: "16px !important",
      fontWeight: "400 !important",
      color:
    localStorage.getItem("theme") === "light"
      ? `${theme.palette.black.main} !important`
      : `${theme.palette.white.main} !important`,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      fontSize: "16px !important",
      fontWeight: "400 !important",
      color:
    localStorage.getItem("theme") === "light"
      ? `${theme.palette.black.main} !important`
      : `${theme.palette.white.main} !important`,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      fontSize: "16px !important",
      fontWeight: "400 !important",
      color:
    localStorage.getItem("theme") === "light"
      ? `${theme.palette.black.main} !important`
      : `${theme.palette.white.main} !important`,
    },
  },
  openMenu: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderLeft: `1px solid ${theme.palette.black.light} !important`,
      borderRight: `1px solid ${theme.palette.black.light} !important`,
      borderTop: `1px solid ${theme.palette.black.light} !important`,
      borderBottom: "none !important",
      borderBottomLeftRadius: "0 !important",
      borderBottomRightRadius: "0 !important",
      borderTopLeftRadius: "6px !important",
      borderTopRightRadius: "6px !important",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderLeft: `1px solid ${theme.palette.black.light} !important`,
      borderRight: `1px solid ${theme.palette.black.light} !important`,
      borderTop: `1px solid ${theme.palette.black.light} !important`,
      borderBottom: "none !important",
      borderBottomLeftRadius: "0 !important",
      borderBottomRightRadius: "0 !important",
      borderTopLeftRadius: "6px !important",
      borderTopRightRadius: "6px !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderLeft: `1px solid ${theme.palette.black.light} !important`,
      borderRight: `1px solid ${theme.palette.black.light} !important`,
      borderTop: `1px solid ${theme.palette.black.light} !important`,
      borderBottom: "none !important",
      borderBottomLeftRadius: "0 !important",
      borderBottomRightRadius: "0 !important",
      borderTopLeftRadius: "6px !important",
      borderTopRightRadius: "6px !important",
    },
  },
  closeMenu: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.black.light} !important`,
      borderRadius: "6px !important",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.black.light} !important`,
      borderRadius: "6px !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.black.light} !important`,
      borderRadius: "6px !important",
    },
  },
  menuStyle: {
    "& .MuiMenu-paper": {
      borderTopLeftRadius: "0 !important",
      borderTopRightRadius: "0 !important",
      borderBottomLeftRadius: "6px !important",
      borderBottomRightRadius: "6px !important",
      boxShadow: "none !important",
    },
  },
  selectMenu: {
    borderLeft: `1px solid ${theme.palette.black.light} !important`,
    borderRight: `1px solid ${theme.palette.black.light} !important`,
    borderBottom: `1px solid ${theme.palette.black.light} !important`,
    borderBottomLeftRadius: "6px !important",
    borderBottomRightRadius: "6px !important",
    borderTopLeftRadius: "0 !important",
    borderTopRightRadius: "0 !important",
    boxShadow: "0 !important",
    // padding: "8px 16px",
  },
  label: {
    fontWeight: "500 !important",
    fontSize: "16px!important",
    marginBottom: theme.spacing(1),
  },
  placeholder: {
    color:
      localStorage.getItem("theme") === "light"
        ? `${theme.palette.grey.main} !important`
        : ``,
    marginTop:"10px !important",
  },
  tagLabel:{
    color:
    localStorage.getItem("theme") === "light"
      ? `${theme.palette.black.main} !important`
      : `${theme.palette.white.main} !important`,
  },
  rowTagLabel:{
    color:
    localStorage.getItem("theme") === "light"
      ? `${theme.palette.black.main} !important`
      : `${theme.palette.white.main} !important`,
      marginRight:'8px !important',
  },
  

  helpText:{
   color:`${theme.palette.grey.main} !important`
  },
  options: {
    fontSize: "16px !important",
  },
  outlinedInput: {
    fontSize: "12px !important",
    fontWeight: "500 !important",
    padding: "12px !important",
  },

  iconStyle: {
    userSelect: "none",
    width: "1em",
    height: "1em",
    flexShrink: 0,
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontSize: "16px !Important",
    fontWeight: "400 !important",
    position: "absolute",
    right: "16px",
    top: "38.5% !important",
    pointerEvents: "none",
    color: `${theme.palette.black.main}`,
    cursor: "pointer",
  },
  divider: {
    background: `${theme.palette.grey.light}`,
  },
  
  ChipSelect:{
    "& .MuiButtonBase-root-MuiChip-root": {
      marginTop:'0 !important'
  }
}

}));
