// FormContext.js
import React, { createContext, useState } from 'react';

const FormContext = createContext();

const FormProvider = ({ children }) => {
  const [formState, setFormState] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log('Form submitted:', formState);
  // };

  return (
    <FormContext.Provider
      value={{
        formState,
        handleChange,
        // handleSubmit
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export { FormContext, FormProvider };
