import { makeStyles } from "@mui/styles";
export default makeStyles(theme=>({
    root: {
        // border: '1px solid #ddd !important',
        borderRadius: '4px',
        marginTop: '4px !important',
        '& input': {
            // fontSize: '10px !important',
            fontWeight: '500 !important'

        },
        "& input::placeholder": {
            fontSize: ".85rem",
            fontWeight: '500 !important'
        },
    },
    tagLabel: {
        minWidth:'110px',
        // fontSize: "18px !important",
        marginTop: '4px !important',
        fontWeight: '500 !important',
    }
}));