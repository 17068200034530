import React from 'react'
import { Box, Typography } from '@mui/material'
import imgSrc from '../../assets/img/FirstHiveLogo.png';
const EndUserFooter = () => {
  return (
    <Box
    >
      <Typography
        variant="body4"
        sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        Powered By{" "}
        <img src={imgSrc} style={{ height: "1.8rem" }} alt="FirstHive" />
      </Typography>
    </Box>
  );
}

export default EndUserFooter;
