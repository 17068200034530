import { makeStyles } from "@mui/styles";
export default makeStyles(theme=>({
    root: {
        color: theme.palette.mode = 'light' ? `${theme.palette.blue.dark} !important` : `${theme.palette.blue.main} !important`,
        "&$checked": {
            color: theme.palette.mode = 'light' ? `${theme.palette.blue.dark} !important` : `${theme.palette.blue.main} !important`,
        },
        '& .MuiSvgIcon-root': {fontSize: 24 },
        '& MuiFormControlLabel-label':{
            fontSize:'16px !important',
        }
        
    },
    checked: {}
}));