import ClientView from '../pages/Survey/ClientView/ClientView';
import {
  Summeryreport,
  CreateSurvey,
  EditBasicInfo,
  SurveyAnalytics,
  ManageFonts,
} from '../pages/pages';
export const routes = [
  { path: '/', element: <Summeryreport /> },
  { path: '/survey/summary', element: <Summeryreport /> },
  { path: '/surveycreation', element: <CreateSurvey /> },
  { path: '/surveyEdit', element: <EditBasicInfo /> },
  { path: '/surveyAnalytics', element: <SurveyAnalytics /> },
  { path: '/ClientSurveyView', element: <ClientView /> },
  { path: '/manage-fonts', element: <ManageFonts /> },
];
