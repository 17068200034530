import {
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';
import {
  colorArray10,
  npsSmileyFilledIcons,
  npsSmileyOutlinedIcons,
} from '../../AddQuestions/AnswerFormat/NpsFormatWithSmileys/utility/utility';
const NpsSmileyFormat = ({
  options,
  label,
  optionsFontStyle,
  optionsFontSize,
  selectedSmileyId,
  setSelectedSmileyId,
  onClick,
  error,
}) => {
  const newFeelingArray = npsSmileyOutlinedIcons.map((option, index) => ({
    id: index,
    // title: option.opt_text,
    color: colorArray10[index],
    icon: npsSmileyOutlinedIcons[index],
    iconOutline: npsSmileyFilledIcons[index],
  }));
  return (
    <FormControl
      component="fieldset"
      // sx={{ border: '1px solid #f00' }}
      fullWidth
    >
      <Grid container alignItems={'center'}>
        <Grid item xs={12}>
          <Typography
            variant="body3"
            sx={{
              fontFamily: optionsFontStyle
                ? `${optionsFontStyle}!important`
                : 'Poppins',
              fontSize: optionsFontSize ? `${optionsFontSize}` : '1rem',
              mr:3
            }}
          >
            {options[0].opt_label}
          </Typography>
          {newFeelingArray.map((path, index) => {
            const val = index + 1;
            return (
              <IconButton onClick={() => onClick(val)} key={val}>
                <Icon
                  className={
                    selectedSmileyId
                      ? selectedSmileyId === val
                        ? `${path.iconOutline}`
                        : `${path.icon}`
                      : `${path.icon}`
                  }
                  sx={{
                    color: selectedSmileyId
                      ? selectedSmileyId === val
                        ? `${path.color}`
                        : `#C6C6C6`
                      : `${path.color}`,
                    fontSize: '2rem',
                  }}
                />
              </IconButton>
            );
          })}
          <Typography
            variant="body3"
            sx={{
              fontFamily: optionsFontStyle ? `${optionsFontStyle}` : 'Poppins',
              fontSize: optionsFontSize ? `${optionsFontSize}` : '1rem',
              ml:3
            }}
          >
            {options[1].opt_label}
          </Typography>
        </Grid>
        <Grid>
          {error && (
            <FormHelperText
              variant="body4"
              color="error"
              sx={{ color: '#d32f2f' }}
            >
              {error}
            </FormHelperText>
          )}
        </Grid>
      </Grid>
    </FormControl>
  );
};
export default NpsSmileyFormat;
