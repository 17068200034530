import React, { useState, useEffect, useContext, lazy, Suspense } from 'react';
import { Grid, Box } from '@mui/material';
import { EditorState, convertToRaw } from 'draft-js';
import { Common } from '../../../components/common/Common';
import { postMethod, getMethod } from '../../../api/apiCallingMethods';
import { getAccessToken } from '../auth';
import draftToHtml from 'draftjs-to-html';
import {
  RatingFormat,
  Count,
  NpsStyleFormat,
  questionsTypeList,
} from './utility';
import { ToastContainer, toast } from 'react-toastify';
import { FormContext } from '../../../context/formContext';
const AddQuestionButtonTemplate = lazy(() =>
  import('./AddQuestionButtonTemplate')
);
const SampleQuestion = lazy(() => import('./SampleQuestion'));

const AddQuestions = ({
  smId,
  onNextClick,
  survey_code,
  handleAddQuesBackButton,
  sectionId,
  setSectionId,
  expiryDate,
  section,
}) => {
  const token = getAccessToken();
  const [editQuestionData, setEditQuestionData] = useState({});
  //Showing Icons on hover
  const [isHovering, setIsHovering] = useState(false);
  const [selectedQuesId, setSelectedQuesId] = useState('');
  //To append list
  const [questionListResp, setQuestionsListRsp] = useState([]);

  //Flag to create section for first time
  const [isFirstSecCreated, setIsFirstSecCreated] = useState(false);
  const [questionTypeValue, setQuestionTypeValue] = useState(
    questionsTypeList[0]
  );

  // console.log('questionTypeValue>>>>', questionTypeValue);

  // selecting rating style type
  const [ratingStyleType, setRatingStyleType] = useState(RatingFormat[0]); //add questions props
  const [editRatingStyleFormat, setEditRatingStyleFormat] = useState(
    RatingFormat[0]
  );

  const [npsRatingType, setNpsRatingType] = useState(NpsStyleFormat[0]); //nps rating type optionfor edit options
  const [npsRatingStyleFormat, setNpsRatingStyleFormat] = useState(
    NpsStyleFormat[0]
  ); //nps rating type options for creation options

  const [nextButtonEnabled, setNextButtonEnabled] = useState(false);
  //For getting question text
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [questionText, setQuestiontext] = useState('');
  const [questionLength, setQuestionLength] = useState();

  //Click question button with +
  const [addQuestionClicked, setAddQuestionClicked] = useState(false);

  //For mandatory checkbox
  const [mandatoryChecked, setMandatoryChecked] = useState(false);

  //To track question count
  const [questionCount, setQuestionCount] = useState(1);

  //To store options for SingleSelectionType & MultipleSelectionType
  const [multipleOptions, setMultipleOptions] = useState([]);
  const [inputs, setInputs] = useState(['', '']);

  //To store labels for rating
  const [ratingLabelOptions, setRatingLabelOptions] = useState([]);
  const [ratingStyle, setRatingStyle] = useState(RatingFormat[0]);
  const [countValue, setCountValue] = useState(Count[0]);
  const [addLabelsChecked, setAddLabelsChecked] = useState(false);
  const [labels, setLabels] = useState(['', '', '']);

  const [toastDisplayed, setToastDisplayed] = useState(false);

  // survey Id
  const [sId, setSId] = useState('');
  const [surveyStatus, setSurveyStatus] = useState('');

  const [npsType, setNpsType] = useState('');
  const { formState } = useContext(FormContext);
  const npsHandleType = (event) => {
    setNpsType(event.target.value);
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const id = url.searchParams.get('id');
    setSId(id);
  }, []); //[sId]

  useEffect(() => {
    if (sId) {
      questionListApi(sId);
    }
  }, [sId, questionText]);

  useEffect(() => {
    // Check if the question type changed to Single Selection or Multiple Selection
    if (
      questionTypeValue.label === 'Single Selection' ||
      questionTypeValue.label === 'Multiple Selection'
    ) {
      // Reset the multipleOptions and inputs when changing to Single Selection or Multiple Selection
      setMultipleOptions([]);
      setInputs(['', '']);
    }
  }, [questionTypeValue]);

  const clickNextButton = () => {
    onNextClick(sId, survey_code, expiryDate);
  };

  const addQuestion = () => {
    setAddQuestionClicked(true);
  };

  //call status update Api
  const callStatusUpdateApi = () => {
    if (sId) {
      getMethod(
        process.env.REACT_APP_BASE_URL +
          process.env.REACT_APP_PREFIX_URL +
          sId +
          process.env.REACT_APP_UPDATE_STATUS,
        token
      )
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const clickSaveButton = () => {
    setNextButtonEnabled(true);
    callStatusUpdateApi();
  };

  // create new section
  const createSection = (title, serialNo) => {
    //let secId ;
    postMethod(
      process.env.REACT_APP_BASE_URL +
        process.env.REACT_APP_PREFIX_URL +
        smId +
        process.env.REACT_APP_SURVEY_CREATE_SECTION,
      token,
      {
        sec_title: title,
        sec_serial_no: serialNo,
      }
    )
      .then((res) => {
        if (res) {
          setSectionId(res.data.data.sec_pk_id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // create new questions handler methods
  const createQuestion = (
    text,
    answerType,
    questionCount,
    isIndependent,
    isMandatory
  ) => {
    let options = [];
    let ans_id = 0;
    let placeholder = '';
    let optionType = '';
    if (answerType.label === 'Short Answer') {
      ans_id = 1;
      options = [];
      placeholder = formState.placeholder
        ? formState.placeholder
        : 'Enter your answer here';
      optionType = '';
    } else if (answerType.label === 'Paragraph') {
      ans_id = 2;
      options = [];
      placeholder = formState.placeholder
        ? formState.placeholder
        : 'Enter your answer here';
      optionType = '';
    } else if (answerType.label === 'NPS') {
      ans_id = 3;
      placeholder = 'nps';
      optionType = npsRatingStyleFormat.id;
      options = [
        {
          opt_serial_no: 1,
          opt_text: 1,
          opt_label: formState.npsDefaultFirstLabel
            ? formState.npsDefaultFirstLabel
            : 'Not Likely',
        },
        {
          opt_serial_no: 2,
          opt_text: 2,
          opt_label: formState.npsDefaultLastLabel
            ? formState.npsDefaultLastLabel
            : 'Extremely likely',
        },
      ];
    } else if (answerType.label === 'Single Selection') {
      ans_id = 4;
      options = multipleOptions;
      placeholder = 'single selection';
      optionType = '';
    } else if (answerType.label === 'Multiple Selection') {
      ans_id = 5;
      options = multipleOptions;
      placeholder = 'multiple selection';
      optionType = '';
    } else if (answerType.label === 'Email') {
      ans_id = 6;
      options = [];
      placeholder = formState.placeholder
        ? formState.placeholder
        : 'Enter your answer here';
      optionType = '';
    } else if (answerType.label === 'Number') {
      ans_id = 7;
      options = [];
      placeholder = formState.placeholder
        ? formState.placeholder
        : 'Enter your answer here';
      optionType = '';
    } else if (answerType.label === 'Rating') {
      ans_id = 8;
      if (addLabelsChecked === true) {
        const newOptions = [...ratingLabelOptions];
        let x = Number(countValue.label);
        let i = 0;
        while (i < x) {
          newOptions[i] = {
            opt_serial_no: i,
            opt_text: i + 1,
            opt_label: labels[i],
          };
          i += 1;
        }
        options = newOptions;
      } else {
        const newOptions = [];
        let x = Number(countValue.label);

        for (let i = 0; i < x; i++) {
          if (i === 0) {
            newOptions[i] = {
              opt_serial_no: i,
              opt_text: i + 1,
              opt_label: formState.ratingFirstLabel
                ? formState.ratingFirstLabel
                : 'Not Likely',
            };
          } else if (i === x - 1) {
            newOptions[i] = {
              opt_serial_no: i,
              opt_text: i + 1,
              opt_label: formState.ratingLastLabel
                ? formState.ratingLastLabel
                : 'Extremely likely',
            };
          } else {
            newOptions[i] = { opt_serial_no: i, opt_text: i + 1 };
          }
        }
        options = newOptions;
      }
      placeholder = 'rating';
      if (ratingStyleType.label === 'Radio Button') {
        if (addLabelsChecked === true) {
          optionType = 1;
        } else {
          optionType = 4;
        }
      } else if (ratingStyleType.label === 'Smiley') {
        if (addLabelsChecked === true) {
          optionType = 2;
        } else {
          optionType = 5;
        }
      } else if (ratingStyleType.label === 'Star') {
        if (addLabelsChecked === true) {
          optionType = 3;
        } else {
          optionType = 6;
        }
      }
    }

    // console.log('<<<<<<<<<text>>>>>>>>', text);

    // const extractText = (html) => {
    //   // Create a new DOMParser
    //   const parser = new DOMParser();

    //   // Parse the HTML string into a document
    //   const doc = parser.parseFromString(html, 'text/html');

    //   // Get the text content from the parsed document
    //   return doc.body.textContent || '';
    // };
    const extractText = (html) => {
      // Create a new DOMParser
      const parser = new DOMParser();

      // Parse the HTML string into a document
      const doc = parser.parseFromString(html, 'text/html');

      // Find all <p> elements in the document
      const pElements = doc.querySelectorAll('p');

      // Replace each <p> element with its inner content
      pElements.forEach((p) => {
        const parent = p.parentNode;
        while (p.firstChild) {
          parent.insertBefore(p.firstChild, p);
        }
        parent.removeChild(p);
      });

      // Serialize the modified document back to an HTML string
      return doc.body.innerHTML;
    };

    // Extract the text content from the HTML string
    console.log('text Before>>>>>>', text);
    const textContent = extractText(text);
    console.log('<<<<<<<<<text>>>>>>>>', textContent);
    let data = {
      que_serial_no: questionCount,
      que_text: textContent,
      que_is_independent: isIndependent,
      que_is_mandatory: isMandatory,
      qtype_fk_id: ans_id,
      options: options,
      que_place_holder: placeholder,
      otype_fk_id: optionType,
      que_label_individually: addLabelsChecked,
    };
    postMethod(
      process.env.REACT_APP_BASE_URL +
        process.env.REACT_APP_PREFIX_URL +
        sId +
        '/section/' +
        `${sectionId}` +
        '/question/new/',
      token,
      data
    )
      .then((res) => {
        if (res) {
          // questionListApi(smId);
          setQuestiontext(res.data.data.que_text);
          if (!toastDisplayed) {
            toast.success('Question added successfully', {
              style: {
                fontSize: '0.875rem',
              },
            });
            setToastDisplayed(true);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (toastDisplayed) {
      const timer = setTimeout(() => {
        setToastDisplayed(false);
      }, 2000); // Reset after 2 seconds or as needed

      return () => clearTimeout(timer); // Cleanup timer on unmount
    }
  }, [toastDisplayed]);

  // surveyStatus
  useEffect(() => {
    if (sId)
      getMethod(
        process.env.REACT_APP_BASE_URL +
          process.env.REACT_APP_PREFIX_URL +
          `${sId}/surveyInfo/`,
        token
      )
        .then((res) => {
          console.log('Res>>>>>data', res.data.data.sm_status);
          setSurveyStatus(res.data.data.sm_status);
          // setSurveyExpiryDate(res.data.data.sm_expiry_date);
          // setSurveyCode(res.data.data.sm_unique_code);
          // Set selected date to expiry date
          // setSelectedDate(new Date(res.data.data.sm_expiry_date));
        })
        .catch((err) => {
          console.log(err);
        });
  }, [sId, token]);

  const nextQuestionClicked = () => {
    if (!(editorState instanceof EditorState)) {
      setEditorState(EditorState.createEmpty());
      // toast.error("Editor state was invalid and has been reset.", {
      //   style: {
      //     fontSize: 16,
      //   },
      // });
      return;
    }

    let text = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    text = text.trim();
    if (text === '<p></p>') {
      text = '';
    }
    // Check if questionText is empty
    if (!text) {
      toast.error('Question text is mandatory for creating the question.', {
        style: {
          fontSize: 16,
        },
      });
      return;
    }
    // Check if options are provided for Single Selection type
    if (
      questionTypeValue.label === 'Single Selection' ||
      questionTypeValue.label === 'Multiple Selection'
    ) {
      // Filter out filled options by checking for non-empty strings
      const filledOptions = multipleOptions.filter(
        (option) =>
          typeof option.opt_label === 'string' && option.opt_label.trim() !== ''
      );

      // Ensure all options are filled and no option is left blank
      const hasEmptyOption = multipleOptions.some(
        (option) =>
          typeof option.opt_label !== 'string' || option.opt_label.trim() === ''
      );

      if (filledOptions.length < 2) {
        toast.error(
          'At least two options must be provided for Single Selection or Multiple Selection questions.',
          {
            style: {
              fontSize: 16,
            },
          }
        );
        return;
      }

      // Check if there are any empty options
      if (hasEmptyOption) {
        toast.error(
          'All options must be filled for Multiple Selection questions.',
          {
            style: {
              fontSize: 16,
            },
          }
        );
        return;
      }
    }
    // Validate rating options when addLabelsChecked is true
    if (questionTypeValue.label === 'Rating' && addLabelsChecked) {
      // Check if any rating option is empty
      const hasEmptyRatingOption = ratingLabelOptions.some(
        (option) =>
          typeof option.opt_text !== 'string' || option.opt_text.trim() === ''
      );

      if (hasEmptyRatingOption) {
        toast.error('Options must be filled for Rating questions.', {
          style: {
            fontSize: 16,
          },
        });
        return;
      }

      // Check if ratingLabelOptions is empty or contains only empty strings
      const isRatingOptionsInvalid = ratingLabelOptions.every(
        (option) => option.opt_text === ''
      );
      if (
        ratingLabelOptions.length < countValue.label ||
        isRatingOptionsInvalid
      ) {
        toast.error(
          'Rating options must be provided when addLabelsChecked is true.',
          {
            style: {
              fontSize: '0.875rem',
            },
          }
        );
        return;
      }
    }
    if (text !== null && text !== undefined && text !== '') {
      createQuestion(
        text,
        questionTypeValue,
        questionCount,
        true,
        mandatoryChecked
      );
    }
    setQuestionTypeValue(questionsTypeList[0]);
    setEditorState('');
    formState.placeholder = '';
    setInputs(['', '']);
  };
  // question list api calling
  const questionListApi = (smId) => {
    getMethod(
      process.env.REACT_APP_BASE_URL +
        process.env.REACT_APP_PREFIX_URL +
        sId +
        '/question/list/',
      token
    )
      .then((res) => {
        if (res.data.data.length === 0) {
          createSection('0', 0);
        } else {
          setQuestionsListRsp(res.data.data);
          setQuestionLength(res.data.data[0].questions.length);
          setQuestionCount(res.data.data[0].questions.length + 1);
          setSectionId(res.data.data[0].sec_pk_id);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleMouseOver = (id) => {
    questionListResp.map((sec) => {
      sec.questions.map((ques) => {
        if (ques.que_pk_id === id) {
          setIsHovering(true);
          setSelectedQuesId(id);
        }
      });
    });
  };

  const handleMouseOut = (id) => {
    questionListResp.map((sec) => {
      sec.questions.map((ques) => {
        if (ques.que_pk_id === id) {
          setIsHovering(false);
          setSelectedQuesId('');
        }
      });
    });
  };
  return (
    <Box>
      <Grid container>
        {/* display list of questions */}
        <Suspense fallback={'Loading...'}>
          {questionListResp.map((sec, index) => (
            <Grid item xs={12} mt={2} key={index}>
              {sec.questions.map((ques, quesIndex) => (
                <SampleQuestion
                  surveyStatus={surveyStatus}
                  key={`question-${ques.que_pk_id}`}
                  question={ques}
                  queId={ques.que_pk_id}
                  secId={ques.sec_fk_id}
                  surveyId={ques.sm_fk_id}
                  text={ques.que_text}
                  mandatoryChecked={ques.que_is_mandatory}
                  questionCount={ques.que_serial_no}
                  isIndividuallyLabel={ques.que_label_individually}
                  value={ques.qtype_fk_id}
                  placeholder={ques.que_place_holder}
                  options={ques.options}
                  index={Number(quesIndex) + 1}
                  isHovering={isHovering}
                  setIsHovering={setIsHovering}
                  handleMouseOut={handleMouseOut}
                  handleMouseOver={handleMouseOver}
                  selectedQuesId={selectedQuesId}
                  editQuestionData={editQuestionData}
                  setEditQuestionData={setEditQuestionData}
                  questionListApi={questionListApi}
                  smId={smId}
                  token={token}
                  ratingStyle={ratingStyle}
                  setRatingStyle={setRatingStyle}
                  countValue={countValue}
                  npsType={npsType}
                  setNpsType={setNpsType}
                  npsHandleType={npsHandleType}
                  npsRatingStyleFormat={npsRatingStyleFormat}
                  setNpsRatingStyleFormat={setNpsRatingStyleFormat}
                  ratingStyleType={ratingStyleType} //rating style type
                  setRatingStyleType={setRatingStyleType}
                  npsRatingType={npsRatingType} //nps rating style type
                  setNpsRatingType={setNpsRatingType}
                  editRatingStyleFormat={editRatingStyleFormat}
                  setEditRatingStyleFormat={setEditRatingStyleFormat}
                />
              ))}
            </Grid>
          ))}
        </Suspense>
      </Grid>
      <Grid container columns={12}>
        <Suspense fallback={'Loading...'}>
          <AddQuestionButtonTemplate
            surveyStatus={surveyStatus}
            addQuestionClicked={addQuestionClicked}
            editorState={editorState}
            setEditorState={setEditorState}
            setAddQuestionClicked={setAddQuestionClicked}
            setMandatoryChecked={setMandatoryChecked}
            mandatoryChecked={mandatoryChecked}
            questionTypeValue={questionTypeValue}
            setQuestionTypeValue={setQuestionTypeValue}
            nextQuestionClicked={nextQuestionClicked}
            setIsFirstSecCreated={setIsFirstSecCreated}
            isFirstSecCreated={isFirstSecCreated}
            section={section}
            addQuestion={addQuestion}
            multipleOptions={multipleOptions}
            setMultipleOptions={setMultipleOptions}
            inputs={inputs}
            setInputs={setInputs}
            ratingLabelOptions={ratingLabelOptions}
            setRatingLabelOptions={setRatingLabelOptions}
            ratingStyle={ratingStyle}
            setRatingStyle={setRatingStyle}
            countValue={countValue}
            setCountValue={setCountValue}
            addLabelsChecked={addLabelsChecked}
            setAddLabelsChecked={setAddLabelsChecked}
            labels={labels}
            setLabels={setLabels}
            npsType={npsType}
            setNpsType={setNpsType}
            npsHandleType={npsHandleType}
            npsRatingStyleFormat={npsRatingStyleFormat}
            setNpsRatingStyleFormat={setNpsRatingStyleFormat}
            ratingStyleType={ratingStyleType} //add rating props
            setRatingStyleType={setRatingStyleType}
          />
        </Suspense>
      </Grid>

      {/* <Grid item xs={12}>
        <Common.Button variant="text" disabled={true}>
          Add New Section
        </Common.Button>
      </Grid> */}
      <Grid container columns={12} mt={2} mb={3}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ marginRight: '16px' }}>
            <Common.Button
              variant="text"
              size="small"
              disabled={surveyStatus === 'Active' ? true : false}
              onClick={() =>
                handleAddQuesBackButton(sId, survey_code, sectionId)
              }
            >
              Back
            </Common.Button>
          </Box>

          <Box sx={{ marginRight: '16px' }}>
            <Common.Button
              variant="outlined"
              size="small"
              onClick={clickSaveButton}
              disabled={
                surveyStatus === 'Active'
                  ? true
                  : questionLength > 0
                  ? false
                  : true
              }
            >
              Save
            </Common.Button>
          </Box>
          <Box>
            <Common.Button
              variant="contained"
              size="small"
              onClick={clickNextButton}
              disabled={
                surveyStatus === 'Active'
                  ? true
                  : questionLength > 0
                  ? nextButtonEnabled === true
                    ? false
                    : true
                  : true
              }
            >
              Next
            </Common.Button>
          </Box>
        </Grid>
      </Grid>
      <ToastContainer position="top-center" autoClose={2000} hideProgressBar />
    </Box>
  );
};

export default AddQuestions;
