import React, { useEffect, useState } from 'react';
import Layout from '../src/components/Layout/Layout';
import { useNavigate, useLocation } from 'react-router-dom';
import Routes from './routes/routes';
import './App.css';
import axios from 'axios';
import { Box, CircularProgress } from '@mui/material';

function App() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [msg, setMsg] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate outside the function
  const [httpReferrer, setHttpReferrer] = useState('');
  const [httpUserAgent, setHttpUserAgent] = useState('');
  const currentURL = window.location.href;

  const referrerDomain = document.referrer;
  localStorage.setItem('referrerDomain', referrerDomain);

  const url = new URL(currentURL);
  const inputData = url.searchParams.get('data');

  // This function will handle the token generation and authentication
  const TokenGenerator = () => {
    const jsonData = inputData ? JSON.parse(atob(inputData)) : null;
    const email = jsonData ? jsonData.email : null;
    const accId = jsonData ? jsonData.accid : null;

    // Define the request body for token generation
    const jsonParam = {
      email: email,
      acm_fk_id: parseInt(accId),
    };
    // Make the request to get the token
    axios
      .post(
        process.env.REACT_APP_BASE_URL + process.env.REACT_APP_TOKEN,
        jsonParam
      )
      .then((res) => {
        // console.log('res.data.access', res);
        if (res.data && res.data.access) {
          localStorage.setItem('accessToken', res.data.access);
          localStorage.setItem('mytime', Date.now());
          navigate('/survey/summary');
          setMsg(null);
        } else {
          setMsg('Authentication Failed');
        }
      })
      .catch((err) => {
        console.error(err);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('mytime');
        if (referrerDomain === '') {
          window.location.href = process.env.REACT_APP_FH_STAGGING_BASE_URL;
        } else {
          const url = new URL(referrerDomain);
          const protocolAndDomain = `${url.protocol}//${url.hostname}`; // Includes http/https and domain
          window.location.href = protocolAndDomain;
        }
        setMsg('Authentication Failed!');
      });
  };

  useEffect(() => {
    // Check if a token is already available
    const token = localStorage.getItem('accessToken');

    // Define the URLs where you want to skip the token check and redirection
    const allowedUrls = [
      '/ClientSurveyView',
      '/ClientDetails',
      '/ClientResponse',
    ];

    // Check if the current URL is in the allowed URLs list
    const isAllowedUrl = allowedUrls.some((url) =>
      location.pathname.startsWith(url)
    );

    // Check if data query parameter exists
    const url = new URL(window.location.href);
    const inputData = url.searchParams.get('data');

    // Only redirect to /survey/summary if there is a data parameter and the token already exists
    if (inputData) {
      TokenGenerator();
    } else if (inputData && token) {
      navigate('/survey/summary');
    } else if (!token && !isAllowedUrl) {
      window.location.href = process.env.REACT_APP_FH_STAGGING_BASE_URL;
    }

    // Set loading state after checking the token and URL
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [location, navigate]);

  useEffect(() => {
    const http_referrer = document.referrer;
    if (http_referrer) setHttpReferrer(http_referrer);
  }, []);
  useEffect(() => {
    const usrAgent = navigator.userAgent;
    if (usrAgent) setHttpUserAgent(usrAgent);
  }, []);

  useEffect(() => {
    if (httpReferrer || httpUserAgent) {
      const data = {
        page: 'loginpage',
        http_refer: httpReferrer,
        user_agent: httpUserAgent,
      };
      if (inputData) {
        const url =
          process.env.REACT_APP_BASE_URL +
          process.env.REACT_APP_PREFIX_URL +
          'httprfer/';
        axios
          .post(url, data)
          .then((res) => {
            // console.log('res.data.access', res);
            if (res.data) {
              // console.log('res.data>>>>>>>>', res.data);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  }, [httpReferrer, httpUserAgent]);

  // console.log('httpReferrer>>>>>>>', httpReferrer);
  // console.log('UserAgent>>>>>>>', httpUserAgent);

  // Loading spinner
  if (isLoading) {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress size={72} thickness={3} />
        </Box>
      </>
    );
  }

  // Error message handling
  if (msg) {
    return <div>{msg}</div>;
  }

  // Render the application layout with routes
  return (
    <Layout>
      <Routes />
    </Layout>
  );
}

export default App;
