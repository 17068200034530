import React, { useState } from 'react';
import {
  Typography,
  IconButton,
  Badge,
  Menu,
  MenuItem,
  Button,
  Icon,
  Grid,
} from '@mui/material';
import SwitchWithIcons from '../../components/common/Switch/SwitchWithIcons';
import { useThemeMode } from '../../context/themeContext';
import ReusableTooltip from '../common/Tooltip/Tooltip';
import useStyles from './styles';
import AppBar from '../AppBar/AppBar';
import { useSidebarContext } from '../../context/sidebarContext';
import { useNavigate } from 'react-router-dom';

export default function Header(props) {
  const { sidebarOpen } = useSidebarContext();
  const navigate = useNavigate();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { darkMode, handleDarkMode } = useThemeMode();
  const referrerDomain = localStorage.getItem('referrerDomain');

  // switch handler
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    handleClose();
    localStorage.clear();
    if (referrerDomain === '') {
      window.location.href = process.env.REACT_APP_FH_STAGGING_BASE_URL;
    } else {
      console.log('local ip');
      const url = new URL(referrerDomain);
      const protocolAndDomain = `${url.protocol}//${url.hostname}`; // Includes http/https and domain
      window.location.href = protocolAndDomain;
    }
  };

  return (
    <AppBar
      position="fixed"
      className={sidebarOpen ? classes.appBarOpen : classes.appBarClosed}
    >
      <Grid container alignItems="center">
        <Grid item xs={3}></Grid>
        <Grid item xs={6} textAlign="center">
          <Typography className={classes.headertext}>
            {props.headertext}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Grid
            container
            justifyContent={'end'}
            item
            // ml={5}
            // spacing={{ xs: 1, md: 2 }}
            // columns={{ xs: 6, sm: 8, md: 12 }}
          >
            {/* <Grid item xs={2} sm={2}>
                    <ReusableTooltip title="Knowledge Base">
                                        <IconButton>
                                            <Icon className="fh-icon fh-icon-library"/>
                                        </IconButton>
                                    </ReusableTooltip>
                                </Grid> */}
            {/* <Grid item xs={2} sm={2}>
                                    <ReusableTooltip
                                        open={true}
                                        title="Info"
                                    >
                                        <IconButton>
                                            <span className="fh-icon fh-icon-info"></span>
                                        </IconButton>
                                    </ReusableTooltip>
                                </Grid> */}
            {/* <Grid item xs={2} sm={2}>
                                    <ReusableTooltip
                                        open={true}
                                        title={"123456789012345678901234567890 tooltip can be 30 charcter lenght in single line"}
                                    >
                                        <IconButton>
                                            <Badge badgeContent={''} color="error">
                                                <span className="fh-icon fh-icon-bell"></span>
                                            </Badge>
                                        </IconButton>
                                    </ReusableTooltip>
                                </Grid> */}
            {/* <Grid item xs={2} sm={2}>
                                    <ReusableTooltip title="Release Notes">
                                        <IconButton>
                                            <span className="fh-icon fh-icon-document"></span>
                                        </IconButton>
                                    </ReusableTooltip>
                                </Grid> */}
            <Grid item xs={2} sm={2}>
              <ReusableTooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  className={classes.userProfile}
                  // size="small"
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  <Icon className="fh-icon fh-icon-user" />
                </IconButton>
              </ReusableTooltip>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                // onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    background:
                      localStorage.getItem('theme') === 'light'
                        ? '#FFFFFF'
                        : '#333',
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      // bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                {/* <MenuItem onClick={handleClose}>
                      <span className="fh-icon fh-icon-user"></span>
                      <Typography variant="body5">TestUser</Typography>
                    </MenuItem> */}
                {/* <MenuItem
                    // onClick={handleClose}
                    >
                      <SwitchWithIcons
                        checked={darkMode}
                        onChange={handleDarkMode}
                      />
                    </MenuItem> */}
                <MenuItem onClick={handleLogout}>
                  <Icon
                    className="fh-icon fh-icon-logout"
                    onClick={handleLogout}
                  />
                  <Typography variant="body3" onClick={handleLogout}>
                    Logout
                  </Typography>
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
}
