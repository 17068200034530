import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Box } from '@mui/system';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function BarChart(props) {
  const {
    barLabels,
    barWidth,
    barHeight,
    datasets,
    fontColor,
    gridColor,
    legendDisplay,
    min,
    max,
  } = props;

  const labels = barLabels;

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 2,
    categoryPercentage: 0.4,
    barPercentage: 1,

    plugins: {
      legend: {
        position: 'right',
        display: legendDisplay,
        align: 'start',
        labels: {
          color: fontColor,
          usePointStyle: true,
          pointStyle: 'rect',
          font: {
            family: 'poppins',
            size: 12,
          },
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            // Return the label corresponding to the index
            return labels[tooltipItems[0].dataIndex];
          },
          // label: (tooltipItem) => {
          //   // Format the label for each tooltip item
          //   const datasetLabel = datasets[tooltipItem.datasetIndex].label || '';
          //   return `${datasetLabel}: ${tooltipItem.raw}%`;
          // },
        },
      },
    },

    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          color: fontColor,
          font: {
            family: 'poppins',
            size: 12,
            weight: 400,
          },
        },
      },
      y: {
        min: min, //min value set
        max: max, //max value set
        ticks: {
          callback: function (value) {
            return value + '%'; // Append '%' to the tick values
          },
        },
        border: {
          display: false,
        },
        grid: {
          color: gridColor,
          lineWidth: 0.5,
        },
        ticks: {
          beginAtZero: true,
          color: fontColor,
          font: {
            family: 'poppins',
            size: 12,
            weight: 400,
          },
        },
      },
    },
  };

  const data = {
    labels,
    datasets,
  };

  return <Bar options={options} data={data} />;
}
