import { makeStyles } from "@mui/styles";
export default makeStyles(theme=>({
    tagContainer: {
        border: theme.palette.mode === 'light' ? `1px solid ${theme.palette.grey.light} !important` : `1px solid ${theme.palette.grey.light} !important`,
        borderRadius: "6px",
        // width: `${tagWidth}`,
        padding: theme.spacing(1)
    },

    tag: {
        background: theme.palette.mode === 'light' ? `${theme.palette.blue.light} !important` : `${theme.palette.blue.dark} !important`,
        color: theme.palette.mode === 'light' ? `${theme.palette.black.dark} !important` : `${theme.palette.white.main} !important`,
        borderColor: theme.palette.mode === 'light' ? `${theme.palette.blue.light} !important` : `${theme.palette.blue.dark} !important`,
        borderRadius: "4px !important",
        marginRight: `${theme.spacing(0.5)} !important`,
        marginBottom: `${theme.spacing(0.5)} !important`,
        padding: `${theme.spacing(0.5)} !important`,
        alignItems: "center",
        fontSize: "12px",
    },
    deleteIcon: {
        fontSize: "12px !important",
        color: theme.palette.mode === 'light' ? `${theme.palette.black.dark} !important` : `${theme.palette.white.main} !important`,
    }
}));