import * as React from "react";
import { Box,Stepper as MuiStepper,Step,StepLabel,Button,Typography } from "@mui/material";


export default function Stepper(props) {
    const { steps, activeStep } = props;
    // const [activeStep, setActiveStep] = React.useState(0);
    const handleNext = () => {
      //  setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
       // setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
       // setActiveStep(0);
    };

    return (
        <Box sx={{ width: "100%" }}>
            <MuiStepper
               // activeStep={activeStep}
                sx={{
                    "& .MuiStepConnector-root": {
                        display: "none"
                    }
                }}
            >
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel
                                sx={{
                                    "& .MuiStepLabel-iconContainer": {
                                        display: "none"
                                    }
                                }}
                                {...labelProps}
                            >
                                {label}
                            </StepLabel>
                            <Box
                                id={`box-${index}`}
                                sx={{
                                    width: "10px",
                                    height: "10px",
                                    background: index === activeStep ? "#161E6A" : "#C6C6C6",
                                    borderRadius: "50%",
                                    display: "inline-block"
                                }}
                            ></Box>
                            <Box
                                id={`box-${index}`}
                                sx={{
                                    display: "inline-block",
                                    border:
                                        index === activeStep
                                            ? "4px solid #161E6A"
                                            : "4px solid #C6C6C6",
                                    // border: "4px solid #161E6A",
                                    width: "130px",
                                    ml: 0.5
                                }}
                            ></Box>
                        </Step>
                    );
                })}
            </MuiStepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button onClick={handleNext}>
                            {activeStep === steps.length - 1 ? "Finish" : "Next"}
                        </Button>
                    </Box>
                </React.Fragment>
            )}
        </Box>
    );
}
