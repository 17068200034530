import { Grid, Typography } from '@mui/material';
import React from 'react';
// import Filters from '../Filters/Filters';
import AnalyticsCharts from '../AnalyticsCharts/AnalyticsCharts';

const OverAllSurveyReports = ({
  participantData,
  stateWiseparticipantData,
  // ageWiseparticipantData,
  submissionReportData,
  channelReportData,
}) => {
  return (
    <>
      <Grid container>
        <Grid item xs={12} mt={2}>
          <Typography variant="sh2">OverAll Reports</Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <AnalyticsCharts
            participantData={participantData}
            stateWiseparticipantData={stateWiseparticipantData}
            // ageWiseparticipantData={ageWiseparticipantData}
            submissionReportData={submissionReportData}
            channelReportData={channelReportData}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default OverAllSurveyReports;
