// import React from 'react';
// import {
//   Box,
//   FormControl,
//   FormControlLabel,
//   Radio,
//   RadioGroup as MuiRadioGroup,
//   Typography,
// } from '@mui/material';
// import useStyles from './styles';

// export default function RatingRadio(props) {
//   const {
//     name,
//     value,
//     onChange,
//     items,
//     ratingStyle,
//     optionsFontStyle,
//     optionsFontSize,
//     errorMessage,
//     ...other
//   } = props;

//   const classes = useStyles(props);
//   console.log('items>>>>', items);

//   const typographyStyle = {
//     color: '#333',
//     fontFamily: optionsFontStyle ? `${optionsFontStyle} !important` : 'Poppins',
//     fontSize: optionsFontSize ? `${optionsFontSize}px !important` : '1rem',
//   };

//   const renderRadioGroup = () => (
//     <MuiRadioGroup
//       name={name}
//       value={value}
//       className={classes.root}
//       onChange={onChange}
//       {...other}
//     >
//       {items.map((item, index) => (
//         <FormControlLabel
//           key={index}
//           value={item.id}
//           control={<Radio className={classes.radio} disableRipple />}
//           label={
//             <Typography variant="body3" sx={typographyStyle}>
//               {item.title}
//             </Typography>
//           }
//           labelPlacement={'bottom'}
//         />
//       ))}
//     </MuiRadioGroup>
//   );

//   if (items.length === 0) return null;

//   return ratingStyle === 1 ? (
//     <FormControl sx={{ ml: 0, mr: 0 }} error={errorMessage ? true : false}>
//       {renderRadioGroup()}
//       {errorMessage && (
//         <Typography variant="body4" color="error">
//           {errorMessage}
//         </Typography>
//       )}
//     </FormControl>
//   ) : (
//     <Box sx={{ display: 'flex' }}>
//       <Typography
//         mt={1}
//         mr={1.5}
//         variant="body3"
//         sx={{
//           ...typographyStyle,
//           fontSize: optionsFontSize
//             ? `${optionsFontSize}px !important`
//             : '1rem',
//         }}
//       >
//         {items[0].title}
//       </Typography>
//       <FormControl sx={{ ml: 0, mr: 0 }}>{renderRadioGroup()}</FormControl>
//       <Typography
//         mt={1}
//         ml={1.5}
//         variant="body3"
//         sx={{
//           ...typographyStyle,
//           fontSize: optionsFontSize
//             ? `${optionsFontSize}px !important`
//             : '16px',
//         }}
//       >
//         {items[items.length - 1].title}
//       </Typography>
//     </Box>
//   );
// }

import React from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup as MuiRadioGroup,
  Typography,
} from '@mui/material';

import useStyles from './styles';

export default function RatingRadio(props) {
  const {
    name,
    label,
    value,
    onChange,
    items,
    labelPlacement,
    optionsFontStyle,
    ratingStyle,
    optionsFontSize,
    ...other
  } = props;

  // console.log('optionsFontStyle>>>>>', value);

  const classes = useStyles(props);

  return items.length !== 0 ? (
    ratingStyle === 1 ? (
      <FormControl sx={{ ml: 0, mr: 0 }}>
        {/* <Typography variant="sh3" sx={{ color: "#333" }}>
        {label}
      </Typography> */}
        <MuiRadioGroup
          name={name}
          value={value}
          className={classes.root}
          onChange={onChange}
          {...other}
        >
          {items.map((item, index) => (
            <FormControlLabel
              key={index}
              value={item.id}
              control={<Radio className={classes.radio} disableRipple />}
              // label={item.title}
              label={
                <Typography
                  // variant="body3"
                  sx={{
                    color: '#333',
                    fontFamily: optionsFontStyle
                      ? `${optionsFontStyle} !important`
                      : 'Poppins',
                    fontSize: optionsFontSize
                      ? `${optionsFontSize}px !important`
                      : '1rem',
                  }}
                >
                  {item.title}
                </Typography>
              }
              labelPlacement={'bottom'}
            />
          ))}
        </MuiRadioGroup>
      </FormControl>
    ) : (
      <Box sx={{ display: 'flex' }}>
        <Typography
          mt={1}
          mr={1.5}
          variant="body3"
          sx={{
            color: '#333',
            fontFamily: optionsFontStyle
              ? `${optionsFontStyle} !important`
              : 'Poppins',
            fontSize: optionsFontSize
              ? `${optionsFontSize}px !important`
              : '16px',
          }}
        >
          {items[0].title}
        </Typography>
        <FormControl sx={{ ml: 0, mr: 0 }}>
          <MuiRadioGroup
            name={name}
            value={value}
            className={classes.root}
            onChange={onChange}
            {...other}
          >
            {items.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item.id}
                control={<Radio className={classes.radio} disableRipple />}
                labelPlacement={'bottom'}
              />
            ))}
          </MuiRadioGroup>
        </FormControl>
        <Typography
          mt={1}
          ml={1.5}
          variant="body3"
          sx={{
            color: '#333',
            fontFamily: optionsFontStyle
              ? `${optionsFontStyle} !important`
              : 'Poppins',
            fontSize: optionsFontSize
              ? `${optionsFontSize}px !important`
              : '16px',
          }}
        >
          {items[items.length - 1].title}
        </Typography>
      </Box>
      
    )
  ) : (
    <></>
  );
}
