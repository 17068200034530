import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import useStyles from "./styles";

const InputWthActionBtn = (props) => {
  const {
    label,
    size,
    variant,
    placeholder,
    id,
    endAdornment,
    direction,
    disabled,
    value,
    onClick,
    onChange,
    inputRef,
    readOnly,
  } = props;
  const classes = useStyles(props);
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: `${direction} !important`,
        flexFlow: "nowrap",
      }}
    >
      <InputLabel className={classes.formLabel}>{label}</InputLabel>
      <FormControl fullWidth>
        <TextField
          id={id}
          variant={variant || "outlined"}
          className={classes.root}
          size={size || "medium"}
          disabled={disabled}
          //   defaultValue={value}
          value={value}
          InputProps={{
            readOnly: readOnly,
            endAdornment: endAdornment && (
              <InputAdornment position="end">
                <IconButton
                  disableRipple
                  onClick={onClick}
                  className={classes.iconButton}
                >
                  <Typography variant="body2" className={endAdornment} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          placeholder={placeholder}
          onChange={onChange}
          inputRef={inputRef}
          helperText={disabled ? "" : "Help text"}
          fullWidth
        />
      </FormControl>
    </Box>
  );
};

export default InputWthActionBtn;
