import {
  Typography,
  Box,
  Grid,
  // FormGroup,
  // FormHelperText,
  // FormControl,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import TextArea from '../../../components/common/TextArea/TextArea';
import InputBox from '../../../components/common/InputBox/InputBox';
import NpsFormat from './DisplayFormat/DisplayFormat';
import RatingFormat from './Ratings';
import OptionsFormat from './DisplayOptionFormat';
// import { FormControlLabel, Checkbox } from '@mui/material';
import StringToMuiTypography from '../AddQuestions/StringToMuiTypography';
import NpsSmileyFormat from './DisplayFormat/NpsSmileyFormat';
import MultipleCheckbox from './MultipleCheckbox/MultipleCheckbox';

const DisplayQuestions = ({
  mandatoryChecked,
  question,
  shortPlaceholder,
  paraPlaceholder,
  value,
  text,
  options,
  queId,
  index,
  onShortAnswerChange,
  onValueTextChange,
  onOptionValueChange,
  onCheckOptionValueChange,
  onNpsValueChange,
  selectedQuesId,
  onEmailChange,
  onNumberChange,
  onValuesChange,
  onRadioRatingChange,
  helperMsgs,
  handleClickedRatingStar,
  handleRadioChange,
  selectedId,
  buttonClicked,
  questionsFontStyle,
  optionsFontStyle,
  emojiSelectedId,
  setEmojiSelectedId,
  // radioValue,
  // setRadioValue,
  smileySelections, //for smiley nps
  setSmileySelections,
  starSelections, //for star
  setStarSelections,
  // EmojiSelections,
  // setEmojiSelections,
  optionsFontSize,
  errorMessage,
  setErrorMessages,
  formData,
  handleChange,
  checkoptionvalue,
  // onCheckOptionValueChange,
  handleChangeCheck,
  handleShort,
  shortanswer1,
  handleCheckboxChange,
  mulChResponses,
  setMulChResponses,
  questionFontSize,
}) => {
  // console.log('options', options);
  // console.log('formData>>>>>>', formData);
  // const [paraAns, setParaAns] = useState('');
  // const [shortanswer, setShortanswer] = useState('');
  // const [npsValue, setNpsvalue] = useState('');
  // const [radioRatingValue, setRadioRatingValue] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState(0);
  let quesText = text;
  // if (mandatoryChecked) {
  //   // console.log('', text);
  //   quesText += '*';
  // } else {
  //   quesText += '';
  // }
  const handleSmileyClick = (queId, val) => {
    setSmileySelections((prev) => ({ ...prev, [queId]: val }));
  };
  // Star rating handling
  const handleStarClick = (queId, val) => {
    // console.log(queId, val);
    setStarSelections((prev) => ({ ...prev, [queId]: val }));
  };

  // Emoji rating handling
  const handleEmojiClick = (queId, val) => {
    setEmojiSelectedId((prev) => ({ ...prev, [queId]: val }));
  };

  // console.log('mmulChResponses>>>>>>>>>', mulChResponses);
  const handleMultiChange = (optionId) => {
    setMulChResponses((prevResponses) => {
      const selectedOptions = prevResponses[question.que_pk_id] || [];
      if (selectedOptions.includes(optionId)) {
        // Remove option if it's already selected
        return {
          ...prevResponses,
          [question.que_pk_id]: selectedOptions.filter((id) => id !== optionId),
        };
      } else {
        // Add option if it's not selected
        return {
          ...prevResponses,
          [question.que_pk_id]: [...selectedOptions, optionId],
        };
      }
    });
  };

  // // radio rating onChange
  // const onhandleRadioRatingChange = (e) => {
  //   setRadioRatingValue(e.target.value);
  //   onRadioRatingChange(e.target.value);
  // };

  const handleEmail = (event) => {
    const emails = event.target.value;
    setEmail(event.target.value);
    onEmailChange(emails);
  };
  const handleNUmber = (event) => {
    const number = event.target.value;
    setNumber(event.target.value);
    onNumberChange(number);
  };

  return (
    <Box mb={2}>
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              // alignItems: 'flex-start',
              justifyContent: 'start',
            }}
          >
            {text !== undefined && text !== null ? (
              <>
                <Typography
                  variant="sh3"
                  sx={{
                    fontFamily: questionsFontStyle,
                    fontSize: questionFontSize
                      ? `${questionFontSize}px`
                      : '1rem',
                    fontWeight: '500',
                    color: '#333',
                    // mt:0.5
                  }}
                >
                  {`Q${index}.`}&nbsp;
                </Typography>
                <StringToMuiTypography
                  inputString={quesText}
                  questionsFontStyle={questionsFontStyle}
                  questionFontSize={questionFontSize}
                />
                {mandatoryChecked === true && (
                  <Typography
                    variant="body3"
                    sx={{
                      fontFamily: questionsFontStyle,
                      fontSize: questionFontSize
                        ? `${questionFontSize}px`
                        : '1rem',
                      fontWeight: '500',
                      color: '#333',
                    }}
                  >{`*`}</Typography>
                )}
              </>
            ) : null}
          </Box>
        </Grid>
      </Grid>
      {text !== undefined && text !== null ? (
        value === 1 ? (
          <>
            <InputBox
              // questionsFontStyle={questionsFontStyle}
              questionsFontStyle={optionsFontStyle[1]}
              placeholder={shortPlaceholder}
              name={`shortAnswer_${question.que_pk_id}`}
              value={formData[`shortAnswer_${question.que_pk_id}`] || ''}
              onChange={handleChange}
              error={errorMessage}
            />
          </>
        ) : value === 2 ? (
          <>
            <TextArea
              // questionsFontStyle={questionsFontStyle}
              questionsFontStyle={optionsFontStyle[1]}
              rows={3}
              placeholder={paraPlaceholder}
              name={`paraAns_${question.que_pk_id}`}
              value={formData[`paraAns_${question.que_pk_id}`] || ''}
              onChange={handleChange}
              error={errorMessage}
            />
          </>
        ) : value === 3 ? (
          <>
            {question.otype_fk_id === 1 && (
              <>
                <NpsFormat //radio nps
                  options={options}
                  name={`npsRadioAns_${question.que_pk_id}`}
                  value={formData[`npsRadioAns_${question.que_pk_id}`] || ''}
                  onChange={handleChange}
                  optionsFontStyle={optionsFontStyle[1]}
                  optionsFontSize={optionsFontSize}
                  error={errorMessage}
                />
              </>
            )}
            {question.otype_fk_id === 2 && (
              <NpsSmileyFormat
                options={options}
                onClick={(val) => handleSmileyClick(queId, val)}
                selectedSmileyId={smileySelections[queId] || null}
                optionsFontStyle={optionsFontStyle[1]}
                error={errorMessage}
              />
            )}
          </>
        ) : value === 4 ? (
          <>
            <OptionsFormat //single choice
              options={options}
              type={value}
              name={`singleChoiceAns_${question.que_pk_id}`}
              value={formData[`singleChoiceAns_${question.que_pk_id}`] || ''}
              onChange={handleChange}
              optionsFontStyle={optionsFontStyle[1]}
              error={errorMessage}
            />
          </>
        ) : value === 5 ? (
          <>
            <MultipleCheckbox
              data={question}
              question={question}
              mulChResponses={mulChResponses}
              optionsFontStyle={optionsFontStyle[1]}
              setMulChResponses={setMulChResponses}
              error={errorMessage}
              handleMultiChange={handleMultiChange}
            />
          </>
        ) : value === 6 ? (
          <>
            <InputBox
              type="email"
              placeholder={shortPlaceholder}
              value={email}
              onChange={handleEmail}
              error={errorMessage}
            />
          </>
        ) : value === 7 ? (
          <>
            <InputBox
              type="number"
              placeholder={shortPlaceholder}
              value={number}
              onChange={handleNUmber}
              error={errorMessage}
            />
          </>
        ) : value === 8 ? (
          <>
            <RatingFormat
              options={options}
              ratingStyle={question.otype_fk_id}
              countValue={options.length}
              selectedId={selectedId}
              buttonClicked={buttonClicked}
              starSelectedId={starSelections[queId] || null} //star rating
              emojiSelectedId={emojiSelectedId[queId] || null} //emoji rating
              optionsFontStyle={optionsFontStyle[1]}
              onClick={(val) => handleStarClick(queId, val)} //
              handleEmojiClick={(val) => handleEmojiClick(queId, val)}
              onChange={handleChange}
              errorMessage={errorMessage}
              name={`radioRatingAns_${question.que_pk_id}`}
              value={formData[`radioRatingAns_${question.que_pk_id}`] || ''} //radio rating
            />
          </>
        ) : null
      ) : null}
    </Box>
  );
};
export default DisplayQuestions;
