import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

const MultipleCheckbox = ({
  question,
  mulChResponses,
  setMulChResponses,
  error,
  handleMultiChange,
  optionsFontStyle,
}) => {
  // Initialize state for selected options per question
  // const [responses, setResponses] = useState({
  //   [question.que_pk_id]: [], // Initialize with empty array for current question
  // });

  // console.log('responses', responses);
  // Handle checkbox changes
  // const handleMultiChange = (optionId) => {
  //   setMulChResponses((prevResponses) => {
  //     const selectedOptions = prevResponses[question.que_pk_id] || [];
  //     if (selectedOptions.includes(optionId)) {
  //       // Remove option if it's already selected
  //       return {
  //         ...prevResponses,
  //         [question.que_pk_id]: selectedOptions.filter((id) => id !== optionId),
  //       };
  //     } else {
  //       // Add option if it's not selected
  //       return {
  //         ...prevResponses,
  //         [question.que_pk_id]: [...selectedOptions, optionId],
  //       };
  //     }
  //   });
  // };

  return (
    <FormGroup>
      {question.options.map((option) => (
        <FormControlLabel
          key={option.opt_pk_id}
          control={
            <Checkbox
              checked={
                mulChResponses[question.que_pk_id]?.includes(
                  option.opt_pk_id
                ) || false
              }
              onChange={() => handleMultiChange(option.opt_pk_id)}
              name={option.opt_label}
              color="primary"
            />
          }
          label=<Typography
            variant='body3'
            sx={{ fontFamily: optionsFontStyle ? optionsFontStyle : 'Poppins' }}
          >
            {option.opt_label}
          </Typography>
        />
      ))}
      {error && question.que_is_mandatory && (
        <FormHelperText error>{error}</FormHelperText>
      )}
    </FormGroup>
  );
};

export default MultipleCheckbox;
