export const menu = [
  //   {
  //     icon: <span className="fh-icon fh-icon-home"></span>,
  //     title: "Home",
  //     items: [],
  //     path: "/",
  //   },
  {
    icon: <span className="fh-icon fh-icon-check-list"></span>,
    title: 'Manage Survey',
    items: [],
    path: '/survey/summary',
  },
  {
    icon: <span className="fh-icon fh-icon-analytics"></span>,
    title: 'Analytics',
    items: [],
    path: '/surveyAnalytics',
  },
  {
    icon: <span className="fh-icon fh-icon-font"></span>,
    title: 'Manage Fonts',
    items: [],
    path: '/manage-fonts',
  },
  //   {
  //     icon: <span className="fh-icon fh-icon-analytics"></span>,
  //     title: "Analytics",
  //     items: [
  //       {
  //         title: "Analytics Menu",
  //         path: "/analytics/analyticsmenu",
  //       },
  //       {
  //         title: "Dashboard(FH Central)",
  //         path: "/analytics/dashboardfhcentral",
  //       },
  //       {
  //         title: "Customer Profile Dashboard",
  //         path: "/analytics/customerprofiledashboard",
  //       },
  //       {
  //         title: "Email Campaign Analytics",
  //         path: "/analytics/emailAnalytics",
  //       },
  //       {
  //         title: "SMS Campaign Analytics",
  //         path: "/analytics/smsAnalytics",
  //       },
  //       {
  //         title: "Voice Campaign Analytics",
  //         path: "/analytics/voiceAnalytics",
  //       },
  //       {
  //         title: "Automation Funnel Analytics",
  //         path: "/analytics/automationfunnelAnalytics",
  //       },
  //       {
  //         title: "Browser Push Analytics",
  //         path: "/analytics/browserPushAnalytics",
  //       },
  //       {
  //         title: "Mobile Push Analytics",
  //         path: "/analytics/mobilePushAnalytics",
  //       },
  //       {
  //         title: "Messenger Analytics",
  //         path: "/analytics/messengerAnalytics",
  //       },
  //       {
  //         title: "Campaign Summary Report",
  //         path: "/analytics/campaignSummaryReport",
  //       },
  //       {
  //         title: "Social Media Insights",
  //         path: "/analytics/socialMediaInsights",
  //       },
  //       {
  //         title: "Unification",
  //         path: "/analytics/unification",
  //       },
  //     ],
  //     path: "/analytics",
  //   },
  //   {
  //     icon: <span className="fh-icon fh-icon-catalog"></span>,
  //     title: "Channel Catalog",
  //     items: [],
  //     path: "/channelcatalog",
  //   },
  //   {
  //     icon: <span className="fh-icon fh-icon-database"></span>,
  //     title: "Data",
  //     items: [
  //       {
  //         title: "Data Menu",
  //         path: "/datamenu",
  //       },
  //       {
  //         title: "Import Customers",
  //         path: "/datalist/import",
  //       },
  //       {
  //         title: "Manage Datalists",
  //         path: "/datalist/getDataList",
  //       },
  //       {
  //         title: "Create Segment",
  //         path: "/createsegment",
  //       },
  //       {
  //         title: "Manage Segments",
  //         path: "/manage-segment",
  //       },
  //       {
  //         title: "Tag List",
  //         path: "/taglist",
  //       },
  //       {
  //         title: "Manage Social Tag",
  //         path: "/managesocialtag",
  //       },
  //       {
  //         title: "Voice Transcription",
  //         path: "/voicetranscription",
  //       },
  //     ],
  //   },
  //   {
  //     icon: <span className="fh-icon fh-icon-megaphone"></span>,
  //     title: "Campaigns",
  //     items: [
  //       {
  //         title: "Campaign Menu",
  //         path: "/campaignmenu",
  //       },
  //       {
  //         title: "Create Your Campaign",
  //         path: "/engage/campaign",
  //       },
  //       {
  //         title: "Manage Campaigns",
  //         path: "/engage/campaign/getCampaignList",
  //       },
  //       {
  //         title: "Manage Templates",
  //         path: "/managetemplates",
  //       },
  //       {
  //         title: "Manage Social Channels",
  //         path: "/managesocialchannels",
  //       },
  //       {
  //         title: "Audience Push",
  //         path: "/audiencepush",
  //       },
  //       {
  //         title: "Manage Social Scheduler",
  //         path: "/managesocialscheduler",
  //       },
  //       {
  //         title: "SMS Inbox",
  //         path: "/smsinbox",
  //       },
  //       {
  //         title: "New Automation",
  //         path: "/newautomation",
  //       },
  //       {
  //         title: "Manage Automation",
  //         path: "/manageautomation",
  //       },
  //     ],
  //   },
  //   {
  //     icon: <span className="fh-icon fh-icon-globe"></span>,
  //     title: "World Swipe",
  //     items: [
  //       {
  //         title: "Code/Coupon Generation",
  //         path: "/codecoupongeneration",
  //       },
  //       {
  //         title: "Manage Codes/Coupons",
  //         path: "/managecodescoupons",
  //       },
  //     ],
  //   },
  //   {
  //     icon: <span className="fh-icon fh-icon-quick-timer"></span>,
  //     title: "Quiz",
  //     items: [
  //       {
  //         title: "Quiz",
  //         path: "/quiz",
  //       },
  //       {
  //         title: "Manage Question Bank",
  //         path: "/managequestionbank",
  //       },
  //       {
  //         title: "Manage Quiz",
  //         path: "/managequiz",
  //       },
  //       {
  //         title: "Create Random Quiz",
  //         path: "/createrandomquiz",
  //       },
  //       {
  //         title: "Create Manual Quiz",
  //         path: "/createmanualquiz",
  //       },
  //       {
  //         title: "Quiz Analytics",
  //         path: "/quizanalytics",
  //       },
  //     ],
  //   },
  //   {
  //     icon: <span className="fh-icon fh-icon-user"></span>,
  //     title: "Admin",
  //     items: [
  //       {
  //         title: "Admin Menu",
  //         path: "/adminmenu",
  //       },
  //       {
  //         title: "Manage Channels",
  //         path: "/managechannels",
  //       },
  //       {
  //         title: "Manage Roles",
  //         path: "/manageroles",
  //       },
  //       {
  //         title: "Manage Users",
  //         path: "/engage/admin",
  //       },
  //       {
  //         title: "Manage Exclusion List",
  //         path: "/manageexclusionlist",
  //       },
  //       {
  //         title: "Site Tracking",
  //         path: "/sitetracking",
  //       },
  //       {
  //         title: "Account Billing",
  //         path: "/accountbilling",
  //       },
  //       {
  //         title: "Account Usage Summary",
  //         path: "/accountusagesummary",
  //       },
  //       {
  //         title: "Wallet Balance",
  //         path: "/walletbalance",
  //       },
  //       {
  //         title: "SMS Summary Report",
  //         path: "/smssummaryreport",
  //       },
  //       {
  //         title: "Event Usage Summary",
  //         path: "/eventusagesummary",
  //       },
  //       {
  //         title: "SMS Balance Summary",
  //         path: "/smsbalancesummary",
  //       },
  //       {
  //         title: "Activity Feed",
  //         path: "/activityfeed",
  //       },
  //       {
  //         title: "Account Settings",
  //         path: "/accountsettings",
  //       },
  //       {
  //         title: "Communication Settings",
  //         path: "/communicationsettings",
  //       },
  //     ],
  //   },
  //   {
  //     icon: <span className="fh-icon fh-icon-check-list"></span>,
  //     title: "Manage Summary",
  //     items: [
  //       {
  //         title: "Manage Summary ",
  //         path: "/survey/summery",
  //       },
  //       {
  //         title: "Create Survey",
  //         path: "/surveycreation",
  //       },
  //     ],
  //   },
  //   {
  //     icon: <span className="fh-icon fh-icon-analytics"></span>,
  //     title: "Analytics",
  //     items: [
  //       {
  //         title: "Analytics",
  //         path: "/SurveyAnalytics",
  //       },
  //     ],
  //   },
];
