import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import TickImg from '../../../../assets/img/tick filled.png';
import EndUserFooter from '../../../../components/Footer/EndUserFooter';

const ThankYou = ({ gridData, headerFontStyle, messageFontStyle }) => {
  return (
    <Grid
      container
      sx={{ border: '1px solid #C6C6C6', borderRadius: '6px', p: 5 }}
    >
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: `${gridData.sm_logo_position}`,
          }}
        >
          <img
            src={`${process.env.REACT_APP_BASE_URL}/` + gridData.sm_logo_link}
            alt=""
          />
        </Box>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Box>
          <img
            src={`${process.env.REACT_APP_BASE_URL}/` + gridData.sm_banner_link}
            alt=""
            style={{ width: '100%', height: 'auto' }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box sx={{ width: 80, height: 80 }}>
              <img
                src={TickImg}
                alt=""
                style={{ width: '100%', height: 'auto' }}
              />
            </Box>
            {gridData.sm_ty_header !== '' || gridData.sm_ty_message !== '' ? (
              <>
                <Typography
                  variant="h1"
                  sx={{ mt: 4, fontFamily: `${headerFontStyle}` }}
                >
                  {gridData.sm_ty_header}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: '#5E5E5E',
                    mt: 4,
                    textAlign: 'center',
                    fontFamily: `${messageFontStyle}`,
                  }}
                >
                  {gridData.sm_ty_message}
                </Typography>
              </>
            ) : (
              <Typography variant="sh1" sx={{ mt: 2, textAlign: 'center' }}>
                Submitted
              </Typography>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <EndUserFooter />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ThankYou;
