import React from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { Common } from '../../../../components/common/Common';
// import ReactDateRangePicker from '../../../../components/common/ReactDatePicker/ReactDateRangePicker';
import CustomizeAutocomplete from '../../../../components/common/CustomizeAutocomplete/CustomizeAutocomplete';

const Filters = ({
  handleGoFilterClick,
  surveyName,
  channelName,
  gettingAllSurveyData, //All survey name
  options, //All channel name
  handleOnSurveyChange,
  handleOnChannelChange,
  dateVal,
  handleDateChange,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <Typography variant="sh3">Survey*</Typography>
        <CustomizeAutocomplete
          size="small"
          name={'surveyName'}
          id={'surveyName'}
          placeholder="Select survey"
          options={gettingAllSurveyData}
          defaultValue={surveyName}
          onChange={handleOnSurveyChange}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <Typography variant="sh3">Channel*</Typography>
        <CustomizeAutocomplete
          size="small"
          name={'channelName'}
          id={'channelName'}
          placeholder="Select channel"
          options={options}
          defaultValue={channelName}
          onChange={handleOnChannelChange}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <Typography variant="sh3">From Date</Typography>
        <TextField
          type="date"
          fullWidth
          size="small"
          name="fromDate"
          value={dateVal.fromDate}
          onChange={handleDateChange}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <Typography variant="sh3">To Date</Typography>
        <TextField
          type="date"
          fullWidth
          size="small"
          name="toDate"
          value={dateVal.toDate}
          onChange={handleDateChange}
        />
      </Grid>
      <Grid item xs={12} md={3} mt={3.5}>
        <Common.Button size="small" onClick={handleGoFilterClick}>
          Go
        </Common.Button>
      </Grid>
    </Grid>
  );
};

export default Filters;
