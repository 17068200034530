import React from 'react';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup as MuiRadioGroup,
  Typography,
  FormHelperText,
} from '@mui/material';

import useStyles from './styles';

export default function RadioGroup(props) {
  const {
    name,
    label,
    value,
    onChange,
    items,
    labelPlacement,
    optionsFontStyle,
    optionsFontSize,
    error,
    helperText,
    ...other
  } = props;

  // const classes = useStyles(props);

  return (
    <FormControl error={error ? true : false}>
      <MuiRadioGroup
        name={name}
        value={value}
        // className={classes.root}
        onChange={onChange}
        {...other}
      >
        {items.map((item, index) => (
          <FormControlLabel
            key={item.id}
            value={item.id}
            control={
              <Radio
                // className={classes.radio}
                disableRipple
              />
            }
            label={
              <Typography
                // variant="body3"
                sx={{
                  fontFamily: optionsFontStyle
                    ? `${optionsFontStyle}!important`
                    : 'Poppins',
                  fontSize: optionsFontSize?`${optionsFontSize}px`:'1rem',
                }}
              >
                {item.title}
              </Typography>
            }
            labelPlacement={labelPlacement || 'end'}
          />
        ))}
      </MuiRadioGroup>
      {error && (
        <FormHelperText variant="body4" color="error">
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
}
