import React from 'react';
import { Chart as ChartJS,Title,Tooltip,Legend, } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { color } from 'chart.js/helpers';
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix';
import { Typography, Box } from '@mui/material';

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    MatrixController, 
    MatrixElement);

    
   
    
    
   export default function HeatMap(props){
    const {data, xLabel, yLabel, heading, value} = props;
    const config = {
      type: 'matrix',
      data: {
        datasets: [{
          data,
          borderWidth: 1,
          borderColor: 'rgba(75, 192, 192, 1)`',
          backgroundColor({raw}) {
            // console.log(raw)
            const alpha = (10 + raw[value])/50;
            return `rgba(69, 143, 216, ${alpha})`;
          },
          width: ({chart}) => (chart.chartArea || {}).width / xLabel.length - 1,
          height: ({chart}) => (chart.chartArea || {}).height / yLabel.length - 1,
        }],
      },
      options: {
        aspectRatio:3.2,
        plugins:{
          legend: false,
        },
        scales: {
          x: {
            type:'category',
            labels: xLabel,
            offset: true,
            ticks:{
              display: true,
              font:{
                family:'poppins',
                size:12,
                weight: 500,
              }
            },
            grid: {
              display: false
            }
          },
          
          y: {
            type:'category',
            labels: yLabel,
            offset: true,
            ticks:{
              display: true,
              
              font:{
                family:'poppins',
                size:12,
                weight: 500,
              }
            },
            grid: {
              display: false
            }
          }
        }
      }
    };
    return(
      <Box>
      <Typography variant="sh3">{heading}</Typography>
      <Chart type="matrix" data={config.data} options={config.options}/>
     </Box>
    )
   }
    