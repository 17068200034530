import React from 'react';
import { Typography,Stack, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import img from '../..//assets/img/FirstHiveLogo.png';

import useStyles from './styles';


export default function Footer({children}){
    const classes=useStyles();
  return (
    <Stack
      spacing={3}
      // sx={{
      //     width: { sm: `calc(100% - ${drawerWidth}px)` },
      //     // ml: { sm: `${drawerWidth}px` },
      // }}
    >
      <Box className={classes.footerContainer}>
        <Typography className={classes.footerContent} variant="body4">
          {"Term of use | Privacy Policy | Cookie Policy | DPA | © "}{" "}
          {new Date().getFullYear()}{" "}{" "}
          <Link color="inherit" href="/" target="_blank">
            {" "}FirstHive
          </Link>{" "}
          {"All rights reserved"}
          {"."}
          <img src={img} alt="FirstHive" style={{ height: "1.8rem" }} />
        </Typography>
        {/* {children} */}
      </Box>
    </Stack>
  );
}