import {
  Box,
  Dialog,
  // DialogActions,
  DialogContent,
  // DialogContentText,
  DialogTitle,
  Icon,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
// import Button from '../../../../components/common/Button/Button';
import { styled } from '@mui/styles';
import { getAccessToken } from '../../auth';
import { getMethod } from '../../../../api/getMethod';
import useTable from '../../../../components/common/Table/useTable';

const headCells = [
  { id: 'cmp_name', label: 'Campaign Name' },
  { id: 'cmp_type', label: 'Channel' },
  { id: 'data_list', label: 'Data segment' },
  { id: 'recipient_count', label: 'Recipient Count' },
  { id: 'response_count', label: 'Responders Count' },
];
const SurveyUsage = ({ open, setOpen, selectedSurveyId }) => {
  const token = getAccessToken();
  const [tableData, setTableData] = useState([]);
  // const [open, setOpen] = React.useState(false);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    tableData,
    headCells,
    filterFn
  );

  const handleClose = () => {
    setOpen(false);
  };

  const fetchData = async () => {
    try {
      const url =
        process.env.REACT_APP_BASE_URL +
        process.env.REACT_APP_PREFIX_URL +
        `${selectedSurveyId}/survey_usage/`;
      const response = await getMethod(url, token);
      setTableData(response.data.data); // Handle the response data here
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (selectedSurveyId) fetchData();
  }, [selectedSurveyId]);

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={'md'}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, textAlign: 'center' }}
          id="customized-dialog-title"
        >
          Survey usage in Channels
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon className="fh-icon fh-icon-close" sx={{ fontSize: '1.2rem' }} />
        </IconButton>
        <DialogContent>
          {tableData.length === 0 ? (
            <Box
              sx={{ minHeight: '20vh', maxHeight: '50vh', minWidth: '30vw' }}
            >
              <Typography variant="body1" align="center" mt={4} mb={4}>
                Survey not yet executed
              </Typography>
            </Box>
          ) : (
            <TblContainer>
              <TblHead variant="sh5" />
              <TableBody>
                {recordsAfterPagingAndSorting().map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.cmp_name}</TableCell>
                    <TableCell>{item.cmp_type}</TableCell>
                    <TableCell>{item.data_list}</TableCell>
                    <TableCell>{item.recipient_count}</TableCell>
                    <TableCell>{item.response_count}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TblContainer>
          )}
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions> */}
      </BootstrapDialog>
    </>
  );
};
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
export default SurveyUsage;
