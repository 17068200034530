import React from "react";
import { Box, Icon, IconButton } from "@mui/material";
import NavList from "../NavList/NavList";
import useStyles, { Drawer } from "./styles";
import { useSidebarContext } from "../../context/sidebarContext";
// import {}

const SideBar = () => {
  const classes = useStyles();
  const { sidebarOpen, setSidebarOpen, handleClick } = useSidebarContext();

  

  return (
    <Box component="nav">
      <Drawer
        open={sidebarOpen}
        variant="permanent"
        classes={{
          root: classes.drawerPaper,
        }}
        anchor="left"
      >
        <Box
          sx={{
            overflow: "hidden",
            overflowY: "auto",
            height: "100%",
            p: 2,
            "&::-webkit-scrollbar-track": {
              background: "none",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "rgba(255, 255, 255, 0.2)",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar": {
              width: "5px",
            },
          }}
        >
          {/*logo*/}
          {sidebarOpen ? (
            <Box className={classes.rowWraperBox}>
              {
                <Icon
                  className="fh-icon fh-icon-fh-logo"
                  style={{ fontSize: "145px" }}
                />
              }
              <IconButton disableRipple onClick={handleClick}>
                <Icon
                  className={`fh-icon fh-icon-backward ${classes.backward}`}
                />
              </IconButton>
            </Box>
          ) : (
            <Box className={classes.columnWraperBox}>
              <IconButton disableRipple onClick={handleClick}>
                {
                  <Icon
                    className={`fh-icon fh-icon-forward ${classes.forward}`}
                  />
                }
              </IconButton>
              {
                <Icon
                  className="fh-icon fh-icon-fh-only-logo"
                  style={{ fontSize: "56px", margin: "0 auto" }}
                />
              }
            </Box>
          )}
          <NavList sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        </Box>
      </Drawer>
    </Box>
  );
};

export default SideBar;
