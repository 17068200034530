import { Typography, Box, Grid, FormHelperText } from '@mui/material';
import React from 'react';
import { Common } from '../../../../components/common/Common';

const checkBoxValue = [
  { title: '1', id: 1 },
  { title: '2', id: 2 },
  { title: '3', id: 3 },
  { title: '4', id: 4 },
  { title: '5', id: 5 },
  { title: '6', id: 6 },
  { title: '7', id: 7 },
  { title: '8', id: 8 },
  { title: '9', id: 9 },
  { title: '10', id: 10 },
];

const DisplayFormat = ({
  options,
  value,
  id,
  onChange,
  optionsFontStyle,
  optionsFontSize,
  error,
  name,
}) => {
  return (
    <Grid container columns={12} alignItems={'center'}>
      <Grid item xs={12}>
        <Typography
          variant="body3"
          sx={{
            display: 'inline-block',
            color: '#333',
            fontFamily: optionsFontStyle
              ? `${optionsFontStyle} !important`
              : 'Poppins',
            // marginBottom: '8px',
            fontSize: optionsFontSize
              ? `${optionsFontSize}px !important`
              : '1rem',
            mt: 4,
          }}
        >
          {options[0].opt_label}
        </Typography>
        <Common.RadioGroup
          row
          name={name}
          value={value}
          onChange={onChange}
          id={id}
          items={checkBoxValue}
          size="small"
          labelPlacement="top"
          optionsFontStyle={optionsFontStyle}
          optionsFontSize={optionsFontSize}

          // error={error}
        />
        <Typography
          variant="body3"
          sx={{
            color: '#333',
            display: 'inline-block',
            fontFamily: optionsFontStyle
              ? `${optionsFontStyle} !important`
              : 'Poppins',
            fontSize: optionsFontSize
              ? `${optionsFontSize}px !important`
              : '1rem',
            marginTop: `32px !important`,
          }}
        >
          {options[1].opt_label}
        </Typography>
      </Grid>
      <Grid item>
        {error && (
          <FormHelperText
            variant="body4"
            color="error"
            sx={{ color: '#d32f2f' }}
          >
            {error}
          </FormHelperText>
        )}
      </Grid>
    </Grid>
  );
};

export default DisplayFormat;
