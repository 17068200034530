import { makeStyles } from "@mui/styles";

export default makeStyles(theme=>({
    wrapperBox:{
        minWidth: '92px',
        height: '34px',
        textAlign:'center',
        display:'inline-flex',
        justifyContent:'center',
        alignItems:'center',
        borderRadius: '4px !important',
        textTransform: 'uppercase',
        padding: '8px !important'
    },
    active:{
        background: localStorage.getItem('theme') === 'light' ? theme.palette.blue.light : theme.palette.blue.dark,
        color: localStorage.getItem('theme') === 'light' ? theme.palette.blue.dark : theme.palette.blue.light,
    },
    activeDefault: {
        background: localStorage.getItem('theme') === 'light' ? theme.palette.blue.light : theme.palette.blue.dark,
        color: localStorage.getItem('theme') === 'light' ? theme.palette.black.dark : theme.palette.white.main,
    },
    success:{
        background: localStorage.getItem('theme') === 'light' ? theme.palette.green.light:theme.palette.green.dark,
        color: localStorage.getItem('theme') === 'light' ? theme.palette.green.dark:theme.palette.green.light,
    },
    successDefault: {
        background: localStorage.getItem('theme') === 'light' ? theme.palette.green.light : theme.palette.green.dark,
        color: localStorage.getItem('theme') === 'light' ? theme.palette.black.dark : theme.palette.white.main,
    },
    expired:{
        background: localStorage.getItem('theme') === 'light' ? theme.palette.red.light : theme.palette.red.dark,
        color: localStorage.getItem('theme') === 'light' ? theme.palette.red.dark : theme.palette.red.light,
    },
    expiredDefault: {
        background: localStorage.getItem('theme') === 'light' ? theme.palette.red.light : theme.palette.red.dark,
        color: localStorage.getItem('theme') === 'light' ? theme.palette.black.dark : theme.palette.white.main,

    },
    suspended:{
        background: localStorage.getItem('theme') === 'light' ? theme.palette.yellow.light : theme.palette.yellow.dark,
        color: localStorage.getItem('theme') === 'light' ? theme.palette.yellow.dark : theme.palette.yellow.light,
    },
    suspendedDefalut: {
        background: localStorage.getItem('theme') === 'light' ? theme.palette.yellow.light : theme.palette.yellow.dark,
        color: localStorage.getItem('theme') === 'light' ? theme.palette.black.dark : theme.palette.white.main,
    },
    bydefault:{
        background: localStorage.getItem('theme') === 'light' ? theme.palette.black.main : theme.palette.white.main,
        color: localStorage.getItem('theme') === 'light' ? theme.palette.white.main : theme.palette.base.main,
    },
    bydefaultDefault:{
        background: localStorage.getItem('theme') === 'light' ? theme.palette.black.main : theme.palette.white.main,
        color: localStorage.getItem('theme') === 'light' ? theme.palette.white.main : theme.palette.base.main,
    }
}))